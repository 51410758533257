<coin-v2-dialog-frame [header]="header">
  <div class="terms-and-conditions-text">
    <h4>{{ 'equity.disclaimer.warning' | translate }}</h4>
    <p class="padding-bottom">{{ 'equity.disclaimer.warning-text' | translate }}</p>
    <h4>{{ 'equity.disclaimer.terms-and-conditions' | translate }}</h4>
    <p class="padding-bottom" [innerHTML]="getTranslation('equity.disclaimer.terms-and-conditions-textq')"></p>
    <h4>{{ 'equity.disclaimer.confirmation' | translate }}</h4>
    <p [innerHTML]="getTranslation('equity.disclaimer.confirmation-text')"></p>
  </div>
  <coin-v2-dialog-footer>
    <coin-v2-button type="secondary" (click)="close()" [text]="'general.btnDecline' | translate" icon="chevron_right" iconPos="right"></coin-v2-button>
    <coin-v2-button type="primary" (click)="confirm()" [text]="'general.btnConfirm' | translate" icon="chevron_right" iconPos="right"></coin-v2-button>
  </coin-v2-dialog-footer>
</coin-v2-dialog-frame>

<ng-template #header>
  <div class="header">
    <div class="header-title">
      <h4>{{ 'equity.disclaimer.title' | translate }}</h4>
    </div>
    <coin-simple-lang-switch></coin-simple-lang-switch>
  </div>
</ng-template>
