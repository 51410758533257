import { IncentiveRecordsAssignmentRecordPartnerModelProcessStateEnum } from '@coin/shared/util-models';

/** If record process state >= frozen, then all operations except "Download TAF Letter" are disabled */
export function isProcessStateAboveOrEqualToFrozen(recordProcessState: IncentiveRecordsAssignmentRecordPartnerModelProcessStateEnum) {
  return [
    IncentiveRecordsAssignmentRecordPartnerModelProcessStateEnum.Frozen,
    IncentiveRecordsAssignmentRecordPartnerModelProcessStateEnum.AchievementLetterCreated,
    IncentiveRecordsAssignmentRecordPartnerModelProcessStateEnum.AchievementLetterPublished
  ].includes(recordProcessState);
}
