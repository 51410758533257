import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { V2NumberInputComponent } from '@coin/shared/ui-storybook';
import { Fn } from '@coin/shared/util-models';

@Component({
  selector: 'coin-email-slider',
  templateUrl: './email-slider.component.html',
  styleUrls: ['./email-slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailSliderComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [V2NumberInputComponent, MatSliderModule, FormsModule]
})
export class EmailSliderComponent implements ControlValueAccessor {
  @Input() label: string;

  private _value: number | null = null;

  public set value(val: number | null) {
    this._value = val;
    this.onChange(val);
    this.onTouch(val);
  }

  public get value(): number | null {
    return this._value;
  }

  public onChange: Fn = () => {};
  public onTouch: Fn = () => {};

  public writeValue(value: number): void {
    this.value = value;
  }

  public registerOnChange(fn: Fn): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: Fn): void {
    this.onTouch = fn;
  }
}
