@if (isApp()) {
  <div class="app-header-container"></div>
}
<div class="custom-header" [class.deactivated]="deactivated()">
  <div class="header-left-part" role="banner">
    <div class="mod-logo">
      <button class="no-style with-focus logo-wrapper" (click)="gotoStartPage()" aria-label="Home Button"></button>
      @if ((responsive.isMobile$ | async) === false) {
        <a class="mod-logo design-logo" href="https://ifdesign.com/en/winner-ranking/project/siemens-energy-compensation-insights-coin/350912" target="_blank">
          <img src="assets/svgs/design_award.svg" />
        </a>
      }
      <ng-container *featureFlag="'displayEnvironment'">
        @if ((responsive.isMobile$ | async) === false) {
          <div class="environment">
            <mat-icon>{{ currentEnvironment.icon }}</mat-icon>
            <p class="bold">{{ currentEnvironment.type }}</p>
          </div>
        }
      </ng-container>
    </div>
  </div>
  <coin-user-menu></coin-user-menu>
  <div class="header-right-part" [class.big-margin]="isAuthenticated$ | async">
    @if (updateNotifications.hasUpdates()) {
      <coin-update-notifier></coin-update-notifier>
    }
    <coin-v2-button
      type="secondary"
      (click)="toggleFaq()"
      [matBadge]="totalNewFlags()"
      [matBadgeHidden]="totalNewFlags() === 0"
      [matBadgeOverlap]="true"
      text="{{ (responsive.isMobile$ | async) ? '' : ('faq.whats-new' | translate) }} ?">
    </coin-v2-button>
    @if (!(isAuthenticated$ | async)) {
      <coin-v2-button type="primary" (click)="login()" [text]="'general.login' | translate" icon="chevron_right" iconPos="right" aria-label="Login"></coin-v2-button>
    }
  </div>
  @if (showFaq()) {
    <div @slideFaq class="faq-sidebar" [ngClass]="{ 'no-menu-spacing': !(isAuthenticated$ | async) }">
      <coin-siemens-energy-faq-menu
        [helpData]="helpData()[languageSelected()]"
        [isLoading]="isHelpDataLoading()"
        (close)="toggleFaq()"
        (openOtherTopic)="onOpenOtherFaqTopic($event)"></coin-siemens-energy-faq-menu>
    </div>
  }
</div>
