import { inject, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { AuthGuard, MercerGuard, RoleGuard } from '@coin/modules/auth/data-management';
import { AuthGmsClientCallbackComponent, LogoutScreenComponent } from '@coin/modules/auth/feature';
import { EnvironmentGuard } from '@coin/shared/data-management-guards';
import { NoBackendScreenComponent } from '@coin/shared/feature-legacy-components';
import { FrontendType, PermissionResource } from '@coin/shared/util-enums';
import { environment } from '@coin/shared/util-environments';
import { Customer } from '@coin/shared/util-helpers';
import { ContentPagesComponent } from './content-pages/content-pages.component';
import { DeputyGuard } from './shared/guards/deputy.guard';
import { RedirectGuard } from './shared/guards/redirect.guard';
import { CustomerRoleGuard } from './shared/services/customer-role-guard.service';
import { ServiceProviderListComponent } from './static/service-provider-list/service-provider-list.component';

// @ts-ignore
const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state)],
    data: {
      frontendType: FrontendType.customer
    },
    pathMatch: 'full'
  },
  {
    path: 'videos',
    loadChildren: () => import('./videos/videos.module').then(m => m.VideosModule),
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state)],
    data: {
      frontendType: FrontendType.customer
    }
  },
  {
    path: 'auth/callback',
    component: AuthGmsClientCallbackComponent
  },
  {
    path: 'logout',
    component: LogoutScreenComponent
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'document-center',
    loadChildren: () => import('./document-center/document-center.module').then(m => m.DocumentCenterModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route),
      () => inject(DeputyGuard).canActivate()
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.CustomerApp
        }
      ],
      frontendType: FrontendType.customer
    }
  },
  {
    path: 'employee-detail-overview',
    loadChildren: () => import('./employee-detail-overview/employee-detail-overview.module').then(m => m.EmployeeDetailOverviewModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route),
      () => inject(DeputyGuard).canActivate()
    ],
    canMatch: [(route: ActivatedRouteSnapshot) => inject(EnvironmentGuard).canMatch(route)],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.CustomerApp
        }
      ],
      frontendType: FrontendType.customer
    }
  },
  {
    path: 'document-download',
    loadChildren: () => import('./document-download/document-download.module').then(m => m.DocumentDownloadModule),
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state)],
    data: {
      frontendType: FrontendType.customer
    }
  },
  {
    path: Customer.segment('MeritBudgetAllocationModule'),
    loadChildren: () => import('./merit/merit-budget-allocation/merit-budget-allocation.module').then(m => m.MeritBudgetAllocationModule),
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state)],
    canMatch: [(route: ActivatedRouteSnapshot) => inject(EnvironmentGuard).canMatch(route)],
    data: {
      frontendType: FrontendType.customer
    }
  },
  {
    path: 'gbr',
    loadChildren: () => import('./gbr/gbr.module').then(m => m.GbrModule),
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state)],
    data: {
      frontendType: FrontendType.customer
    }
  },
  {
    path: Customer.segment('SpecialPaymentModule'),
    loadChildren: () => import('./special-payment/special-payment.module').then(m => m.SpecialPaymentModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(CustomerRoleGuard).canActivate(route)
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.SpecialPayment
        }
      ],
      directAllowAccessLineManager: true,
      frontendType: FrontendType.customer
    }
  },
  {
    path: 'support-apps',
    loadChildren: () => import('./merit/hr-partner-app/hr-partner-app.module').then(m => m.HrPartnerAppModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route)
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.ReOrganisationApp
        },
        {
          resource: PermissionResource.AllocationHR
        },
        {
          resource: PermissionResource.PositionEvaluationHR
        },
        {
          resource: PermissionResource.SuccessionManagementHR
        },
        {
          resource: PermissionResource.OrganisationPlanningAdmin
        },
        {
          resource: PermissionResource.IncentiveSeasonHr
        }
      ],
      frontendType: FrontendType.customer
    }
  },
  {
    path: 'org-review',
    loadChildren: () => import('./org-review/org-review.module').then(m => m.OrgReviewModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route)
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.ReOrganisationApp
        }
      ],
      frontendType: FrontendType.customer
    }
  },
  {
    path: Customer.segment('MeritSupportModule'),
    loadChildren: () => import('./merit/merit-support/merit-support.module').then(m => m.MeritSupportModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route)
    ],
    canMatch: [(route: ActivatedRouteSnapshot) => inject(EnvironmentGuard).canMatch(route)],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.AllocationHR
        }
      ],
      frontendType: FrontendType.customer
    }
  },
  {
    path: Customer.segment('MercerModule'),
    loadChildren: () => import('./mercer/mercer.module').then(m => m.MercerModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route),
      (route: ActivatedRouteSnapshot) => inject(MercerGuard).canActivate(route)
    ],
    data: {
      frontendType: FrontendType.customer
    }
  },
  {
    path: Customer.segment('OrgPlanningModule'),
    loadChildren: () => import('./org-planning/org-planning.module').then(m => m.OrgPlanningModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route)
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.OrganisationPlanning
        },
        {
          resource: PermissionResource.OrganisationPlanningAdmin
        }
      ],
      frontendType: FrontendType.customer
    }
  },
  {
    path: Customer.segment('OrgManagementModule'),
    loadChildren: () => import('@coin/customer/org-management/feature').then(m => m.OrgManagementModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route),
      (route: ActivatedRouteSnapshot) => inject(EnvironmentGuard).canMatch(route)
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.OrganisationManagementUser
        },
        {
          resource: PermissionResource.OrganisationManagementAdmin
        }
      ],
      frontendType: FrontendType.customer,
      featureFlag: 'orgManagement'
    }
  },
  {
    path: Customer.segment('OrgPlanningSupportModule'),
    loadChildren: () => import('./org-planning-support/org-planning-support.module').then(m => m.OrgPlanningSupportModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route)
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.OrganisationPlanningAdmin
        }
      ],
      frontendType: FrontendType.customer
    }
  },
  {
    path: Customer.segment('MercerSupportModule'),
    loadChildren: () => import('./mercer-support/mercer-support.module').then(m => m.MercerSupportModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route)
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.PositionEvaluationHR
        }
      ],
      frontendType: FrontendType.customer
    }
  },
  {
    path: Customer.segment('MercerStandingModule'),
    loadChildren: () => import('./mercer-standing/mercer-standing.module').then(m => m.MercerStandingModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route),
      (route: ActivatedRouteSnapshot) => inject(EnvironmentGuard).canMatch(route)
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.StandingPositionEvaluationHR
        }
      ],
      frontendType: FrontendType.customer,
      featureFlag: 'mercerStanding'
    }
  },
  {
    path: Customer.segment('HeadCountApprovalModule'),
    loadChildren: () => import('./headcount-approval/headcount-approval.module').then(m => m.HeadcountApprovalModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(CustomerRoleGuard).canActivate(route),
      (route: ActivatedRouteSnapshot) => inject(EnvironmentGuard).canMatch(route)
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.CAndB
        },
        {
          resource: PermissionResource.HeadcountApprovalHr
        },
        {
          resource: PermissionResource.HeadcountApprovalAdmin
        }
      ],
      frontendType: FrontendType.customer,
      directAllowAccessLineManager: true,
      featureFlag: 'headCountApprovalFeature'
    }
  },
  {
    path: Customer.segment('SuccessionManagementModule'),
    loadChildren: () => import('./succession-management/succession-management.module').then(m => m.SuccessionManagementModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(CustomerRoleGuard).canActivate(route),
      (route: ActivatedRouteSnapshot) => inject(EnvironmentGuard).canMatch(route)
    ],
    data: {
      allowedResources: [
        {
          // TODO: What PermissionResource should be used
          resource: PermissionResource.All
        }
      ],
      directAllowAccessLineManager: true,
      frontendType: FrontendType.customer
    }
  },
  {
    path: Customer.segment('SuccessionManagementSupportModule'),
    loadChildren: () => import('./succession-management-support/succession-management-support.module').then(m => m.SuccessionManagementSupportModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route)
    ],
    data: {
      frontendType: FrontendType.customer
    }
  },
  {
    path: Customer.segment('IncentiveSupportModule'),
    loadChildren: () => import('@coin/customer/incentive-support-page-feature').then(m => m.IncentiveSupportModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route),
      (route: ActivatedRouteSnapshot) => inject(EnvironmentGuard).canMatch(route)
    ],
    data: {
      frontendType: FrontendType.customer,
      featureFlag: 'incentiveSupport'
    }
  },
  {
    path: 'service-provider',
    component: ServiceProviderListComponent
  },
  {
    path: 'helpdesk',
    canActivate: [(route: ActivatedRouteSnapshot) => inject(RedirectGuard).canActivate(route)],
    component: RedirectGuard,
    data: {
      externalUrl: environment.helpdeskUrl
    }
  },
  {
    path: Customer.segment('EmploymentConditionLibraryModule'),
    loadChildren: () => import('./employment-condition-library/employment-condition-library.module').then(m => m.EmploymentConditionLibraryModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(RoleGuard).canActivate(route)
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.EmploymentConditionLibraryUser
        },
        {
          resource: PermissionResource.EmploymentConditionLibraryAdmin
        },
        {
          resource: PermissionResource.EmploymentConditionLibraryRestricted
        },
        {
          resource: PermissionResource.EmploymentConditionLibraryGenerator
        }
      ],
      frontendType: FrontendType.customer
    }
  },
  {
    path: Customer.segment('RestructuringModule'),
    loadChildren: () => import('./restructuring/restructuring.module').then(m => m.RestructuringModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(CustomerRoleGuard).canActivate(route),
      (route: ActivatedRouteSnapshot) => inject(EnvironmentGuard).canMatch(route)
    ],
    data: {
      allowedResources: [
        {
          resource: PermissionResource.RestructuringAdmin
        },
        {
          resource: PermissionResource.RestructuringAdminRestricted
        },
        {
          resource: PermissionResource.RestructuringHr
        },
        {
          resource: PermissionResource.RestructuringConditionLibraryAdmin
        }
      ],
      frontendType: FrontendType.customer,
      featureFlag: 'restructuring'
    }
  },
  {
    path: Customer.segment('HeadcountOverviewModule'),
    loadChildren: () => import('./headcount-overview/headcount-overview.module').then(m => m.HeadcountOverviewModule),
    canActivate: [
      (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state),
      (route: ActivatedRouteSnapshot) => inject(CustomerRoleGuard).canActivate(route),
      (route: ActivatedRouteSnapshot) => inject(EnvironmentGuard).canMatch(route)
    ],
    data: {
      allowedResources: [
        // TODO: Add allowed permission resources
        {
          resource: PermissionResource.CAndB
        }
      ],
      frontendType: FrontendType.customer,
      featureFlag: 'headcountOverview',
      directAllowAccessLineManager: true
    }
  },
  {
    path: Customer.segment('SpecialPaymentEquityModule'),
    loadChildren: () => import('./special-payment-equity/special-payment-equity.module').then(m => m.SpecialPaymentEquityModule),
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuard).canActivate(route, state)],
    data: {
      // TODO: Add permission resources
      frontendType: FrontendType.customer,
      featureFlag: 'specialPaymentEquity'
    }
  },
  {
    path: 'fallback',
    component: NoBackendScreenComponent
  },
  {
    path: '**',
    component: ContentPagesComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
