import { QuillEditorComponent } from 'ngx-quill';
import { AngularEditorComponent } from '@kolkov/angular-editor';
import { ElementRef, inject, Signal } from '@angular/core';

export function registerPlainPaste(editorComponents: { quill?: Signal<QuillEditorComponent>; kolkov?: Signal<AngularEditorComponent> }) {
  inject(ElementRef<HTMLElement>).nativeElement.addEventListener(
    'paste',
    (event: ClipboardEvent) => {
      event.preventDefault();
      event.stopPropagation();

      const plainText = event.clipboardData.getData('text/plain');

      const quill = editorComponents.quill();
      if (quill) {
        document.execCommand('insertText', false, plainText);
      }

      const kolkov = editorComponents.kolkov();
      if (kolkov) {
        document.execCommand('insertText', false, plainText);
      }
    },
    { capture: true }
  );
}
