<coin-v2-dialog-frame header="You are logged in as a Guest">
  <div class="container">
    <div class="disclaimer">
      <div>
        <mat-icon>priority_high</mat-icon>
        <p class="bold">You are no Siemens Energy employee. Please contact the cutsomer support If that´s wrong. Otherwise start your journey as a guest.</p>
      </div>
    </div>
    <div class="content">
      <div class="exit-section mat-elevation-z4">
        <div>
          <div class="left">
            <mat-icon>exit_to_app</mat-icon>
          </div>
          <h3>Start the COIN App for Customer as a Guest</h3>
        </div>
        <coin-v2-button type="primary" (click)="close()" [text]="'general.start' | translate" icon="chevron_right" iconPos="right"></coin-v2-button>
      </div>
      <div class="exit-section mat-elevation-z4">
        <div>
          <div class="left left--contrast">
            <mat-icon>exit_to_app</mat-icon>
          </div>
          <h3>Ask for permission to COIN App for Customer</h3>
        </div>
        <coin-v2-button type="secondary" (click)="close()" [text]="'general.customer-support' | translate" icon="chevron_right" iconPos="right"></coin-v2-button>
      </div>
    </div>
  </div>
</coin-v2-dialog-frame>
