import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { CmsItem, DefaultColorPickerValues } from '@coin/admin/cms/util';
import { LegacyComponentsModule } from '@coin/shared/feature-legacy-components';
import { RenderedGmmSpacerComponent } from '@coin-siemens-energy/letter-print-page';

@Component({
  selector: 'coin-gmm-spacer',
  templateUrl: './spacer.component.html',
  styleUrls: ['./spacer.component.scss'],
  standalone: true,
  imports: [LegacyComponentsModule, FormsModule, MatSliderModule, RenderedGmmSpacerComponent]
})
export class SpacerComponent implements OnInit {
  @Input() contentEdit = false;
  @Input() item: CmsItem<'spacer'>;
  @Input() showBackgroundPicker = false;

  ngOnInit(): void {
    this.item ??= {} as CmsItem<'spacer'>;
    this.setDefaultBackgroundColor();
  }

  private setDefaultBackgroundColor(): void {
    this.item.backgroundColor = this.item.backgroundColor || DefaultColorPickerValues.backgroundColor;
  }
}
