import { DestroyRef, Injectable } from '@angular/core';
import { SetDynamicMercerAccess } from '@coin/modules/auth/util';
import { CommunicationTypeEnum, TodoTaskType } from '@coin/shared/util-enums';
import { DateOperations } from '@coin/shared/util-helpers';
import { Topic } from '@coin/shared/util-models';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { produce } from 'immer';
import { Observable, switchMap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CosmosDashboardService } from '@coin/shared/data-access';
import { Category } from '../models/category.model';
import { TopicsFilter } from '../models/topics-filter.model';
import * as topicsActions from './topics.actions';

export interface TopicsStateModel {
  topics: Topic[];
  bannerTopics: Topic[];
  topicsFilter: TopicsFilter;
  loaded: boolean;
  loading: boolean;
}

@State<TopicsStateModel>({
  name: 'topicsState',
  defaults: {
    topics: undefined,
    bannerTopics: undefined,
    topicsFilter: {
      categories: [],
      allItemsFilter: true
    },
    loaded: false,
    loading: false
  }
})
@Injectable()
export class TopicsState {
  constructor(
    private dashboardService: CosmosDashboardService,
    private store: Store,
    private destroyRef: DestroyRef
  ) {}

  @Selector()
  static topics(state: TopicsStateModel): Topic[] {
    return state.topics;
  }

  @Selector()
  static filteredTopics(state: TopicsStateModel): Topic[] {
    return state.topics
      .filter(topic => state.topicsFilter.categories.find(category => category.type === topic.type).active)
      .sort((a, b) => (b.dateStart && a.dateStart ? new Date(b.dateStart).valueOf() - new Date(a.dateStart).valueOf() : 0));
  }

  @Selector()
  static currentTopics(state: TopicsStateModel): Topic[] {
    return this.filteredTopics(state).filter(topic => {
      const dueDateIsReached = DateOperations.dueDateOverBasedOnDay(topic.dateEnd);
      return !dueDateIsReached && !topic.done;
    });
  }

  @Selector()
  static visibleBannerTopics(state: TopicsStateModel): Topic[] {
    return state.bannerTopics.filter(topic => topic.type === CommunicationTypeEnum.Banner && topic.showBanner && !DateOperations.dueDateOverBasedOnDay(topic.dateEnd));
  }

  @Selector()
  static topicsFilter(state: TopicsStateModel): TopicsFilter {
    return state.topicsFilter;
  }

  @Selector()
  static loaded(state: TopicsStateModel): boolean {
    return state.loaded;
  }

  @Action(topicsActions.LoadTopics)
  loadTopics({ patchState, dispatch }: StateContext<TopicsStateModel>): Observable<void> {
    patchState({ loading: true });

    return this.dashboardService.getData().pipe(
      switchMap(topics => dispatch(new topicsActions.LoadTopicsSuccess(topics))),
      catchError(error => dispatch(new topicsActions.LoadTopicsFail(error)))
    );
  }

  @Action(topicsActions.LoadTopicsSuccess)
  loadTopicsSuccess(ctx: StateContext<TopicsStateModel>, { payload }: topicsActions.LoadTopicsSuccess): Observable<void> {
    const { bannerTopics } = ctx.getState();
    const newBannerTopics = this.mapBannerTopics(
      bannerTopics,
      payload.filter(topic => topic.type === CommunicationTypeEnum.Banner)
    );
    const topics = payload.filter(topic => topic.type !== CommunicationTypeEnum.Banner);
    ctx.patchState({ topics: topics });
    ctx.patchState({ bannerTopics: newBannerTopics });

    ctx
      .dispatch(new topicsActions.ExtractCategories())
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        ctx.patchState({ loaded: true, loading: false });
      });

    const hasFinalIncumbentTodo = topics.some(topic => topic.type === CommunicationTypeEnum.Task && topic.taskType === TodoTaskType.FinalIncumbent);
    return this.store.dispatch(new SetDynamicMercerAccess(hasFinalIncumbentTodo));
  }

  @Action(topicsActions.UpdateBannerVisibility)
  updateBannerVisibility(ctx: StateContext<TopicsStateModel>, { payload }: topicsActions.UpdateBannerVisibility): void {
    ctx.setState(
      produce(ctx.getState(), state => {
        const foundIndex = state.bannerTopics.findIndex(topic => topic.id === payload.id);
        if (foundIndex >= 0) {
          state.bannerTopics[foundIndex].showBanner = false;
        }
      })
    );
  }

  @Action(topicsActions.LoadTopicsFail)
  loadTopicsFail({ dispatch }: StateContext<TopicsStateModel>, { payload }: topicsActions.LoadTopicsFail): Observable<void> {
    dispatch({ loaded: false, loading: false });
    return this.store.dispatch(new SetDynamicMercerAccess(false));
  }

  @Action(topicsActions.UpdateCategories)
  updateCategories(ctx: StateContext<TopicsStateModel>, { payload }: topicsActions.UpdateCategories): void {
    const state = ctx.getState();

    let updatedCategories: Category[] = [...state.topicsFilter.categories];

    if (state.topicsFilter.allItemsFilter) {
      updatedCategories = updatedCategories.map(category => ({ type: category.type, active: false }));
    }

    updatedCategories = updatedCategories.map(category => (category.type === payload ? { type: category.type, active: !category.active } : category));

    ctx.patchState({ topicsFilter: { categories: updatedCategories, allItemsFilter: false } });
  }

  @Action(topicsActions.ResetCategories)
  resetCategories(ctx: StateContext<TopicsStateModel>): void {
    const state = ctx.getState();
    const updatedCategories: Category[] = state.topicsFilter.categories.map(category => ({ type: category.type, active: true }));
    ctx.patchState({ topicsFilter: { categories: updatedCategories, allItemsFilter: true } });
  }

  @Action(topicsActions.ExtractCategories)
  extractCategories(ctx: StateContext<TopicsStateModel>): void {
    const state = ctx.getState();
    const types: string[] = [];
    const categories: Category[] = [];

    for (const topic of state.topics) {
      types.push(topic.type);
    }

    for (const itemType of [...new Set(types)]) {
      categories.push({
        type: itemType,
        active: true
      });
    }

    const topicsFilterItem = Object.assign({}, state.topicsFilter);
    topicsFilterItem.categories = categories;
    ctx.patchState({ topicsFilter: topicsFilterItem });
  }

  mapBannerTopics(currentTopics: Topic[], newTopics: Topic[]): Topic[] {
    return newTopics.map(topic => {
      const currentTopic = currentTopics?.find(currentTopic => currentTopic.id === topic.id);
      if (currentTopic) {
        topic.showBanner = currentTopic.showBanner;
      }
      return topic;
    });
  }
}
