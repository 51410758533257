import { Injectable } from '@angular/core';
import { AuthService } from '@coin/modules/auth/data-access';
import { UserState } from '@coin/modules/auth/data-management';
import { EmployeeHelper } from '@coin/shared/util-helpers';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { produce } from 'immer';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { DocumentCenterItem } from '../models/document-center-item.model';
import { GetDocumentsService } from '../services/get-documents.service';
import * as actions from './documents.actions';

export interface DocumentsStateModel {
  documentItems: DocumentCenterItem[];
}

@State<DocumentsStateModel>({
  name: 'documentsState',
  defaults: {
    documentItems: []
  }
})
@Injectable()
export class DocumentsState {
  constructor(
    private documentsService: GetDocumentsService,
    private store: Store,
    private authService: AuthService
  ) {}

  @Selector()
  static documentItems(state: DocumentsStateModel): DocumentCenterItem[] {
    return state.documentItems ? state.documentItems : [];
  }

  @Action(actions.LoadDocuments)
  loadDocuments(ctx: StateContext<DocumentsStateModel>, { gid, employeeId, managerId }: actions.LoadDocuments): Observable<DocumentCenterItem[]> {
    return this.store.select(UserState?.user).pipe(
      take(1),
      switchMap(user => forkJoin([from(this.authService.getEmulation()), from(this.authService.getEmulationDecoded()), from(this.authService.getUser()), of(user)])),
      map(([emulation, emulationDecoded, authUser, user]) => EmployeeHelper.getEmployeeIdByGid(user?.gid ?? (emulation ? emulationDecoded.Gid : authUser.gid))),
      switchMap(employeeIdFromGid => (managerId ? this.documentsService.getDirectFiles(gid, employeeId, managerId) : this.documentsService.getMyFiles(employeeIdFromGid))),
      tap(docs => {
        ctx.setState(
          produce(ctx.getState(), state => {
            state.documentItems = docs;
          })
        );
      }),
      catchError(() => of(null))
    );
  }
}
