import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Employee } from '@coin/shared/util-models';
import { Observable } from 'rxjs';
import { ActionDef } from '@ngxs/store';
import { OrganisationService } from '../../../models/organisation-service.model';

@Component({
  selector: 'coin-c-org-tree-overview-items',
  templateUrl: './org-tree-overview-items.component.html',
  styleUrls: ['./org-tree-overview-items.component.scss'],
  standalone: false
})
export class OrgTreeOverviewItemsComponent implements OnInit {
  @Input() rootEmployee: Employee;
  @Input() selectedEmployeeId: string;
  @Input() level: number;
  @Input() lastParent = false;
  @Input() service: OrganisationService;
  @Input() seasonId?: string;
  @Input() customOpenEmployeeInViewAction: ActionDef;
  @Output() itemSelect = new EventEmitter<void>();

  public heads$: Observable<Employee[]>;

  ngOnInit(): void {
    this.loadChildOrganizations();
  }

  private loadChildOrganizations(): void {
    if (this.rootEmployee) {
      this.heads$ = this.service.getChildOrganisations(this.rootEmployee?.orgCode, this.seasonId);
    }
  }
}
