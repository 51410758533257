import { Router } from '@angular/router';
import { MercerEditableByType, MercerSurveyDecision } from '@coin/admin/master-data/util';
import { CustomerSurvey, CustomerSurveyResult } from '@coin/modules/customer-survey/util';
import { DisplayedCurrency, PermissionResource } from '@coin/shared/util-enums';
import { CurrencyIsoCode, deleteCascadingFieldsIfNecessary, MercerData } from '@coin/shared/util-models';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { PositionEvaluationSetting } from '@coin/admin/season-mgmt/util';
import { MercerSupportPositionClassHelper } from '@coin/shared/util-helpers';
import { UserState } from '@coin/modules/auth/data-management';
import { MercerState } from '../../mercer/state/mercer.state';
import { MercerSupportState } from '../../mercer-support/state/mercer-support.state';
import { SetMercerSupportPositionLock } from '../../mercer-support/state/mercer-support.actions';
import { MercerDialogType } from '../enums/mercer-dialog-type.enum';
import { MercerNewHireState } from '../enums/mercer-support-new-hire-state.enum';
import { MercerSupportTreeViewItem } from '../enums/mercer-support-tree-view-item.model';
import { MercerValidationState } from '../enums/mercer-support-validation-state.enum';
import { MercerEvaluationMember, MercerEvaluationMemberDetails } from '../models/mercer-support-evaluation-member.model';
import { MercerSupportIncumbentMember, ProposalState } from '../models/mercer-support-incumbent-member.model';

export class MercerSupportHelper {
  public static readonly seniorManagementPositionClassStart = MercerSupportPositionClassHelper.seniorManagementPositionClassStart;
  public static readonly minPositionClass = MercerSupportPositionClassHelper.minPositionClass;
  public static readonly maxPositionClass = MercerSupportPositionClassHelper.maxPositionClass;

  public static readonly isoCodeDE = MercerSupportPositionClassHelper.isoCodeDE;

  public static groupTreeItems(treeItems: MercerSupportTreeViewItem[]): Record<string, MercerSupportTreeViewItem[]> {
    return treeItems?.reduce(
      (sections, treeItem) => ({
        ...sections,
        [treeItem.orgType]: [...(sections[treeItem.orgType] ?? []), treeItem]
      }),
      {}
    );
  }

  public static hasSpecialization(data: MercerData): boolean {
    return !!data?.family && !!data?.subFamily && !!data?.specialization;
  }

  /**
   *
   * @param dialogType
   * @param member
   * @param store
   * @returns if the position have editable specs
   */
  public static arePositionSpecsEditable(dialogType: MercerDialogType, member: MercerEvaluationMemberDetails, store: Store): boolean {
    if (this.isReadOnly(member, dialogType, store) || this.isLockedNewHire(member)) {
      return false;
    }

    const isManagerOrHeadcount = [MercerDialogType.Manager, MercerDialogType.Headcount].includes(dialogType);
    const arePositionEditableForManagerOrHeadcount =
      (isManagerOrHeadcount ? !this.isPositionRequested(member) && !this.isPositionRequestedForCBAdminOrFinal(member) : this.arePositionSpecsEditableInSupportApp(member, store)) &&
      (dialogType === MercerDialogType.Headcount || MercerSupportHelper.isValidationEditable(member, store, dialogType));

    return arePositionEditableForManagerOrHeadcount;
  }

  private static arePositionSpecsEditableInSupportApp(member: MercerEvaluationMemberDetails, store: Store): boolean {
    return this.isHrUser(store) ? !this.isPositionRequestedForCBAdminOrFinal(member) : !this.isPositionSetToFinal(member);
  }

  public static isPositionOpenOrRejected(member: MercerEvaluationMember): boolean {
    return [MercerValidationState.Open, MercerValidationState.Rejected].includes(member?.validationState);
  }

  public static isPositionSetToFinal(member: MercerEvaluationMember): boolean {
    return member?.validationState === MercerValidationState.Accepted;
  }

  public static isPositionRequested(member: MercerEvaluationMember): boolean {
    return member?.validationState === MercerValidationState.Requested;
  }

  public static isPositionRequestedForCBAdmin(member: MercerEvaluationMember): boolean {
    return member?.validationState === MercerValidationState.RequestedCb;
  }

  public static isPositionRequestedForCBAdminOrFinal(member: MercerEvaluationMember): boolean {
    return [MercerValidationState.RequestedCb, MercerValidationState.Accepted, MercerValidationState.Published].includes(member?.validationState);
  }

  public static hidePositionClass(member: MercerEvaluationMemberDetails): boolean {
    return member?.isPositionClassHidden;
  }

  public static isIncumbentRemovable(incumbent: MercerSupportIncumbentMember, store: Store): boolean {
    return this.isAdmin(store) || ![ProposalState.Accepted, ProposalState.Communicated].includes(incumbent.proposalState);
  }

  public static isPlacementEditable(dialogType: MercerDialogType, member: MercerEvaluationMember, store: Store): boolean {
    return (
      (this.isAdmin(store) || member?.isPlacementEditable) &&
      this.getSeasonSettings(dialogType, store)?.isPlacementSettingsEnabled &&
      !this.isReadOnly(member, dialogType, store) &&
      !this.isLockedNewHire(member)
    );
  }

  public static isValidationEditable(member: MercerEvaluationMember, store: Store, type: MercerDialogType): boolean {
    return (this.isAdmin(store) || member?.isValidationEditable) && !this.isReadOnly(member, type, store) && !this.isLockedNewHire(member);
  }

  public static isCountryEditable(dialogType: MercerDialogType, member: MercerEvaluationMember, store: Store): boolean {
    if (this.isReadOnly(member, dialogType, store) || this.isLockedNewHire(member)) {
      return false;
    }
    const seasonSettings = this.getSeasonSettings(dialogType, store);
    const permission = seasonSettings.positionCountryEditableBy;
    switch (permission) {
      case MercerEditableByType.Nobody:
        return false;
      case MercerEditableByType.AdminOnly:
        return dialogType === MercerDialogType.Support && this.isAdmin(store);
      case MercerEditableByType.AdminAndHr:
        return dialogType === MercerDialogType.Support && (this.isAdmin(store) || this.isHrUser(store));
      case MercerEditableByType.All:
        return true;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return false;
  }

  /** Return true if the user is Super Admin or CB Admin */
  public static isAdmin(store: Store): boolean {
    const userPermissions = store.selectSnapshot(UserState.allPermissions);
    return userPermissions?.some(
      ({ resource }) => resource === PermissionResource.All || (resource === PermissionResource.AdminApp && this.hasPositionEvaluationPermissions(store))
    );
  }

  public static isCandBUserOrAdmin(store: Store): boolean {
    return (
      (store.selectSnapshot(UserState.allPermissions)?.some(permission => permission.resource === PermissionResource.CAndB) && this.hasPositionEvaluationPermissions(store)) ||
      this.isAdmin(store)
    );
  }

  public static isHrUser(store: Store): boolean {
    return store.selectSnapshot(UserState.allPermissions)?.every(permission => permission.resource !== PermissionResource.AdminApp) && this.hasPositionEvaluationPermissions(store);
  }

  public static isNonAdmin(store: Store): boolean {
    return !this.isAdmin(store);
  }

  private static hasPositionEvaluationPermissions(store: Store): boolean {
    return store.selectSnapshot(UserState.allPermissions)?.some(permission => permission.resource === PermissionResource.PositionEvaluationHR);
  }

  public static getDisplayCurrency(dialogType: MercerDialogType, store: Store, memberDetails: MercerEvaluationMemberDetails): CurrencyIsoCode {
    const currency = dialogType === MercerDialogType.Manager ? store.selectSnapshot(MercerState.selectedCurrency) : store.selectSnapshot(MercerSupportState.selectedCurrency);
    return currency === DisplayedCurrency.EUR ? DisplayedCurrency.EUR : memberDetails?.currencyExternal;
  }

  public static isGerman(member: MercerEvaluationMember): boolean {
    return member?.countryIsoCode === this.isoCodeDE;
  }

  /**
   * we check if position is a german non-senior to only target german users that are not allowed to see the position class
   * but if the derived position class is >= than the senior mgmt position class, we show the position section just with the derived position class
   */
  public static isPositionSectionVisibleForGermanNonSeniors(
    memberDetails: MercerEvaluationMemberDetails,
    type: MercerDialogType,
    store: Store,
    derivedPositionClass: number
  ): boolean {
    return MercerSupportHelper.isGermanNonSeniorBasedOnSetting(memberDetails) && derivedPositionClass >= MercerSupportHelper.seniorManagementPositionClassStart;
  }

  public static isFinalizationAllowedWithoutInput(dialogType: MercerDialogType, memberDetails: MercerEvaluationMember, store: Store): boolean {
    return dialogType === MercerDialogType.Manager && MercerSupportHelper.isGermanNonSeniorBasedOnSetting(memberDetails);
  }

  public static getSeasonSettings(type: MercerDialogType, store: Store): PositionEvaluationSetting {
    switch (type) {
      case MercerDialogType.Support:
        return store.selectSnapshot(MercerSupportState.seasonSettings);
      case MercerDialogType.Manager:
        return store.selectSnapshot(MercerState.seasonSettings);
      // TODO: add from endpoint once implemented: /customer/api/v1/master/position-evaluation/settings
      case MercerDialogType.Headcount:
        return { questionnaireDecision: MercerSurveyDecision.JobFamilyBasis } as PositionEvaluationSetting;
    }
  }

  public static isQuestionnaireDisabledForGermanNonSeniors(member: MercerEvaluationMember, type: MercerDialogType, store: Store): boolean {
    return MercerSupportHelper.isGermanNonSeniorBasedOnSetting(member) && MercerSupportHelper.getSeasonSettings(type, store)?.isQuestionnaireDisabledForGermanNonSeniors;
  }

  public static isReadOnly(member: MercerEvaluationMember, type: MercerDialogType, store: Store): boolean {
    return this.isLocked(member) || this.isManagerReadOnly(type, store);
  }

  public static canAddNewPositions(type: MercerDialogType, store: Store): boolean {
    return type === MercerDialogType.Support && MercerSupportHelper.isAdmin(store) && MercerSupportHelper.getSeasonSettings(type, store)?.isAllowedToAddPositions;
  }

  public static isGermanNonSeniorBasedOnSetting(member: MercerEvaluationMember): boolean {
    return !!member?.isGermanNonSeniorManagement;
  }

  public static getSurveyResult(survey: CustomerSurvey): CustomerSurveyResult {
    const resultQuestion = survey?.questions?.find(
      question => question.isActive && question.answers.find(answer => answer.linkedSurveyResultId && answer.id === question.selectedAnswerId)
    );

    if (!resultQuestion) {
      return null;
    }

    const answer = resultQuestion.answers.find(answer => answer.linkedSurveyResultId && answer.id === resultQuestion.selectedAnswerId);
    return survey?.results?.find(result => result.id === answer.linkedSurveyResultId);
  }

  private static isLocked(member: MercerEvaluationMember): boolean {
    return member?.isLocked;
  }

  public static isManagerReadOnly(type: MercerDialogType, store: Store): boolean {
    return this.getSeasonSettings(type, store)?.isManagerAppReadOnly && type === MercerDialogType.Manager;
  }

  public static isLockedNewHire(member: MercerEvaluationMember): boolean {
    return this.isNewHire(member) && this.getNewHireState(member) !== MercerNewHireState.Approved;
  }

  public static isNewHire(member: MercerEvaluationMember): boolean {
    return member?.isNewHire;
  }

  public static getNewHireState(member: MercerEvaluationMember): MercerNewHireState {
    return member?.newHireState;
  }

  public static canLock(store: Store, member: MercerEvaluationMember, dialogType: MercerDialogType): boolean {
    return !this.isLockedNewHire(member) && !this.isReadOnly(member, dialogType, store) && this.isAdmin(store) && dialogType === MercerDialogType.Support;
  }

  public static canUnlock(store: Store, member: MercerEvaluationMember, dialogType: MercerDialogType): boolean {
    return !this.isLockedNewHire(member) && this.isReadOnly(member, dialogType, store) && this.isAdmin(store) && dialogType === MercerDialogType.Support;
  }

  public static canReviewNewHire(store: Store, member: MercerEvaluationMember, type: MercerDialogType): boolean {
    return !this.isReadOnly(member, type, store) && this.isNewHire(member) && this.getNewHireState(member) === MercerNewHireState.ForReview;
  }

  public static lock(store: Store, memberId: string): Observable<unknown> {
    return store.dispatch(new SetMercerSupportPositionLock({ evaluationMemberId: memberId, lock: true }));
  }

  public static unlock(store: Store, memberId: string): Observable<unknown> {
    return store.dispatch(new SetMercerSupportPositionLock({ evaluationMemberId: memberId, lock: false }));
  }

  public static isTreeView(router: Router): boolean {
    return router.url.includes('tree-view');
  }

  public static canDeclineOrFinalizePositionClassBasedOnValidationState(member: MercerEvaluationMember, store: Store, dialogType: MercerDialogType): boolean {
    if (MercerSupportHelper.isPositionRequested(member)) {
      return MercerSupportHelper.isAdmin(store) || (MercerSupportHelper.isHrUser(store) && dialogType === MercerDialogType.Support);
    }

    if (MercerSupportHelper.isPositionRequestedForCBAdmin(member)) {
      return MercerSupportHelper.isAdmin(store);
    }

    return true;
  }

  public static isFinalizationAllowed(config: {
    member: MercerEvaluationMember;
    derivedPositionClass: number;
    managerAllowed: boolean;
    store: Store;
    dialogType: MercerDialogType;
    adjustedPositionClass: number;
    mercerData: MercerData;
    surveyWasSaved: boolean;
  }): boolean {
    const isQuestionnaireDisabledForGermanNonSeniors = this.isQuestionnaireDisabledForGermanNonSeniors(config.member, config.dialogType, config.store);

    return (
      (!!config.member?.newPositionClass ||
        !!config.derivedPositionClass ||
        config.member?.isPositionClassHidden ||
        (config.member?.isGermanNonSeniorManagement && this.isAdmin(config.store))) &&
      (isQuestionnaireDisabledForGermanNonSeniors ? !!config.mercerData?.specializationCode : !!config.mercerData?.jobCode) &&
      !MercerSupportHelper.isPositionSetToFinal(config.member) &&
      this.canDeclineOrFinalizePositionClassBasedOnValidationState(config.member, config.store, config.dialogType) &&
      this.isValidationEditable(config.member, config.store, config.dialogType) &&
      (config?.dialogType === MercerDialogType.Support ||
        !!config.adjustedPositionClass ||
        this.isFinalizationAllowedWithoutInput(config.dialogType, config.member, config.store)) &&
      (config.managerAllowed || isQuestionnaireDisabledForGermanNonSeniors) &&
      !(this.isPositionRequestedForCBAdmin(config.member) && this.isHrUser(config.store)) &&
      !(config.dialogType === MercerDialogType.Manager && this.isPositionRequested(config.member)) &&
      config.surveyWasSaved
    );
  }

  public static finalizeButtonTooltipText(config: {
    adjustedPositionClass: number;
    derivedPositionClass: number;
    member: MercerEvaluationMemberDetails;
    isFinalizationAllowed: boolean;
  }): string {
    if (!config.isFinalizationAllowed && config.derivedPositionClass && config.adjustedPositionClass) {
      const validPc = Math.abs(config.derivedPositionClass - config.adjustedPositionClass) <= 1;
      if (!validPc) {
        return 'mercer-support.dialog.manager-invalid-pc';
      }
      if (!config.member.deviationReason) {
        return 'mercer-support.dialog.manager-valid-pc-no-reason';
      }
    }
    return '';
  }

  public static deleteCascadingFieldsIfNecessary = deleteCascadingFieldsIfNecessary;

  public static isPositionClassOutOfRange({ newPc, derivedPc, dialogType }: { newPc: number; derivedPc: number; dialogType: MercerDialogType }): boolean {
    // In case of PC is not set at all
    if (isNaN(newPc)) return false;

    // In case we found a derived position class.
    if (!isNaN(derivedPc) && derivedPc >= this.minPositionClass) {
      return (
        this.minPositionClass > newPc ||
        newPc > this.maxPositionClass ||
        ([MercerDialogType.StandingEmployeeProfile, MercerDialogType.Manager].includes(dialogType) && Math.abs(derivedPc - newPc) > 1) // new must be +/- 1 of derived for managers
      );
    }

    // In case there was an issue finding a derived position class
    return this.minPositionClass > newPc || newPc > this.maxPositionClass;
  }
}
