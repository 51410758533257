import { Component, Input } from '@angular/core';
import { TranslationFile } from '@coin/shared/util-models';
import { CmsItem } from '@coin/admin/cms/util';
import { HtmlEditorComponent } from '../../shared/html-editor/html-editor.component';

@Component({
  selector: 'coin-gmm-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss'],
  standalone: true,
  imports: [HtmlEditorComponent]
})
export class QuoteComponent {
  // TODO: check if quote has been removed
  @Input() item: CmsItem;
  @Input() contentEdit = false;
  @Input() translationFile: TranslationFile;

  constructor() {}
}
