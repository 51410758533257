<div class="item-container">
  <div class="pic-container">
    <div class="pic-image" [ngStyle]="{ backgroundImage: 'url(' + item.img + ')' }"></div>
  </div>
  <div class="text-container">
    @if (!contentEdit) {
      <h3>{{ item.headline }}</h3>
    }
    @if (contentEdit) {
      <h3 [contentEditable]="contentEdit" [textContent]="item.headline" (input)="item.headline = $event.target.textContent ? $event.target.textContent : 'headline'"></h3>
    }
    @if (!contentEdit) {
      <p style="margin-top: 30px; margin-bottom: 30px" [innerHTML]="item.text"></p>
    }
    @if (contentEdit) {
      <coin-html-editor [item]="item" [contentEdit]="true" [isNews]="true" style="margin-top: 15px"></coin-html-editor>
    }
    @if (item.link && item.link.length) {
      <div class="link">
        @if (!contentEdit) {
          <coin-v2-button (click)="gotoPage(item.link)" type="primary" icon="arrow" [text]="item.buttonText"></coin-v2-button>
        }
        <!-- <a *ngIf="!contentEdit" [href]="item.link" target="_blank">{{getTranslateText(item.buttonText)}}</a> -->
        @if (contentEdit) {
          <mat-form-field style="width: 100%; margin-top: 20px">
            <mat-label>{{ 'cms.button-text' | translate }}</mat-label>
            <input matInput aria-label="Button text" [(ngModel)]="item.buttonText" [placeholder]="'Button text'" [disabled]="!contentEdit" type="text" />
          </mat-form-field>
        }
        @if (contentEdit) {
          <mat-form-field style="width: 100%; margin-top: 10px">
            <mat-label>{{ 'cms.button-link-url' | translate }}</mat-label>
            <input matInput aria-label="Link url" [(ngModel)]="item.link" [placeholder]="'https://...'" [disabled]="!contentEdit" type="test" />
          </mat-form-field>
        }
        <!-- <a *ngIf="contentEdit" [contentEditable]="contentEdit" [textContent]="getTranslateText(item.buttonText)" ((input)="updateText($event.target.textContent, item.buttonText)" target="_blank"></a> -->
        <!-- <i class="icon icon--arrow-right"></i> -->
      </div>
    }
  </div>
</div>

@if (contentEdit) {
  <div class="inputs">
    <!-- <mat-form-field *ngIf="contentEdit">
        <mat-label>{{'cms.link-url' | translate}}</mat-label>
        <input matInput aria-label="Link url" [(ngModel)]="item.link" [placeholder]="'https://...'" [disabled]="!contentEdit" type="test">
    </mat-form-field> -->
    @if (contentEdit) {
      <coin-v2-button (click)="editPicture(item)" type="secondary" svgIcon="edit" text="Change Picture"></coin-v2-button>
    }
  </div>
}
