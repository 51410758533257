import { Component, Input, OnInit } from '@angular/core';
import { CmsItem, DefaultColorPickerValues } from '@coin/admin/cms/util';
import { V2CheckboxComponent } from '@coin/shared/ui-storybook';
import { LegacyComponentsModule } from '@coin/shared/feature-legacy-components';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { EmailSliderComponent } from '../../shared';

@Component({
  selector: 'coin-email-button',
  templateUrl: './email-button.component.html',
  styleUrls: ['./email-button.component.scss'],
  standalone: true,
  imports: [V2CheckboxComponent, EmailSliderComponent, LegacyComponentsModule, FormsModule, MatFormFieldModule, TranslateModule, NgClass, MatInput]
})
export class EmailButtonComponent implements OnInit {
  @Input() item: CmsItem<'email-button'>;
  @Input() contentEdit = false;
  @Input() showBackgroundPicker = false;

  ngOnInit(): void {
    this.item ??= {} as CmsItem<'email-button'>;
    this.item.content ??= {} as never;
    this.setDefaultBackgroundColor();
  }

  private setDefaultBackgroundColor(): void {
    this.item.backgroundColor = this.item.backgroundColor || DefaultColorPickerValues.backgroundColor;
  }
}
