import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { LegacyComponentsModule } from '@coin/shared/feature-legacy-components';
import { V2ButtonComponent, V2RadioButtonComponent } from '@coin/shared/ui-storybook';
import { FilterPipe } from '@coin/shared/util-pipes';
import { TranslateModule } from '@ngx-translate/core';
import { CustomerSurveyAnswerComponent } from './customer-survey-answer/customer-survey-answer.component';
import { CustomerSurveyQuestionComponent } from './customer-survey-question/customer-survey-question.component';
import { CustomerSurveyComponent } from './customer-survey/customer-survey.component';

@NgModule({
  declarations: [CustomerSurveyComponent, CustomerSurveyQuestionComponent, CustomerSurveyAnswerComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    LegacyComponentsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatExpansionModule,
    FilterPipe,
    V2RadioButtonComponent,
    V2ButtonComponent
  ],
  exports: [CustomerSurveyComponent, CustomerSurveyAnswerComponent]
})
export class CustomerSurveyModule {}
