import { Component, DestroyRef, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FirstVisitService } from '@coin/modules/auth/data-management';
import { StorageService } from '@coin/shared/data-access';
import { ConfirmationDialogComponent } from '@coin/shared/ui-storybook';
import { StorageKey } from '@coin/shared/util-enums';
import { environment } from '@coin/shared/util-environments';

@Component({
  selector: 'coin-a-g2m-tour',
  templateUrl: './g2m-tour.component.html',
  styleUrls: ['./g2m-tour.component.scss'],
  standalone: false
})
export class G2mTourComponent implements OnInit {
  @ViewChild('calmain') calmain: ElementRef;

  public imageLink: string;
  public text: { headline: string; text: string };
  public specialImageData: string;
  public tourMaxHeight = 'calc(100vh - 210px)';
  public tourMaxWidth = '1000px';

  /// nso represents the backgrounds for the guided tour, with properties for the border-width
  public nso:
    | {
        bt: string;
        br: string;
        bb: string;
        bl: string;
      }
    | undefined;
  /// nso2 represents a background square that takes the whole screen, for the guided tour, with properties for margin
  public nso2:
    | {
        bt: string;
        br: string;
        bb: string;
        bl: string;
      }
    | undefined;

  public selectedTab = 0;
  public needsColumn = false;
  public tourLength = ['get-started', 'employee-overview', 'employee-details', 'edit-mode', 'buttons-description', 'thats-it'] as const;

  // Set it to true at first, to close everything the user could have done beforehand
  // when calling removeAll() for the first time
  private isShowingEditMode = true;

  /// For dragging the dialog around
  public offset = { x: 0, y: 0 };

  constructor(
    private elemRef: MatDialogRef<G2mTourComponent>,
    private firstVisit: FirstVisitService,
    private dialog: MatDialog,
    private router: Router,
    private storageService: StorageService,
    private destroyRef: DestroyRef,
    @Inject(MAT_DIALOG_DATA) public data: { manualStart: boolean }
  ) {
    this.firstVisit.activate();
  }

  ngOnInit(): void {
    document.getElementsByClassName('backdrop-hide-remove')?.[0]?.classList.add('show');
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 500);
  }

  private removeAll(): void {
    this.nso = undefined;
    this.nso2 = undefined;
    this.needsColumn = false;
    this.tourMaxHeight = 'calc(100vh - 210px)';
    this.tourMaxWidth = '1000px';
    this.offset = { x: 0, y: 0 };

    // In case user goes from the edit mode to another tab
    this.closeEditMode();

    const elemsToClose = [
      { tag: 'app-apps', class: 'less-visible', isTag: true },
      { tag: 'app-directs', class: 'less-visible', isTag: true },
      { tag: 'side-bar-main-item', class: 'highlight-this-part', isTag: false },
      { tag: 'backdrop-hide-remove', class: 'show', isTag: false },
      { tag: 'backdrop-hide-remove', class: 'show', isTag: false },
      { tag: 'faq-sidebar', class: 'highlight-this-part', isTag: false },
      { tag: 'app-sidebar', class: 'sidebar-visible', isTag: true }
    ];

    for (const itm of elemsToClose) {
      this.removeSingle(itm.tag, itm.class, itm.isTag);
    }
  }

  private removeSingle(tag: string, className: string, isTag: boolean): void {
    const elem = isTag ? document.getElementsByTagName(tag) : document.getElementsByClassName(tag);
    if (elem[0] && elem[0].classList) {
      elem[0].classList.remove(className);
    }
  }

  public tabChange(i: number): void {
    const marginHorizontal = '1vw';
    const marginVertical = '6vh';

    const headerHeight = `${document.querySelector('.header').clientHeight}px`;
    const employeeDetailsHeight = 150; // in px
    switch (i) {
      case 0:
        this.removeAll();
        this.elemRef.removePanelClass('half-width');
        this.nso = { bt: '50vh', bl: '50vw', br: '50vw', bb: '50vh' };
        this.elemRef.updatePosition({ top: null, bottom: null });

        break;
      case 1:
        this.removeAll();
        this.nso = { bt: '50vh', bl: '50vw', br: '50vw', bb: '50vh' };
        this.elemRef.updatePosition({ top: null, bottom: null });

        this.imageLink = `${environment.coinAssetsPath}/videos/get_started.mp4`;
        this.text = { headline: 'guided-tour.g2m.get-started', text: 'guided-tour.g2m.get-started-text' };
        break;
      case 2:
        // Employee Overview
        this.removeAll();
        this.scrollEmployeesToTop();
        this.nso = {
          bt: `calc(${marginVertical} + ${headerHeight})`,
          br: 'min(41vw, 99vw - 870px)',
          bb: `calc(${marginVertical} + 70px)`,
          bl: marginHorizontal
        };
        this.elemRef.updatePosition({ right: '2.5vw' });

        this.imageLink = `${environment.coinAssetsPath}/videos/employee_overview.mp4`;
        this.text = { headline: 'guided-tour.g2m.employee-overview', text: 'guided-tour.g2m.employee-overview-text' };
        break;
      case 3:
        // Employee Details
        this.removeAll();
        this.scrollEmployeesToTop();
        this.nso = {
          bt: `calc(${marginVertical} + ${headerHeight} + 152px)`,
          br: 'calc(min(41vw, 99vw - 870px) + 15px)',
          bb: `calc(98vh - 220px - ${employeeDetailsHeight}px)`,
          bl: `calc(${marginHorizontal} + 15px)`
        };
        this.elemRef.updatePosition({ top: null, bottom: '100px' });

        this.imageLink = `${environment.coinAssetsPath}/videos/Employee_Details.mp4`;
        this.text = { headline: 'guided-tour.g2m.employee-details', text: 'guided-tour.g2m.employee-details-text' };

        break;
      case 4:
        // Edit mode
        this.removeAll();
        this.openEditMode();
        this.elemRef.updatePosition({ top: null, bottom: '2.5vh' });
        this.tourMaxWidth = 'min(80vw, 1800px)';
        this.tourMaxHeight = '300px';
        this.nso = {
          bt: `calc(${marginVertical} + ${headerHeight} + 152px)`,
          br: 'calc(min(41vw, 99vw - 870px) + 15px)',
          bb: `calc(98vh - 220px - ${employeeDetailsHeight}px)`,
          bl: `calc(${marginHorizontal} + 15px)`
        };
        setTimeout(() => {
          if (!this.isShowingEditMode) {
            return;
          }

          this.nso = {
            bt: `calc(${marginVertical} + ${headerHeight})`,
            br: `calc(${marginHorizontal})`,
            bb: 'calc((94vh - 260px) - 150px)',
            bl: 'max(calc(1vw + 870px), 59vw)'
          };
        }, 1000);
        setTimeout(() => {
          if (!this.isShowingEditMode) {
            return;
          }

          this.nso = {
            bt: `calc(${marginVertical} + ${headerHeight})`,
            br: `calc(${marginHorizontal})`,
            bb: `calc(${marginVertical} + 70px)`,
            bl: `calc(${marginHorizontal} + 625px)`
          };
        }, 2000);

        this.imageLink = `${environment.coinAssetsPath}/videos/EditMode.mp4`;
        this.text = { headline: 'guided-tour.g2m.edit-mode', text: 'guided-tour.g2m.edit-mode-text' };
        break;
      case 5:
        // Buttons at the top & bottom
        this.removeAll();
        this.nso = {
          bt: marginVertical,
          br: marginHorizontal,
          bb: marginVertical,
          bl: marginHorizontal
        };
        this.nso2 = {
          bt: `calc(${marginVertical} + ${headerHeight})`,
          br: `${marginHorizontal}`,
          bb: `calc(${marginVertical} + 68px)`,
          bl: `${marginHorizontal}`
        };

        this.elemRef.updatePosition({ top: null, bottom: null });
        this.tourMaxHeight = '50vh';

        this.imageLink = `${environment.coinAssetsPath}/videos/Buttons.mp4`;
        this.text = { headline: 'guided-tour.g2m.buttons-description', text: 'guided-tour.g2m.buttons-description-text' };
        break;
      case 6:
        // That's it
        this.removeAll();
        this.nso = { bt: '50vh', bl: '50vw', br: '50vw', bb: '50vh' };
        this.elemRef.updatePosition({ top: null, bottom: null });

        this.imageLink = `${environment.coinAssetsPath}/videos/Thatsit.mp4`;
        this.text = { headline: 'guided-tour.g2m.thats-it', text: 'guided-tour.g2m.thats-it-text' };
        break;
    }
  }

  /// Simulates the user opening the edit mode (in about 2s)
  private openEditMode(): void {
    this.isShowingEditMode = true;
    const checkedCircle = document.querySelectorAll('.checked-circle')[1] as HTMLElement;
    const checkboxChecked = checkedCircle.classList.contains('checked');
    if (!checkboxChecked) {
      checkedCircle.click();
    }
    setTimeout(() => {
      if (!this.isShowingEditMode) {
        return;
      }
      // Only click if the user didn't close the edit mode presentation in the meantime
      (document.querySelector('.job-profile-edit-button') as HTMLElement).click();
    }, 2000);
  }

  /// Simulates the user closing the edit mode (in about 50ms)
  private closeEditMode(): void {
    if (!this.isShowingEditMode) {
      return;
    }

    this.isShowingEditMode = false;
    const closeButton = document.querySelector('.job-profile-close-button') as HTMLElement;
    if (closeButton !== undefined && closeButton !== null) {
      closeButton.click();
    }

    setTimeout(() => {
      const checkedCircle = document.querySelectorAll('.checked-circle')[1] as HTMLElement;
      const checkboxChecked = checkedCircle.classList.contains('checked');
      if (checkboxChecked) {
        checkedCircle.click();
      }
    }, 50);
  }

  public async closeVisit(): Promise<void> {
    const visitCompleted = (await this.storageService.getAsync(StorageKey.FIRST_G2M_COMPLETED)) === 'true';
    if (visitCompleted || (this.data && this.data.manualStart)) {
      // Visit was already completed OR user manually started the guided tour process:
      // No need to ask for confirmation to display guided tour next time
      this.closeThis(visitCompleted);
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: true,
        data: {
          headline: 'general.close-confirm',
          msg: 'general.cancel-guided-tour',
          cancelMsg: 'general.proceed-next-time',
          confirmMsg: 'general.confirm',
          translate: true
        }
      });
      dialogRef
        .afterClosed()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(result => {
          this.closeThis(result);
        });
    }
  }

  /// Automatically scrolls to the top of the employee list
  private scrollEmployeesToTop(): void {
    const simpleBar = document.querySelector('coin-siemens-energy-employee-list .simplebar-content-wrapper');
    if (simpleBar !== undefined) {
      simpleBar.scrollTo(0, 0);
    }
  }

  private async closeThis(nevershow: boolean): Promise<void> {
    if (nevershow) {
      await this.storageService.setAsync(StorageKey.FIRST_G2M_COMPLETED, 'true');
    }
    this.removeAll();
    this.firstVisit.deactivate();
    this.firstVisit.closeGuidedTour();
    this.elemRef.close();
  }

  public next(): void {
    this.selectedTab += 1;
  }

  public back(): void {
    this.selectedTab -= 1;
  }

  public completeTour(): void {
    this.closeThis(true);
    this.elemRef.close();
  }

  public openGrip2MercerPage(): void {
    window.open('/G2M', '_blank');
  }
}
