import { Component, computed, input, Input, Signal } from '@angular/core';

@Component({
  selector: 'coin-c-icon-text-card',
  templateUrl: './icon-text-card.component.html',
  styleUrls: ['./icon-text-card.component.scss'],
  standalone: false
})
export class IconTextCardComponent {
  public icon = input<string>();
  public svgIcon = input<string>();
  public backgroundColor = input<string>();
  public title = input<string>();
  public subTitle = input<string>();
  public textOverflowBehaviour = input<'wrap' | 'truncate'>('truncate');

  protected shouldTruncateText: Signal<boolean> = computed(() => this.textOverflowBehaviour() === 'truncate');
}
