import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { V2DialogModule } from '@coin/shared/ui-storybook';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'coin-enlarge-image',
  templateUrl: './enlarge-image.component.html',
  styleUrls: ['./enlarge-image.component.scss'],
  standalone: true,
  imports: [V2DialogModule, TranslateModule]
})
export class EnlargeImageComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { imageUrl: string },
    private dialogRef: MatDialogRef<EnlargeImageComponent>
  ) {}

  close(): void {
    this.dialogRef.close();
  }
}
