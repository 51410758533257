import { Component } from '@angular/core';
import { availableServiceProvider, ServiceProvider } from './service-provider-list.config';

@Component({
  selector: 'app-service-provider-list',
  templateUrl: './service-provider-list.component.html',
  styleUrls: ['./service-provider-list.component.scss'],
  standalone: false
})
export class ServiceProviderListComponent {
  public readonly serviceProvider: ServiceProvider[] = availableServiceProvider;
  public readonly columnWidths: string[] = ['5%', '15%', '15%', '20%', '15%', '30%'];
}
