import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { CmsItem } from '@coin/admin/cms/util';
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';
import { HtmlEditorComponent } from '../../shared';

@Component({
  selector: 'coin-equity-expand-box',
  templateUrl: './equity-expand-box.component.html',
  styleUrls: ['./equity-expand-box.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatLabel, FormsModule, TranslateModule, NgClass, HtmlEditorComponent, MatInput]
})
export class EquityExpandBoxComponent {
  @Input() item: CmsItem<'expand-box'>;
  @Input() contentEdit = false;

  public boxExpanded = false;
}
