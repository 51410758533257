@if (isEquityPage && !item?.hideChapterOverview && !hideChapters) {
  <coin-equity-cms-chapter-overview
    class="cms-item hover-effect chapter-overview"
    [update]="overviewUpdate"
    [item]="item?.content"
    (click)="chapterOverviewClicked.emit()"></coin-equity-cms-chapter-overview>
}
@for (subItem of item?.content; track subItem) {
  <div
    class="cms-item"
    [id]="subItem.id"
    (click)="itemClicked(subItem, $event)"
    [style.background-color]="subItem.backgroundColor"
    [ngClass]="{ 'highlight-item': highlightId === subItem.id, 'fadeout-item': highlightId && highlightId !== subItem.id, 'hover-effect': isEquityPage }"
    [hidden]="
      (settings?.germanyRowSplit && germanyRowType !== 'both' && subItem.germanyRowType !== germanyRowType && subItem.germanyRowType !== 'both' && subItem.germanyRowType) ||
      (subItem.requiresAuthentication && !isAuthenticated)
    "
    [ngClass]="{ 'equity-width-limited': isEquityPage && !subItem.fullWidth }">
    @if (subItem.type === 'text-block') {
      <coin-gmm-text-block [id]="subItem.id" [item]="subItem" [ngClass]="{ hasMargin: hasMargin }"></coin-gmm-text-block>
    }
    @if (subItem.type === 'image-slider') {
      <coin-gmm-image-slider [id]="subItem.id" [item]="subItem" class="showOnScroll has-margin"></coin-gmm-image-slider>
    }
    @if (subItem.type === 'quote') {
      <coin-gmm-quote [id]="subItem.id" [item]="subItem" class="showOnScroll has-margin"></coin-gmm-quote>
    }
    @if (subItem.type === 'spacer') {
      <coin-gmm-spacer [item]="subItem" [ngClass]="{ hasMargin: hasMargin }"></coin-gmm-spacer>
    }
    @if (subItem.type === 'picture-container') {
      <coin-gmm-picture-container [heightModified]="heightModified" [item]="subItem" class="showOnScroll has-margin"></coin-gmm-picture-container>
    }
    @if (subItem.type === 'compilation') {
      <coin-gmm-compilation [item]="subItem" [startanimation]="true" class="showOnScroll has-margin"></coin-gmm-compilation>
    }
    @if (subItem.type === 'numbers') {
      <coin-gmm-numbers [noCounting]="true" [item]="subItem" [startanimation]="true" class="showOnScroll has-margin"></coin-gmm-numbers>
    }
    @if (subItem.type === 'text-image-link') {
      <coin-gmm-text-image-link [item]="subItem" class="showOnScroll has-margin"></coin-gmm-text-image-link>
    }
    @if (subItem.type === 'line') {
      <coin-gmm-line [item]="subItem"></coin-gmm-line>
    }
    @if (subItem.type === 'editor' || subItem.type === 'email-headline' || subItem.type === 'bullet-list') {
      <coin-html-editor [item]="subItem" [ngClass]="{ hasMargin: hasMargin }"></coin-html-editor>
    }
    @if (subItem.type === 'headline-with-subheadline') {
      <coin-news-headline [item]="subItem" [ngClass]="{ hasMargin: hasMargin }"></coin-news-headline>
    }
    @if (subItem.type === 'expand-box') {
      <coin-equity-expand-box [item]="subItem" [ngClass]="{ hasMargin: hasMargin }"></coin-equity-expand-box>
    }
    @if (subItem.type === 'expand-linksbox') {
      <coin-equity-expand-linksbox [item]="subItem" [ngClass]="{ hasMargin: hasMargin }"></coin-equity-expand-linksbox>
    }
    @if (subItem.type === 'button') {
      <coin-cms-button [item]="subItem" [ngClass]="{ hasMargin: hasMargin }"></coin-cms-button>
    }
    @if (subItem.type === 'download-file') {
      <coin-download-file [isContentPage]="isEquityPage" [item]="subItem" [ngClass]="{ hasMargin: hasMargin }"></coin-download-file>
    }
    @if (subItem.type === 'email-button') {
      <coin-email-button [item]="subItem" [ngClass]="{ hasMargin: hasMargin }"></coin-email-button>
    }
    @if (subItem.type === 'single-or-multi-image') {
      <coin-email-single-double-image [isResponsive]="isResponsive" [item]="subItem" [ngClass]="{ hasMargin: hasMargin }"></coin-email-single-double-image>
    }
    @if (subItem.type === 'chapter') {
      <coin-equity-cms-chapter [germanyRowSlideToggle]="germanyRowSlideToggle" (slideChange)="slideChange.emit($event)" [item]="subItem"></coin-equity-cms-chapter>
    }
    @if (subItem.type === 'cards') {
      <coin-equity-cms-cards [modifiedWidth]="modifiedWidth" [item]="subItem" [ngClass]="{ hasMargin: hasMargin }"></coin-equity-cms-cards>
    }
    @if (subItem.type === 'full-width-part-with-image') {
      <coin-equity-cms-full-widthpart-with-image [item]="subItem"></coin-equity-cms-full-widthpart-with-image>
    }
    @if (subItem.type === 'main-body-image-text') {
      <coin-email-main-body-image-text [item]="subItem"></coin-email-main-body-image-text>
    }
    @if (subItem.type === 'index-list') {
      <coin-email-index-list [item]="subItem" [content]="item.content"></coin-email-index-list>
    }
  </div>
}
