import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CmsItem } from '@coin/admin/cms/util';
import { V2ButtonComponent } from '@coin/shared/ui-storybook';
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'coin-cms-button',
  templateUrl: './cms-button.component.html',
  styleUrls: ['./cms-button.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatLabel, TranslateModule, MatSelectModule, FormsModule, V2ButtonComponent, MatInput]
})
export class CmsButtonComponent {
  @Input() item: CmsItem<'button'>;
  @Input() contentEdit = false;

  public buttonStyles = ['primary', 'secondary'] as const;

  public buttonIcons = ['add', 'close', 'arrow', 'save', 'reset', 'notification', 'search', 'simulate', 'star'] as const;

  public openPage(link: string): void {
    window.open(link, '_blank');
  }
}
