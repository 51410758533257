import { NgStyle } from '@angular/common';
import { Component, DestroyRef, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { V2ButtonComponent } from '@coin/shared/ui-storybook';
import { TranslateModule } from '@ngx-translate/core';
import { SelectMediaComponent } from '../../../dialogs/shared/select-media/select-media.component';

export interface CardModel {
  headline: string;
  backgroundUrl: string;
  text: string;
  icon: string;
  button: {
    name: string;
    url: string;
  };
}

@Component({
  selector: 'coin-equity-cms-card-item',
  templateUrl: './equity-cms-card-item.component.html',
  styleUrls: ['./equity-cms-card-item.component.scss'],
  standalone: true,
  imports: [NgStyle, V2ButtonComponent, MatFormFieldModule, FormsModule, TranslateModule, MatInput]
})
export class EquityCmsCardItemComponent {
  @Input() card: CardModel;
  @Input() small: boolean;
  @Input() contentEdit = false;
  @Input() modifiedWidth = false;

  public get iconUrl(): string {
    return `assets/equity/icons/general/${this.card?.icon}.svg`;
  }

  constructor(
    private dialog: MatDialog,
    private domSanitizer: DomSanitizer,
    private destroyRef: DestroyRef
  ) {}

  public onButtonClick(button: CardModel['button']): void {
    if (button.url) {
      window.open(button.url, '_blank');
    }
  }

  getSanitizedUrl(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  /**
   * Open popup to set a new image from existing or upload a new one
   * @param item current image that is used in card item
   */
  public editPicture(item: CardModel): void {
    SelectMediaComponent.openDialog(this.dialog, { url: item?.backgroundUrl, mediaType: 'image' })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(newImage => {
        if (newImage) {
          item.backgroundUrl = newImage.mediaUrl;
        }
      });
  }
}
