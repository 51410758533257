import { DragDropModule } from '@angular/cdk/drag-drop';
import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEnExtra from '@angular/common/locales/extra/en';
import { Injectable, isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule, DomSanitizer, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CmsComponentsModule } from '@coin/admin/cms/feature-preview';
import { CustomerAppState } from '@coin/customer/common/feature';
import { ApiModule as IncentiveSupportApiModule, Configuration as IncentiveSupportApiConfiguration } from '@coin/customer/incentive-support-data-access';
import { AuthComponentsModule, AuthGmsAngularClientModule } from '@coin/modules/auth/feature';
import { AuthGmsClientConfig } from '@coin/modules/auth/util';
import { ApiModule as SuccessionManagementApiModule, Configuration as SuccessionManagementApiConfiguration } from '@coin/modules/succession-management/data-access';
import { ApiModule, Configuration as ApiConfiguration } from '@coin/shared/data-access';
import { AppEnvironment, CustomTranslationLoader, TranslationDebugParser } from '@coin/shared/data-access-translation-loader';
import { FeatureFlagDirective } from '@coin/shared/data-management-directives';
import { LegacyComponentsModule } from '@coin/shared/feature-legacy-components';
import { NumberFormatCountryIso } from '@coin/shared/util-enums';
import { environment } from '@coin/shared/util-environments';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxsModule } from '@ngxs/store';
import Hammer from 'hammerjs';
import { UpdateNotifierComponent, V2ButtonComponent } from '@coin/shared/ui-storybook';
import { provideDefaultDateFormats } from '@coin/shared/util-helpers';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContentPagesModule } from './content-pages/content-pages.module';
import { FvLeftComponent } from './first-visit-experience/components/fv-left/fv-left.component';
import { FvRightComponent } from './first-visit-experience/components/fv-right/fv-right.component';
import { G2mIntroComponent } from './first-visit-experience/components/g2m-intro/g2m-intro.component';
import { IntroComponent } from './first-visit-experience/components/intro/intro.component';
import { BeginTourComponent } from './first-visit-experience/dialogs/begin-tour/begin-tour.component';
import { G2mTourComponent } from './first-visit-experience/dialogs/g2m-tour/g2m-tour.component';
import { FirstVisitExperienceComponent } from './first-visit-experience/first-visit-experience.component';
import { httpInterceptorProviders } from './http-interceptors';
import { ApiModule as MercerStandingApiModule, Configuration as MercerStandingApiConfiguration } from './mercer-standing/api/generated';
import { DynamicLocaleId } from './shared/models/dynamic-locale-id.model';
import { SharedModule } from './shared/shared.module';
import { FooterComponent } from './static/footer/footer.component';
import { HeaderComponent } from './static/header/header.component';
import { ServiceProviderListComponent } from './static/service-provider-list/service-provider-list.component';

registerLocaleData(localeDe, NumberFormatCountryIso.German, localeDeExtra);
registerLocaleData(localeEn, NumberFormatCountryIso.English, localeEnExtra);

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL } // override default settings
  };
}

@NgModule({
  declarations: [
    AppComponent,
    BeginTourComponent,
    HeaderComponent,
    FooterComponent,
    IntroComponent,
    G2mIntroComponent,
    FvLeftComponent,
    FvRightComponent,
    FirstVisitExperienceComponent,
    G2mTourComponent,
    ServiceProviderListComponent
  ],
  imports: [
    MatBadgeModule,
    NgxsModule.forRoot(CustomerAppState, {
      developmentMode: !environment.production,
      selectorOptions: { suppressErrors: true }
    }),
    CmsComponentsModule,
    MatTabsModule,
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    MatIconModule,
    FormsModule,
    LegacyComponentsModule,
    TranslateModule.forRoot(CustomTranslationLoader.createConfig(AppEnvironment.Customer, TranslationDebugParser)),
    AuthGmsAngularClientModule.forRoot({ ...environment.authentication, ...environment.customerAuthentication } as AuthGmsClientConfig),
    DragDropModule,
    ContentPagesModule,
    SuccessionManagementApiModule.forRoot(() => {
      return new SuccessionManagementApiConfiguration({ basePath: environment.api.baseUrl });
    }),
    ApiModule.forRoot(() => {
      return new ApiConfiguration({ basePath: environment.api.baseUrl });
    }),
    IncentiveSupportApiModule.forRoot(() => {
      return new IncentiveSupportApiConfiguration({ basePath: environment.api.baseUrl });
    }),
    MercerStandingApiModule.forRoot(() => {
      return new MercerStandingApiConfiguration({ basePath: environment.api.baseUrl });
    }),
    FeatureFlagDirective,
    AuthComponentsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWithDelay:5000'
    }),
    UpdateNotifierComponent,
    V2ButtonComponent
  ],
  providers: [
    httpInterceptorProviders,
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    TranslateService,
    { provide: LOCALE_ID, deps: [TranslateService], useClass: DynamicLocaleId },
    provideHttpClient(withInterceptorsFromDi()),
    provideDefaultDateFormats()
  ],
  exports: [FirstVisitExperienceComponent],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.loadIcons();
  }

  private loadIcons(): void {
    this.matIconRegistry.addSvgIcon('manager', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/svgs/org-planning/manager.svg'));
    this.matIconRegistry.addSvgIcon('manager_white', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svgs/org-planning/manager_white.svg'));
    this.matIconRegistry.addSvgIcon('org', this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/svgs/org.svg'));
    this.matIconRegistry.addSvgIcon('collapse_all', this.domSanitizer.bypassSecurityTrustResourceUrl('../../assets/svgs/collapse-all.svg'));
  }
}
