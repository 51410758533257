import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { AuthService } from '@coin/modules/auth/data-access';
import { CmsContentItemContent, CmsContentPageSettings, CmsItem } from '@coin/admin/cms/util';
import { NgClass } from '@angular/common';
import { EquityCmsChapterOverviewComponent } from '../../equity/equity-cms-chapter-overview/equity-cms-chapter-overview.component';
import { TextBlockComponent } from '../text-block/text-block.component';
import { ImageSliderComponent } from '../image-slider/image-slider.component';
import { QuoteComponent } from '../quote/quote.component';
import { SpacerComponent } from '../spacer/spacer.component';
import { PictureContainerComponent } from '../picture-container/picture-container.component';
import { CompilationComponent } from '../compilation/compilation.component';
import { NumbersComponent } from '../numbers/numbers.component';
import { TextImageLinkComponent } from '../text-image-link/text-image-link.component';
import { LineComponent } from '../line/line.component';
import { HtmlEditorComponent } from '../../shared/html-editor/html-editor.component';
import { NewsHeadlineComponent } from '../news-headline/news-headline.component';
import { EquityExpandBoxComponent } from '../../equity/equity-expand-box/equity-expand-box.component';
import { EquityExpandLinksboxComponent } from '../../equity/equity-expand-linksbox/equity-expand-linksbox.component';
import { CmsButtonComponent } from '../cms-button/cms-button.component';
import { DownloadFileComponent } from '../../emails/download-file/download-file.component';
import { EmailButtonComponent } from '../../emails/email-button/email-button.component';
import { EmailSingleDoubleImageComponent } from '../../emails/email-single-double-image/email-single-double-image.component';
import { EquityCmsChapterComponent } from '../../equity/equity-cms-chapter/equity-cms-chapter.component';
import { EquityCmsCardsComponent } from '../../equity/equity-cms-cards/equity-cms-cards.component';
import { EquityCmsFullWidthpartWithImageComponent } from '../../equity/equity-cms-full-widthpart-with-image/equity-cms-full-widthpart-with-image.component';
import { EmailMainBodyImageTextComponent } from '../../emails/email-main-body-image-text/email-main-body-image-text.component';
import { EmailIndexListComponent } from '../../emails/email-index-list/email-index-list.component';

@Component({
  selector: 'coin-all-cms-news-items',
  templateUrl: './all-cms-news-items.component.html',
  styleUrls: ['./all-cms-news-items.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    EquityCmsChapterOverviewComponent,
    TextBlockComponent,
    ImageSliderComponent,
    QuoteComponent,
    SpacerComponent,
    PictureContainerComponent,
    CompilationComponent,
    NumbersComponent,
    TextImageLinkComponent,
    LineComponent,
    HtmlEditorComponent,
    NewsHeadlineComponent,
    EquityExpandBoxComponent,
    EquityExpandLinksboxComponent,
    CmsButtonComponent,
    DownloadFileComponent,
    EmailButtonComponent,
    EmailSingleDoubleImageComponent,
    EquityCmsChapterComponent,
    EquityCmsCardsComponent,
    EquityCmsFullWidthpartWithImageComponent,
    EmailMainBodyImageTextComponent,
    EmailIndexListComponent
  ]
})
export class AllCmsNewsItemsComponent implements OnInit, OnChanges {
  @Input() item: CmsContentItemContent;
  @Input() isEquityPage = false;
  @Input() heightModified = false;
  @Input() modifiedWidth = false;
  @Input() overviewUpdate = true;
  @Input() germanyRowType: string;
  @Input() settings: CmsContentPageSettings;
  @Input() hasMargin = true;
  @Input() highlightItem: CmsItem;
  @Input() hideChapters = false;
  @Input() authService: AuthService;
  @Input() isResponsive: boolean;
  @Input() germanyRowSlideToggle = false;

  @Output() slideChange = new EventEmitter<MatSlideToggleChange>();
  @Output() chapterOverviewClicked = new EventEmitter<CmsItem>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onItemClicked = new EventEmitter<CmsItem>();

  public isAuthenticated = false;
  public highlightId: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.highlightItem) {
      if (changes.highlightItem.currentValue) {
        this.highlightId = changes.highlightItem.currentValue.id;
        document.getElementById(this.highlightId)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      if (!changes.highlightItem.currentValue) {
        this.highlightId = undefined;
      }
    }
  }

  public itemClicked(item: CmsItem, event: MouseEvent): void {
    if (this.onItemClicked.observed) {
      event.preventDefault();
      this.onItemClicked.next(item);
    }
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.checkAuthentication();
    }, 200);
  }

  private checkAuthentication(): void {
    if (this.authService) {
      this.isAuthenticated = this.authService.isAuthenticated$.value;
    }
  }
}
