import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, DestroyRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Employee } from '@coin/shared/util-models';
import { UserState } from '@coin/modules/auth/data-management';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DirectsOverviewComponent } from '../directs-overview.component';
import { OrgTreeService } from '../../../modules/org-tree/services/org-tree.service';

@Component({
  selector: 'coin-directs-overview-tab-view',
  templateUrl: './directs-overview-tab-view.component.html',
  styleUrls: ['./directs-overview-tab-view.component.scss'],
  standalone: false
})
export class DirectsOverviewTabViewComponent {
  employeeListValues: Employee[]; // Contains all the values
  displayedEmployeeList: Employee[]; // Contains the values after search, gets set by search
  @Input()
  set employeeList(value: Employee[]) {
    const mappedValue = value?.map(val => ({ ...val, countryCode: val.countryRegionHomeAddress, paOrgCode: val.organisation?.orgCode }));

    this.employeeListValues = mappedValue;
    this.displayedEmployeeList = mappedValue;
  }

  @Input() searchEmployee!: string;
  @Output() searchEmployeeChange = new EventEmitter<string>();

  @ViewChild('viewport') viewport: CdkVirtualScrollViewport;

  constructor(
    public dialogRef: MatDialogRef<DirectsOverviewComponent>,
    private router: Router,
    private store: Store,
    private orgReviewService: OrgTreeService,
    private destroyRef: DestroyRef
  ) {}

  searchInputChanged(): void {
    this.searchEmployeeChange.emit(this.searchEmployee);
    this.search();
  }

  search(): void {
    if (!this.searchEmployee.trim().length) {
      // Nothing to search here
      this.displayedEmployeeList = [...this.employeeListValues];
      return;
    }

    const searchKeywords = this.searchEmployee.split(' ');
    this.displayedEmployeeList = this.employeeListValues.filter(
      employee =>
        // Search by org code
        (typeof employee.organisation?.orgCode === 'string' && this.searchEmployee.trim().toLowerCase() === employee.organisation?.orgCode.toLowerCase()) ||
        // Or search for every keyword, if something corresponds to that employee
        searchKeywords
          .map(
            value =>
              // Search for GID
              (typeof employee.gid === 'string' && employee.gid.toLowerCase().includes(value.toLowerCase())) ||
              // Search for firstname
              (typeof employee.firstname === 'string' && employee.firstname.toLowerCase().includes(value.toLowerCase())) ||
              // Search for lastname
              (typeof employee.lastname === 'string' && employee.lastname.toLowerCase().includes(value.toLowerCase()))
          )
          .reduce((previousValue, currentValue) => previousValue && currentValue)
    );
  }

  goToDocumentCenter(directEmployee: Employee): void {
    this.orgReviewService
      .getEmployeeDetails(this.store.selectSnapshot(UserState?.user)?.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(employee => {
        this.router
          .navigate(['/employee-detail-overview'], {
            queryParams: {
              receiverId: directEmployee.id,
              managerId: employee?.id
            }
          })
          .then(() => {
            this.dialogRef.close();
          });
      });
  }

  onTabSelectedChange(event: MatTabChangeEvent): void {
    this.search();
  }
}
