import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TinyHelpers } from '@coin/shared/util-helpers';
import { CustomerSurvey, CustomerSurveyAnswer } from '@coin/modules/customer-survey/util';
import { Fn } from '@coin/shared/util-models';

@Component({
  selector: 'coin-customer-survey',
  templateUrl: './customer-survey.component.html',
  styleUrls: ['./customer-survey.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomerSurveyComponent),
      multi: true
    }
  ],
  standalone: false
})
export class CustomerSurveyComponent implements ControlValueAccessor {
  public expandedPanelIndex = -1;

  private _value: CustomerSurvey;

  @Input()
  set value(value: CustomerSurvey) {
    if (value == null) {
      return;
    }

    this._value = value;
    this.onChange(value);
    this.onTouch(value);
  }

  get value() {
    return this._value;
  }

  @Input() isEditable: boolean;
  @Input() isLocked: boolean;

  @Output() next = new EventEmitter<void>();

  onChange: Fn = () => {};
  onTouch: Fn = () => {};

  writeValue(value: CustomerSurvey): void {
    this._value = value;
  }
  registerOnChange(fn: Fn): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: Fn): void {
    this.onTouch = fn;
  }

  public startSurvey(): void {
    this.resetSurveyState();
    this.expandedPanelIndex = 0;
  }

  private resetSurveyState(): void {
    const survey: CustomerSurvey = TinyHelpers.nonThrowableStructuredCloneWorkaround(this.value); // clone-problem: CustomerSurvey is a class, should not cause a problem yet though
    survey.questions.forEach((question, index) => {
      question.isActive = index === 0;
      delete question.selectedAnswerId;
      question.answers.forEach(answer => {
        answer.isSelected = false;
      });
    });
    this.value = survey;
    this.expandedPanelIndex = -1;
  }

  public onTogglePanel(index: number): void {
    this.expandedPanelIndex = this.expandedPanelIndex === index ? -1 : index;
  }

  public expandNextPanel(index: number): void {
    // Wait a bit because out of no apparent reason the opened function from the underlying mat-expansion-panel is suddenly called twice which result in the survey being stuck at the first opened question. Waiting prevents that.
    setTimeout(() => {
      this.onTogglePanel(index + 1);
      this.next.emit();
    }, 100);
  }

  public onAnswerToggle(event: CustomerSurveyAnswer): void {
    if (event) {
      this.setQuestionsState(event);
    }
  }

  private setQuestionsState(answer: CustomerSurveyAnswer): void {
    // survey needs to be deep copied to assign this.value to rerender html
    const survey = structuredClone(this.value); // clone-problem: CustomerSurvey is a class, should not cause a problem yet though
    this.resetOpenQuestions(survey, answer);

    if (answer.linkedSurveyQuestionId) {
      this.setNextQuestionToActive(survey, answer);
    }

    this.value = survey;
  }

  resetOpenQuestions(survey: CustomerSurvey, answer: CustomerSurveyAnswer): void {
    const currentLevel = this.value.questions.find(question => question.answers.some(answerQuestion => answerQuestion.id === answer.id))?.treeLevel;

    survey.questions.forEach(question => {
      if (question.treeLevel > currentLevel) {
        question.isActive = false;
      }
    });
  }

  setNextQuestionToActive(survey: CustomerSurvey, answer: CustomerSurveyAnswer): void {
    const newQuestion = survey.questions.find(question => question.id === answer.linkedSurveyQuestionId);
    newQuestion.isActive = true;
    newQuestion.selectedAnswerId = undefined;
    newQuestion.answers = newQuestion.answers.map(a => ({ ...a, isSelected: false }));
  }
}
