import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingService } from '@coin/shared/data-access';
import { environment } from '@coin/shared/util-environments';
import { Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { DocumentCenterItem } from '../models/document-center-item.model';

@Injectable({
  providedIn: 'root'
})
export class GetDocumentsService {
  constructor(
    private http: HttpClient,
    private loadingService: LoadingService
  ) {}

  getMyFiles(receiverId: string): Observable<DocumentCenterItem[]> {
    return this.http.get<DocumentCenterItem[]>(`${environment.api.documentsUrl}/private-documents/list?receiverId=${receiverId}`).pipe(this.loadingService.withLoadingScreen);
  }

  getFileDownloadUrl(filename: string): Observable<string> {
    return this.http.post<string>(`${environment.api.documentsUrl}/private-documents`, { key: filename });
  }

  getFileForDirectDownload(filename: string, directGid: string): Observable<string> {
    return this.http.post<string>(`${environment.api.documentsUrl}/direct-documents`, { key: filename, userGid: directGid });
  }

  getFile(link: string): Observable<Blob> {
    this.loadingService.present();
    return this.http.get(link, { responseType: 'blob' }).pipe(finalize(() => this.loadingService.dismiss()));
  }

  getDirectFiles(gid: string, receiverId: string, managerId: string): Observable<DocumentCenterItem[]> {
    return this.http.get<DocumentCenterItem[]>(`${environment.api.documentsUrl}/direct-documents/list?gid=${gid}&receiverId=${receiverId}&managerId=${managerId}`).pipe(
      this.loadingService.withLoadingScreen,
      catchError(() => of(null))
    );
  }
}
