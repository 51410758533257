<div>
  @if (showAdditionalEditOptions) {
    <div class="spacing-elements">
      <coin-color-picker [(ngModel)]="item.backgroundColor"></coin-color-picker>
    </div>
    <coin-email-slider [(ngModel)]="item.paddingTop" label="Space-Top"></coin-email-slider>
    <coin-email-slider [(ngModel)]="item.paddingBottom" label="Space-Bottom"></coin-email-slider>
    <coin-email-slider [(ngModel)]="item.fontSize" label="Font size"></coin-email-slider>
  }
  @for (linkItem of item.content; track linkItem) {
    <div class="download-file-item">
      <img class="download-file-icon" aria-label="download icon" [src]="imagePath + 'coin/images/originals/download_icon_SE.png'" />
      @if (contentEdit) {
        <mat-form-field class="flex-one">
          <mat-label>Link Text</mat-label>
          <input matInput aria-label="link text" [(ngModel)]="linkItem.text" />
        </mat-form-field>
      }
      @if (contentEdit) {
        <mat-form-field class="flex-one">
          <mat-label>Link Href</mat-label>
          <input matInput aria-label="link link" [(ngModel)]="linkItem.link" type="test" placeholder="https://..." />
        </mat-form-field>
      }
      @if (!contentEdit) {
        <a [href]="linkItem.link" class="flex-one" target="_blank">{{ linkItem.text }}</a>
      }
    </div>
  }
</div>

@if (contentEdit) {
  <div class="download-file-edit">
    <mat-form-field appearance="fill">
      <mat-label>{{ 'cms.amount-links' | translate }}</mat-label>
      <mat-select (selectionChange)="amountChange($event.value)" [(ngModel)]="amountLinks">
        @for (option of amountOptions; track option) {
          <mat-option [value]="option">{{ option }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    @if (isContentPage) {
      <coin-v2-checkbox [(ngModel)]="item.requiresAuthentication" [label]="'cms.content-pages.requires-authentication' | translate" />
    }
  </div>
}
