import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@coin/shared/util-environments';
import { CmsItem, DefaultColorPickerValues } from '@coin/admin/cms/util';
import { LegacyComponentsModule } from '@coin/shared/feature-legacy-components';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { V2CheckboxComponent } from '@coin/shared/ui-storybook';
import { MatInput } from '@angular/material/input';
import { EmailSliderComponent } from '../../shared';

@Component({
  selector: 'coin-download-file',
  templateUrl: './download-file.component.html',
  styleUrls: ['./download-file.component.scss'],
  standalone: true,
  imports: [LegacyComponentsModule, MatFormFieldModule, FormsModule, MatSelectModule, TranslateModule, V2CheckboxComponent, EmailSliderComponent, MatInput]
})
export class DownloadFileComponent implements OnInit {
  @Input() item: CmsItem<'download-file'> = { content: [], paddingTop: 0, paddingBottom: 0, fontSize: 0, backgroundColor: '', type: 'download-file' };
  @Input() contentEdit = false;
  @Input() isContentPage = false;
  @Input() showAdditionalEditOptions = false;

  public amountLinks = 1;
  public imagePath = environment.coinImagePath;

  public amountOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] as const;

  ngOnInit(): void {
    this.item ??= {} as CmsItem<'download-file'>;
    this.amountLinks = this.item.content.length;
    this.setDefaultBackgroundColor();
  }

  private setDefaultBackgroundColor(): void {
    this.item.backgroundColor = this.item.backgroundColor || DefaultColorPickerValues.backgroundColor;
  }

  public amountChange(value: number): void {
    if (value > this.item.content.length) {
      for (let i = this.item.content.length; i < value; i++) {
        this.item.content.push({ text: 'Download Link Text', link: 'https://www.google.de' });
      }
    } else if (value < this.item.content.length) {
      this.item.content = this.item.content.slice(0, value);
    }
  }
}
