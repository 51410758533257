import { NgStyle } from '@angular/common';
import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MediaService } from '@coin/admin/cms/data-access';
import { CmsUsages } from '@coin/admin/cms/util';
import { LegacyComponentsModule } from '@coin/shared/feature-legacy-components';
import { V2DialogModule, ConfirmationDialogComponent } from '@coin/shared/ui-storybook';
import { environment } from '@coin/shared/util-environments';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { filter, finalize, Observable, of, switchMap, tap } from 'rxjs';
import { MatInput } from '@angular/material/input';
import { SpacerComponent } from '../../../components/news/spacer/spacer.component';
import { CmsImageUsagesComponent } from '../../../components/shared/cms-image-usages/cms-image-usages.component';

export interface DialogResult {
  mediaUrl: string;
}

interface DialogData {
  url?: string;
  mediaType: 'image' | 'video';
}

@Component({
  selector: 'coin-gmm-select-media',
  templateUrl: './select-media.component.html',
  styleUrls: ['./select-media.component.scss'],
  standalone: true,
  imports: [
    V2DialogModule,
    SpacerComponent,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatLabel,
    TranslateModule,
    FormsModule,
    NgStyle,
    LegacyComponentsModule,
    CmsImageUsagesComponent,
    MatInput
  ]
})
export class SelectMediaComponent implements OnInit {
  public isLoading = false;
  public isUsageLoading = false;
  public selectedMediaUrl: string;
  public cmsUsages: CmsUsages;
  public thumbnailUrls: string[] = [];
  public saveCalculatedCmsUsages: Record<string, CmsUsages> = {};
  public isUsageAlreadyCalculated: boolean;
  readonly mediaPath = this.data.mediaType === 'image' ? 'images' : 'videos';

  public get mediaName(): string {
    return this.data.mediaType;
  }

  public get isMediaUsed(): boolean {
    return this.cmsUsageCounter > 0;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<SelectMediaComponent, DialogResult>,
    private imageService: MediaService,
    private dialog: MatDialog,
    private toast: ToastrService,
    private translate: TranslateService,
    private destroyRef: DestroyRef
  ) {}

  public ngOnInit(): void {
    this.loadThumbNails();
    this.selectedMediaUrl = this.data?.url;
  }

  public static openDialog(dialog: MatDialog, data: DialogData): Observable<DialogResult> {
    return dialog
      .open<SelectMediaComponent, DialogData, DialogResult>(SelectMediaComponent, {
        disableClose: true,
        data
      })
      .beforeClosed();
  }

  /**
   * Select the original image for a selected thumbnail.
   * @param path The thumbnail image path
   */

  public selectMedia(path: string): void {
    this.selectedMediaUrl = path.replace('thumbnails', 'originals');
    if (this.data.mediaType === 'video') {
      this.selectedMediaUrl = this.selectedMediaUrl.replace('.png', '.mp4');
    }
    this.isUsageAlreadyCalculated = Object.keys(this.saveCalculatedCmsUsages).includes(this.selectedMediaUrl);
    this.cmsUsages = this.isUsageAlreadyCalculated ? this.saveCalculatedCmsUsages[this.selectedMediaUrl] : undefined;
  }

  /**
   * Confirm the current image selection.
   */
  public confirm(): void {
    this.dialogRef.close({ mediaUrl: this.selectedMediaUrl });
  }

  /**
   * Discard the current image selection.
   */
  public discard(): void {
    this.dialogRef.close();
  }

  public loadThumbNails(): void {
    this.isLoading = true;
    const thumbNails$ = this.data.mediaType === 'image' ? this.imageService.getImageThumbnails() : this.imageService.getVideoThumbnails();

    thumbNails$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(thumbnails => {
      this.thumbnailUrls = thumbnails.map(imagePath => environment.coinImagePath + imagePath.key);
      this.isLoading = false;
    });
  }
  /**
   * Upload a file to the cloud. Creates a thumbnail as well and updates the current selection.
   * @param event The file input event.
   */
  public async uploadMedia(event: Event): Promise<void> {
    this.isLoading = true;
    const file = (event.target as HTMLInputElement).files[0];
    // element should be the same as event.target
    (document.getElementById('mediaInput') as HTMLInputElement).value = null; // Reset input field to trigger event again on same image
    if (file) {
      const base64Media = await this.readFile(file);
      const uploadMedia$ = this.data.mediaType === 'image' ? this.imageService.uploadImage(base64Media) : this.imageService.uploadVideo(file);

      uploadMedia$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
        response => {
          // Get media
          this.selectedMediaUrl = `${environment.coinImagePath}coin/${this.mediaPath}/originals/${response.fileName}`;
          this.thumbnailUrls.push(`${environment.coinImagePath}coin/${this.mediaPath}/thumbnails/${response.fileName.replace('.mp4', '.png')}`);
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
    }
  }

  public deleteMedia(url: string): void {
    const deleteMediaUrl = this.selectedMediaUrl.split('/').at(-1);
    this.isUsageAlreadyCalculated = Object.keys(this.saveCalculatedCmsUsages).includes(deleteMediaUrl);

    this.getMediaCmsUsage(deleteMediaUrl)
      .pipe(
        switchMap(() => {
          return this.dialog
            .open(ConfirmationDialogComponent, {
              disableClose: true,
              data: {
                headline: this.translate.instant('general.delete-confirm'),
                noteText: this.isMediaUsed
                  ? `${this.translate.instant('cms.image-is-used-in')} ${this.cmsUsageCounter}${this.translate.instant('cms.cms-items')}\n${this.translate.instant(
                      'cms.for-details-go-back'
                    )}`
                  : null,
                translate: true,
                msg: this.translate.instant('cms.delete-image-confirm-question'),
                confirmMsg: this.translate.instant('general.btnDelete'),
                cancelMsg: this.translate.instant('general.btnCancel')
              }
            })
            .afterClosed();
        }),
        filter(result => !!result),
        switchMap(() => {
          const delete$ = this.data.mediaType === 'image' ? this.imageService.deleteImage(deleteMediaUrl) : this.imageService.deleteVideo(deleteMediaUrl);
          return delete$;
        })
      )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.toast.success(this.translate.instant('cms.image-delete-success'));
        this.selectedMediaUrl = '';
        this.loadThumbNails();
        this.isUsageLoading = false;
      });
  }

  public get cmsUsageCounter(): number {
    if (this.cmsUsages) {
      const contentpages = Object.values(this.cmsUsages?.contentPages)
        .map(array => array.length)
        .reduce((a, b) => a + b, 0);
      return this.cmsUsages?.emails?.length + this.cmsUsages?.news?.length + contentpages;
    }
    return 0;
  }

  public calculateUsageClickHandler(url: string): void {
    this.getMediaCmsUsage(url).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  public getMediaCmsUsage(url: string): Observable<CmsUsages> {
    this.isUsageAlreadyCalculated = Object.keys(this.saveCalculatedCmsUsages).includes(this.selectedMediaUrl);
    if (this.isUsageAlreadyCalculated) {
      this.isUsageLoading = false;
      return of(this.saveCalculatedCmsUsages[url]);
    }
    this.isUsageLoading = true;

    const getCmsUsage$ = this.data.mediaType === 'image' ? this.imageService.getImageCmsUsage(url) : this.imageService.getVideoCmsUsage(url);
    return getCmsUsage$.pipe(
      tap(response => {
        this.saveCalculatedCmsUsages[url] = response;
        this.cmsUsages = response;
        this.isUsageAlreadyCalculated = Object.keys(this.saveCalculatedCmsUsages).includes(url);
        if (response) {
          this.isUsageLoading = false;
        } else if (!response) {
          this.toast.error(this.translate.instant('general.please-try-again'));
          this.isUsageLoading = false;
        }
      }),
      finalize(() => {
        this.isUsageLoading = false;
      })
    );
  }
  /**
   * Read a given file to a base64 string.
   * @param file The file to read.
   */
  private readFile(file: File): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = event => resolve(btoa(event.target.result.toString()));
    });
  }
}
