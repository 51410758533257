<div class="container">
  <div>
    <div class="image" [class.edit]="contentEdit" [style.background-image]="'url(' + item?.content?.img + ')'">
      @if (contentEdit) {
        <coin-v2-button (click)="editPicture()" type="secondary" svgIcon="edit" [text]="'cms.emails.change-image-button' | translate"> </coin-v2-button>
      }
      @if (item?.content && contentEdit) {
        <coin-input [(ngModel)]="item.content.link" label="Image URL"></coin-input>
      }
    </div>
    @if (contentEdit) {
      <coin-v2-checkbox [(ngModel)]="item.inverted" [label]="'cms.emails.inverted-checkbox' | translate" class="inverse-checkbox"> </coin-v2-checkbox>
    }
  </div>

  <coin-html-editor [showAdditionalEditOptions]="showAdditionalEditOptions" [contentEdit]="contentEdit" [isContentText]="true" [item]="item"></coin-html-editor>
</div>
