@if (item) {
  <div class="container">
    <coin-color-picker [(ngModel)]="item.backgroundColor"></coin-color-picker>
    <coin-color-picker [(ngModel)]="item.textColor" [isTextColorPicker]="true"></coin-color-picker>
    <coin-email-slider [(ngModel)]="item.paddingTop" label="Space-Top"></coin-email-slider>
    <coin-email-slider [(ngModel)]="item.paddingBottom" label="Space-Bottom"></coin-email-slider>
    <coin-v2-checkbox [(ngModel)]="automaticSyncEnabled" (ngModelChange)="confirmAutoSync()" [label]="'cms.emails.auto-sync-index-list' | translate" />
    <div class="index-list" cdkDropList (cdkDropListDropped)="drop($event)">
      @for (index of item.content; track trackByIndexItemFn(i, index); let i = $index) {
        <div class="index" cdkDrag cdkDragBoundary=".index-list">
          <div class="reference-title">
            <mat-icon class="drag" [class.disabled]="item.automaticSyncEnabled" cdkDragHandle>drag_indicator</mat-icon>
            <mat-label> {{ index.referenceTitle }}</mat-label>
          </div>
          <div class="editor">
            <quill-editor class="customize-editor" [(ngModel)]="index.title"> </quill-editor>
          </div>
          <div class="delete">
            <mat-icon class="delete" [class.disabled]="item.automaticSyncEnabled" (click)="deleteItem(index)">delete</mat-icon>
          </div>
        </div>
      }
    </div>

    <div class="buttons">
      <coin-v2-button type="primary" [disabled]="item.automaticSyncEnabled" (click)="addItem()" [text]="'cms.emails.add-index-item' | translate"></coin-v2-button>
    </div>
  </div>
}
