import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { SearchItemService } from '@coin/shared/util-models';
import { IncentiveSupportComponentState } from './incentive-support.component.state';
import { Reassignment, ReassignmentService } from '../generated';

@Injectable()
export class ReassignmentsSearchService implements SearchItemService {
  constructor(
    private incentiveSupportComponentState: IncentiveSupportComponentState,
    private reassignmentService: ReassignmentService
  ) {}

  getSearchItemsByText(text: string, page: number): Observable<Reassignment[]> {
    const selectedSeason = this.incentiveSupportComponentState.getSelectedSeason();
    if (!selectedSeason) {
      throw new Error('Cannot load reassignments without selected season');
    }
    return this.reassignmentService
      .getSeasonsIncentiveSeasonIdGuidPartnerV1Reassignments({ seasonId: selectedSeason.id, querySearch: text, pagingPage: page, pagingSize: 50 })
      .pipe(
        map(result => result.content),
        // Return empty list in case of an error to prevent an error in the view
        catchError(() => of([]))
      );
  }
}
