import { Component, DestroyRef, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalEventsService } from '@coin/shared/util-helpers';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgStyle } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LegacyComponentsModule } from '@coin/shared/feature-legacy-components';
import { CMSContextMenuReplacement } from '../models/cms-context-menu-replacement';

@Component({
  selector: 'coin-contextmenu',
  templateUrl: './contextmenu.component.html',
  styleUrls: ['./contextmenu.component.scss'],
  standalone: true,
  imports: [NgStyle, FormsModule, LegacyComponentsModule]
})
export class ContextmenuComponent implements OnInit {
  @Input() x = 0;
  @Input() y = 0;
  @Input() contextMenuReplacements!: CMSContextMenuReplacement[];

  @Output() addItemToContent = new EventEmitter<string>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter<void>();

  public search: string;

  public get height(): number {
    return document.documentElement.clientHeight - this.y - 50;
  }

  public get filteredMenuReplacements(): CMSContextMenuReplacement[] {
    return this.contextMenuReplacements?.filter(item => !this.search?.trim() || item.value.toLowerCase().includes(this.search.toLowerCase()));
  }

  constructor(
    private elementRef: ElementRef,
    private events: GlobalEventsService,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.events
      .listen('click')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(event => this.clickout(event));
  }

  public clickout = (event: MouseEvent): void => {
    const clickedOutside = !this.elementRef.nativeElement.contains(event.target);

    if (clickedOutside) {
      this.close.emit();
    }
  };

  public addItem(item: CMSContextMenuReplacement): void {
    this.addItemToContent.emit(item.replacementText);
    this.close.emit();
  }
}
