import { Directive, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[reRenderOnChange]',
  standalone: true
})
export class ReRenderOnChangeDirective implements OnChanges {
  /** Destroy and then recreates the attached element when the supplied variable changes. */
  @Input() reRenderOnChange: unknown;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef
  ) {}

  public ngOnChanges(): void {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef);
  }
}
