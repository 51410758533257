import { Component, DestroyRef, ElementRef, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FirstVisitService } from '@coin/modules/auth/data-management';
import { StorageService } from '@coin/shared/data-access';
import { ConfirmationDialogComponent } from '@coin/shared/ui-storybook';
import { CommunicationTypeEnum, ContentLanguage, StorageKey } from '@coin/shared/util-enums';
import { environment } from '@coin/shared/util-environments';
import { Topic } from '@coin/shared/util-models';
import { shrinkExpandAnimation } from '../../../shared/animations/shrinkExpand.anim';
import { OpenFaqService } from '../../../shared/services/open-faq.service';

@Component({
  selector: 'app-begin-tour',
  templateUrl: './begin-tour.component.html',
  styleUrls: ['./begin-tour.component.scss'],
  animations: [shrinkExpandAnimation],
  standalone: false
})
export class BeginTourComponent implements OnInit {
  @ViewChild('calmain') calmain: ElementRef;

  public imageLink: string;
  public showFakeItem = false;
  public text: { headline: string; text: string };
  public specialImageData: string;
  public fakeItem: Partial<Topic>;
  private readonly fakeItems: Partial<Topic>[] = [
    {
      id: 'e0123ca5-2b77-4f6a-bd6b-c89c9dab958c',
      dateStart: '2020-09-30T00:00:00',
      dateEnd: '2020-10-02T00:00:00',
      type: CommunicationTypeEnum.News,
      moreInfo: '',
      moreDetails: 'More Details',
      done: false,
      tags: [],
      contents: [
        {
          language: ContentLanguage.ENGLISH,
          text: '{}',
          description: 'Go check it out.',
          title: 'COIN is live!'
        }
      ]
    },
    {
      id: 'e0123ca5-2b77-4f6a-bd6b-c89c9dab958c',
      dateStart: '2020-09-30T00:00:00',
      dateEnd: '2020-10-02T00:00:00',
      type: CommunicationTypeEnum.Announcement,
      moreInfo: '',
      moreDetails: 'More Details',
      done: false,
      tags: [],
      contents: [
        {
          language: ContentLanguage.ENGLISH,
          text: '{}',
          description: 'Go check it out.',
          title: 'COIN is live!'
        }
      ]
    },
    {
      id: 'e0123ca5-2b77-4f6a-bd6b-c89c9dab958c',
      dateStart: '2020-09-30T00:00:00',
      dateEnd: '2020-10-02T00:00:00',
      type: CommunicationTypeEnum.Task,
      moreInfo: '',
      moreDetails: 'More Details',
      done: false,
      tags: [],
      contents: [
        {
          language: ContentLanguage.ENGLISH,
          text: '{}',
          description: 'Go check it out.',
          title: 'COIN is live!'
        }
      ]
    }
  ];
  public tourMaxHeight = 'calc(100vh - 210px)';
  public nso: { bb: string; bt: string; bl: string; br: string };
  public selectedTab = 0;
  public needsColumn = false;
  /// For dragging the dialog around
  public offset = { x: 0, y: 0 };

  constructor(
    private elemRef: MatDialogRef<BeginTourComponent>,
    private firstVisit: FirstVisitService,
    private dialog: MatDialog,
    private openFaq: OpenFaqService,
    private router: Router,
    private storageService: StorageService,
    private destroyRef: DestroyRef
  ) {
    this.firstVisit.activate();
  }

  ngOnInit(): void {
    document.getElementsByClassName('backdrop-hide-remove')?.[0]?.classList.add('show');
    setTimeout(() => {
      this.router.navigate(['/']);
    }, 500);
  }

  public tabChange(i: number): void {
    switch (i) {
      case 0:
        setTimeout(() => {
          this.openUserMenu('close');
        }, 500);
        this.removeAll();
        this.elemRef.removePanelClass('half-width');
        document.getElementsByClassName('backdrop-hide-remove')[0].classList.add('show');
        this.elemRef.updatePosition({ top: '110px' });
        break;
      case 1:
        this.removeAll();
        // document.getElementsByClassName('profile-main')[0].classList.add('highlight-this-part');
        this.nso = { bt: '0px', bl: '30vw', br: '30vw', bb: 'calc(200vh - 75px)' };
        this.elemRef.updatePosition({ top: 'min(460px, 40vh)' });
        this.tourMaxHeight = 'calc(100vh - min(460px, 40vh) - 210px)';
        this.imageLink = `${environment.coinAssetsPath}/videos/user-menu.mov`;
        this.text = { headline: 'guided-tour.user-profile', text: 'guided-tour.user-profile-text' };
        setTimeout(() => {
          this.openUserMenu('open');
          this.nso = { bt: '0px', bl: '30vw', br: '30vw', bb: 'calc(200vh - 442px)' };
        }, 500);
        break;
      case 2:
        this.removeAll();
        setTimeout(() => {
          this.openUserMenu('close');
        }, 500);
        this.nso = { bt: '65px', bl: '0px', br: '55px', bb: `calc(200vh - ${document.getElementsByClassName('season-container')[0]['offsetHeight'] + 75}px)` };
        this.tourMaxHeight = `calc(100vh - ${document.getElementsByClassName('season-container')[0]['offsetHeight'] + 75}px - 210px)`;
        this.imageLink = `${environment.coinAssetsPath}/videos/timeline.mov`;
        this.elemRef.updatePosition({ bottom: '5vh' });
        this.text = { headline: 'guided-tour.timeline', text: 'guided-tour.timeline-text' };
        break;
      case 3:
        this.removeAll();
        document.getElementsByClassName('backdrop-hide-remove')[0].classList.add('show');
        (document.getElementsByClassName('backdrop-hide-remove')[0] as HTMLElement).click();
        this.imageLink = `${environment.coinAssetsPath}/videos/task.mov`;
        this.elemRef.updatePosition({ top: '40px' });
        this.showFakeItem = true;
        this.iterateFakeItems(0, true);
        this.text = { headline: 'guided-tour.news-announcment-tasks', text: 'guided-tour.news-announcment-tasks-text' };
        break;
      case 4:
        this.removeAll();
        this.needsColumn = true;
        this.addSingle('side-bar-main-item', 'highlight-this-part', false);
        this.elemRef.updatePosition({ top: '10vh', right: '400px' });
        this.imageLink = `${environment.coinAssetsPath}/videos/side-menu.mov`;
        this.addSingle('app-sidebar', 'sidebar-visible', true);
        this.text = { headline: 'guided-tour.sidenavigation', text: 'guided-tour.sidenavigation-text' };
        setTimeout(() => {
          try {
            (document.getElementsByTagName('app-collapsed-bar')[0].childNodes[0].childNodes[0] as HTMLElement).click();
            // eslint-disable-next-line no-empty
          } catch (error) {}
        }, 500);
        break;
      case 5:
        this.removeAll();
        // this.nso = { bt: '0px', bl: 'calc(100vw - 465px)', br: '65px', bb: '0px' };
        this.openFaq.faqManage(true);
        this.needsColumn = true;
        setTimeout(() => {
          document.getElementsByClassName('faq-sidebar')[0].classList.add('highlight-this-part');
        }, 200);
        this.addSingle('app-sidebar', 'sidebar-visible', false);
        this.elemRef.updatePosition({ top: '80px', right: '500px' });
        this.imageLink = `${environment.coinAssetsPath}/videos/faq.mov`;
        this.text = { headline: 'guided-tour.help-button', text: 'guided-tour.help-button-text' };
        break;
      case 6:
        this.removeAll();
        document.getElementsByClassName('backdrop-hide-remove')[0].classList.add('show');
        this.elemRef.updatePosition({ top: undefined, right: undefined });
        this.imageLink = undefined;
        this.text = { headline: 'guided-tour.thank-you', text: 'guided-tour.thank-you-text' };
        break;
    }
  }

  private removeAll(): void {
    this.openFaq.faqManage(false);
    this.nso = undefined;
    this.needsColumn = false;
    this.tourMaxHeight = 'calc(100vh - 210px)';
    this.offset = { x: 0, y: 0 };

    const elemsToClose = [
      { tag: 'app-apps', class: 'less-visible', isTag: true },
      { tag: 'side-bar-main-item', class: 'highlight-this-part', isTag: false },
      { tag: 'backdrop-hide-remove', class: 'show', isTag: false },
      { tag: 'backdrop-hide-remove', class: 'show', isTag: false },
      { tag: 'faq-sidebar', class: 'highlight-this-part', isTag: false }
    ];

    for (const itm of elemsToClose) {
      this.removeSingle(itm.tag, itm.class, itm.isTag);
    }
    if (document.getElementsByTagName('app-sidebar')[0].classList.contains('sidebar-visible')) {
      document.getElementById('menu-bar-close-open').click();
    }
    this.showFakeItem = false;
  }

  private removeSingle(tag: string, className: string, isTag: boolean): void {
    const elem = isTag ? document.getElementsByTagName(tag) : document.getElementsByClassName(tag);
    if (elem[0] && elem[0].classList) {
      elem[0].classList.remove(className);
    }
  }

  private addSingle(tag: string, className: string, isTag: boolean): void {
    const elem = isTag ? document.getElementsByTagName(tag) : document.getElementsByClassName(tag);
    if (elem[0] && elem[0].classList) {
      elem[0].classList.add(className);
    }
  }

  private openUserMenu(type: 'open' | 'close'): void {
    const state = document.getElementsByClassName('profile-info')[0].classList.contains('show-user-menu');
    if (type === 'close') {
      if (state) {
        (document.getElementsByClassName('profile-info')[0] as HTMLElement).click();
      }
    } else if (!state) {
      (document.getElementsByClassName('profile-info')[0] as HTMLElement).click();
    }
  }

  public next(): void {
    this.selectedTab += 1;
  }

  public back(): void {
    this.selectedTab -= 1;
  }

  private iterateFakeItems(n: number, override = false): void {
    let number = n;

    if (this.showFakeItem || override) {
      this.fakeItem = undefined;
      setTimeout(() => {
        this.fakeItem = this.fakeItems[number % 3];
        number += 1;
        setTimeout(() => {
          this.iterateFakeItems(number);
        }, 3000);
      }, 10);
    }
  }

  public closeFirstVisit(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {
        headline: 'general.close-confirm',
        msg: 'general.cancel-guided-tour',
        cancelMsg: 'general.proceed-next-time',
        confirmMsg: 'general.confirm',
        translate: true
      }
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(result => {
        this.closeThis(result);
      });
  }

  private async closeThis(nevershow: boolean): Promise<void> {
    if (nevershow) {
      await this.storageService.setAsync(StorageKey.FIRST_VISIT_COMPLETED, 'true');
    }
    this.removeAll();
    this.firstVisit.deactivate();
    this.firstVisit.closeGuidedTour();
    this.elemRef.close();
  }

  public completeTour(): void {
    this.closeThis(true);
  }
}
