<div class="container">
  <div class="left" [style.background-color]="backgroundColor()">
    <mat-icon [svgIcon]="svgIcon()">{{ icon() }}</mat-icon>
  </div>
  <div class="right">
    <p class="bold" [class.truncate]="shouldTruncateText()" coinEllipsisTooltip matTooltip>
      {{ title() }}
    </p>
    <p class="sub-title" [class.truncate]="shouldTruncateText()" coinEllipsisTooltip matTooltip>
      {{ subTitle() }}
    </p>
  </div>
</div>
