import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CurrencyIsoCode } from '@coin/shared/util-models';
import { DisplayedCurrency } from '@coin/shared/util-enums';
import { RoundOperations } from '@coin/shared/util-helpers';
import { CurrencyConversionService } from '@coin/shared/data-access';
import { SpecialPaymentState } from '../../../special-payment/state/special-payment.state';
import { MercerState } from '../../../mercer/state/mercer.state';
import { MercerSupportState } from '../../../mercer-support/state/mercer-support.state';
import { EmployeeProfileCurrencyState } from '../../../employee-detail-overview/store/employee-profile-currency.state';
import { MeritCurrencyState } from '../store/merit-currency.state';
import { CurrencyHelper } from '../helpers/currency.helper';

@Pipe({
  name: 'currencyConversion',
  standalone: false
})
export class CurrencyConversionPipe implements PipeTransform {
  constructor(
    private currencyConversionService: CurrencyConversionService,
    private store: Store,
    private router: Router,
    private currencyHelper: CurrencyHelper
  ) {}

  transform(
    value: number,
    originCurrency: CurrencyIsoCode,
    isEquity = false,
    targetCurrency?: CurrencyIsoCode | DisplayedCurrency.Local,
    forceConversion?: boolean,
    freezeDate?: string,
    disableConversion?: boolean
  ): Observable<number> {
    if (!value) {
      return of(0);
    }

    if (disableConversion) {
      return of(value);
    }

    const targetCurrency$ = targetCurrency ? of(targetCurrency) : this.getCurrency();

    if (isEquity) {
      return targetCurrency$.pipe(
        map(_targetCurrency => [_targetCurrency, this.currencyHelper.getFreezeDate(freezeDate)] as const),
        switchMap(([_targetCurrency, _freezeDate]) => {
          if (!_freezeDate) {
            return of(undefined);
          }
          if (_targetCurrency === DisplayedCurrency.Local) {
            return this.currencyConversionService
              .getFxRate(
                {
                  from: 'EUR',
                  to: originCurrency,
                  date: _freezeDate
                },
                forceConversion
              )
              .pipe(map(fxRate => RoundOperations.round(value * fxRate, 2)));
          }
          return of(RoundOperations.round(value, 2));
        })
      );
    }
    return targetCurrency$.pipe(
      map(_targetCurrency => [_targetCurrency, this.currencyHelper.getFreezeDate(freezeDate)] as const),
      switchMap(([_targetCurrency, _freezeDate]) => {
        if (!_freezeDate) {
          return of(undefined);
        }
        if (_targetCurrency === DisplayedCurrency.Local) {
          return of(RoundOperations.round(value, 2));
        }
        return this.currencyConversionService
          .getFxRate(
            {
              from: originCurrency,
              to: _targetCurrency,
              date: _freezeDate
            },
            forceConversion
          )
          .pipe(map(fxRate => RoundOperations.round(value * fxRate, 2)));
      })
    );
  }

  private getCurrency(): Observable<DisplayedCurrency> {
    if (this.router.url.includes('merit-support') || this.router.url.includes('budget-allocation')) {
      return this.store.select(MeritCurrencyState.selectedCurrency);
    }
    if (this.router.url.includes('mercer-support')) {
      return this.store.select(MercerSupportState.selectedCurrency);
    }
    if (this.router.url.includes('mercer')) {
      return this.store.select(MercerState.selectedCurrency);
    }
    if (this.router.url.includes('special-payment')) {
      return this.store.select(SpecialPaymentState.selectedCurrency);
    }
    if (this.router.url.includes('employee-detail-overview')) {
      return this.store.select(EmployeeProfileCurrencyState.selectedCurrency);
    }
    return of(DisplayedCurrency.EUR);
  }
}
