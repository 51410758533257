@if (showBackgroundPicker && item && contentEdit) {
  <div class="background-color-picker">
    <coin-color-picker [(ngModel)]="item.backgroundColor"></coin-color-picker>
  </div>
}
<rendered-gmm-spacer [item]="item" [contentEdit]="contentEdit"></rendered-gmm-spacer>

@if (contentEdit) {
  <mat-slider class="cmsslider" [max]="100" [min]="5" discrete style="width: 200px">
    <input [(ngModel)]="item.height" matSliderThumb />
  </mat-slider>
}
@if (contentEdit) {
  <p style="display: inline-flex">{{ item.height }}</p>
}
