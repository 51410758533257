@if (contentEdit) {
  <p style="margin-bottom: 20px">Just a line.</p>
}
<rendered-gmm-line [item]="item"></rendered-gmm-line>

@if (contentEdit && item) {
  <coin-v2-checkbox class="spacing-elements" [(ngModel)]="item.fullWidth" [label]="'Full Width'" />
  @if (showBackgroundPicker) {
    <div class="spacing-elements">
      <coin-color-picker [(ngModel)]="item.backgroundColor"></coin-color-picker>
    </div>
  }
}
