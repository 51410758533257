import { NgStyle } from '@angular/common';
import { Component, DestroyRef, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { CmsItem } from '@coin/admin/cms/util';
import { V2ButtonComponent } from '@coin/shared/ui-storybook';
import { TranslateModule } from '@ngx-translate/core';
import { HtmlEditorComponent } from '../../shared';
import { SelectMediaComponent } from '../../../dialogs/shared/select-media/select-media.component';

@Component({
  selector: 'coin-gmm-text-image-link',
  templateUrl: './text-image-link.component.html',
  styleUrls: ['./text-image-link.component.scss'],
  standalone: true,
  imports: [NgStyle, HtmlEditorComponent, V2ButtonComponent, MatFormFieldModule, MatLabel, TranslateModule, FormsModule, MatInput]
})
export class TextImageLinkComponent {
  @Input() item: CmsItem<'text-image-link'>;
  @Input() contentEdit = false;

  constructor(
    private dialog: MatDialog,
    private destroyRef: DestroyRef
  ) {}

  public editPicture(item: TextImageLinkComponent['item']): void {
    SelectMediaComponent.openDialog(this.dialog, { url: item?.img, mediaType: 'image' })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(newImage => {
        if (newImage) {
          item.img = newImage.mediaUrl;
        }
      });
  }

  public gotoPage(link: string): void {
    window.open(link, '_blank');
  }
}
