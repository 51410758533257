import { ChangeDetectionStrategy, Component, computed, HostBinding, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'coin-banner',
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIcon]
})
export class BannerComponent {
  public icon = input<string>();
  public text = input.required<string>();

  public type = input.required<BannerType>();

  protected defaultIcon = computed(() => {
    switch (this.type()) {
      case 'info':
        return 'info';
      case 'warning':
        return 'warning';
      case 'error':
        return 'error';
    }
  });

  @HostBinding('class')
  private get classes(): string {
    return `type-${this.type()}`;
  }
}

export const BANNER_TYPES = ['info', 'warning', 'error'] as const;
export type BannerType = (typeof BANNER_TYPES)[number];
