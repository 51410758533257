import { Component, Input } from '@angular/core';
import { CmsItem } from '@coin/admin/cms/util';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgStyle } from '@angular/common';
import { V2NumberInputComponent } from '@coin/shared/ui-storybook';
import { MatInput } from '@angular/material/input';
import { HtmlEditorComponent } from '../../shared';

@Component({
  selector: 'coin-gmm-text-block',
  templateUrl: './text-block.component.html',
  styleUrls: ['./text-block.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, TranslateModule, FormsModule, NgStyle, V2NumberInputComponent, HtmlEditorComponent, MatInput]
})
export class TextBlockComponent {
  @Input() item: CmsItem<'text-block'>;
  @Input() contentEdit = false;
}
