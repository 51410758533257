import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomerSurveyAnswer } from '@coin/modules/customer-survey/util';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'coin-customer-survey-answer',
  templateUrl: './customer-survey-answer.component.html',
  styleUrls: ['./customer-survey-answer.component.scss'],
  standalone: false
})
export class CustomerSurveyAnswerComponent {
  @Input() answer: CustomerSurveyAnswer;
  @Input() customIsSelected?: boolean;
  @Input() isEditable = true;

  @Output() toggleAnswer = new EventEmitter<boolean>();

  public get isSelected(): boolean {
    return this.customIsSelected !== undefined ? this.customIsSelected : this.answer?.isSelected;
  }

  public toggleSelect(radioBtn: NgModel): void {
    this.toggleAnswer.emit(!this.isSelected);

    setTimeout(() => {
      radioBtn.control.setValue(this.isSelected, { emitViewToModelChange: false });
    });
  }
}
