<div class="container" [class.selected]="isSelected">
  <div class="select">
    <coin-v2-radio-button [name]="answer.title" #radioBtn="ngModel" [ngModel]="isSelected" [disabled]="!isEditable" (ngModelChange)="toggleSelect(radioBtn)" />
  </div>
  <div class="info">
    <div class="text">
      <h4>{{ answer?.title | translate }}</h4>
      @if (answer?.description) {
        <p>{{ answer?.description | translate }}</p>
      }
    </div>
  </div>
</div>
