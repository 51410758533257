import { Component, Input, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'coin-sub-component-header',
  templateUrl: './sub-component-header.component.html',
  styleUrls: ['./sub-component-header.component.scss'],
  imports: [CommonModule]
})
export class SubComponentHeaderComponent {
  @Input() title: string;
  @Input() titleContainer: TemplateRef<unknown>;
  @Input() actionContainerLeft: TemplateRef<unknown>;
  @Input() actionContainerMiddle: TemplateRef<unknown>;
  @Input() actionContainerRight: TemplateRef<unknown>;
}
