import { LetterSelectionResult } from '@coin/modules/incentive/util';
import { ConfirmationDialogConfirm } from '@coin/shared/util-models';
import { AutoFinalizeLettersPayload } from './auto-finalize-letters-payload.model';
import { SelectedTemplatePayload } from './selected-template-payload.model';

export const MASS_OPERATION_TYPES = ['create', 'publish', 'unpublish', 'forward', 'auto-finalize', 'set-no-taf', 'reset-taf-required', 'set-irrelevant', 'set-active'] as const;
export type MassOperationType = (typeof MASS_OPERATION_TYPES)[number];

export type MassOperationPayload<T extends MassOperationType> = T extends 'publish'
  ? LetterSelectionResult
  : T extends 'auto-finalize'
    ? AutoFinalizeLettersPayload
    : T extends 'create'
      ? SelectedTemplatePayload
      : ConfirmationDialogConfirm;
