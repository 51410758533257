/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, DestroyRef, Inject, Injector } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TextDialogData } from '@coin/shared/util-models';
import { TranslateModule } from '@ngx-translate/core';
import { filter, Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { V2DialogModule } from '../dialog-frame/v2-dialog.module';
import { V2ButtonComponent } from '../button/v2-button.component';

@Component({
  selector: 'coin-text-dialog',
  templateUrl: './text-dialog.component.html',
  styleUrls: ['./text-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, V2DialogModule, TranslateModule, V2ButtonComponent]
})
export class TextDialogComponent {
  protected formGroup = this.createFormGroup();

  protected get isReadOnly(): boolean {
    return !this.data?.sections?.find(section => section.isEditable);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TextDialogData,
    private dialogRef: MatDialogRef<TextDialogComponent, any>,
    private dialog: MatDialog,
    private destroyRef: DestroyRef,
    private formBuilder: FormBuilder
  ) {}

  public static openDialog(dialog: MatDialog, data: TextDialogData): Observable<any> {
    return dialog
      .open<TextDialogComponent, TextDialogData, any>(TextDialogComponent, {
        maxHeight: 'unset',
        data
      })
      .beforeClosed();
  }

  public close(): void {
    if (!this.formGroup || this.formGroup.pristine) {
      this.dialogRef.close();
      return;
    }

    ConfirmationDialogComponent.openDialog(this.dialog, {
      translate: true,
      msg: 'general.unsaved-changes',
      headline: 'general.unsaved-changes-title',
      confirmMsg: 'general.btnConfirm',
      cancelMsg: 'general.btnCancel'
    })
      .pipe(filter(Boolean), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dialogRef.close();
      });
  }

  public save(): void {
    const formValue = this.formGroup.getRawValue();
    this.dialogRef.close(formValue);
  }

  private createFormGroup(): FormGroup {
    if (this.isReadOnly) {
      return undefined;
    }

    const controls = {};

    this.data.sections
      .filter(section => section.isEditable)
      .forEach(section => {
        if (!section.id) {
          throw Error('Editable section must have unique id');
        }

        controls[section.id] = [section.text];
      });

    return this.formBuilder.group(controls);
  }
}
