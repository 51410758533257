<coin-v2-dialog-frame [header]="data?.title || 'general.select' | translate">
  <div class="content">
    <div class="inner-content">
      <p class="bold">{{ data.subTitle | translate }}</p>
      <mat-button-toggle-group class="button-group" (change)="onChange($event)">
        @for (option of data.options; track option) {
          <mat-button-toggle class="text" [value]="option.value">{{ option.title | translate }}</mat-button-toggle>
        }
      </mat-button-toggle-group>
    </div>
  </div>
  <coin-v2-dialog-footer color="white" padding="large">
    <coin-v2-button type="secondary" (click)="cancel()" [text]="'general.btnCancel' | translate" icon="close" iconPos="right"></coin-v2-button>
    <coin-v2-button
      [disabled]="!selectedOption"
      type="primary"
      (click)="confirm()"
      [text]="'general.btnConfirm' | translate"
      icon="chevron_right"
      iconPos="right"></coin-v2-button>
  </coin-v2-dialog-footer>
</coin-v2-dialog-frame>