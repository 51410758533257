import { Injectable } from '@angular/core';
import { IncentivePartnerMonitoringFilterOptions } from '@coin/shared/util-enums';
import { IncentivePartnerService, IncentivePartnerServiceQueryFieldValuesMonitoringRequestParams } from '@coin/shared/data-access';
import { TinyHelpers } from '@coin/shared/util-helpers';
import { ToastrService } from 'ngx-toastr';
import { IncentiveSupportComponentState } from './incentive-support.component.state';

@Injectable()
export class MonitoringFieldValuesService {
  constructor(
    private incentiveSupportComponentState: IncentiveSupportComponentState,
    private monitoringService: IncentivePartnerService,
    private toast: ToastrService
  ) {}

  public getFieldValues(page: number, size: number, targetProperty: IncentivePartnerMonitoringFilterOptions, searchText?: string) {
    const selectedSeason = this.incentiveSupportComponentState.getSelectedSeason();
    if (!selectedSeason) {
      throw new Error('Cannot get field values without selected season');
    }

    const args: IncentivePartnerServiceQueryFieldValuesMonitoringRequestParams = {
      seasonId: selectedSeason.id,
      page: page + 1,
      size: size,
      targetProperty: this.mappedTargetPropertyToIndex(targetProperty)
    };
    const camelCaseTargetProperty = TinyHelpers.pascalcaseToCamelCase(targetProperty);
    args[`${camelCaseTargetProperty}`] = searchText;

    return this.monitoringService.queryFieldValuesMonitoring(args);
  }

  private mappedTargetPropertyToIndex(targetProperty: IncentivePartnerMonitoringFilterOptions) {
    // TODO: Transform to enum in swagger for easier mapping?
    switch (targetProperty) {
      case IncentivePartnerMonitoringFilterOptions.Are:
        return 4;
      case IncentivePartnerMonitoringFilterOptions.Country:
        return 5;
      case IncentivePartnerMonitoringFilterOptions.PlanName:
        return 7;
      case IncentivePartnerMonitoringFilterOptions.Template:
        return 8;
      default:
        this.toast.error(`Target Property Mapping not implemented for ${targetProperty}`);
        throw new Error();
    }
  }
}
