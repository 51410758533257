import { FilterSetting } from '@coin/shared/util-models';
import { IncentivePartnerMonitoringFilterOptions } from '@coin/shared/util-enums';
import { TinyHelpers } from '@coin/shared/util-helpers';
import { CommunicationsPartnerServiceCreateLetterBatchLettersRequestParams } from '@coin/shared/data-access';

type MassOperationBatchParams = Omit<CommunicationsPartnerServiceCreateLetterBatchLettersRequestParams, 'siemensCOINClientApiCommunicationsCommandsLettersCreateLetterBatchModel'>;

export function convertFiltersToBatchParams(filters: FilterSetting[]) {
  const filtersByKey = filters.reduce<Record<string, string[]>>((accumulation, filter) => {
    accumulation[filter.key] = filter.values;
    return accumulation;
  }, {});

  return TinyHelpers.getEnumKeys(IncentivePartnerMonitoringFilterOptions).reduce<MassOperationBatchParams>((batchParams, filterKey: IncentivePartnerMonitoringFilterOptions) => {
    switch (filterKey) {
      case IncentivePartnerMonitoringFilterOptions.GidContains:
        batchParams.gid = filtersByKey[filterKey];
        break;
      case IncentivePartnerMonitoringFilterOptions.AssignmentIdContains:
        batchParams.assignmentId = filtersByKey[filterKey]?.[0];
        break;
      case IncentivePartnerMonitoringFilterOptions.FirstnameContains:
        batchParams.firstName = filtersByKey[filterKey];
        break;
      case IncentivePartnerMonitoringFilterOptions.LastnameContains:
        batchParams.lastName = filtersByKey[filterKey];
        break;
      case IncentivePartnerMonitoringFilterOptions.PlanName:
        batchParams.planName = filtersByKey[filterKey];
        break;
      case IncentivePartnerMonitoringFilterOptions.Are:
        batchParams.are = filtersByKey[filterKey];
        break;
      case IncentivePartnerMonitoringFilterOptions.OrgCodeContains:
        batchParams.orgCode = filtersByKey[filterKey];
        break;
      case IncentivePartnerMonitoringFilterOptions.Template:
        batchParams.template = filtersByKey[filterKey];
        break;
      case IncentivePartnerMonitoringFilterOptions.Workflow:
        batchParams.workflow = filtersByKey[filterKey] as never[];
        break;
      case IncentivePartnerMonitoringFilterOptions.RecordCommunicationStates:
        batchParams.recordCommunicationStates = filtersByKey[filterKey] as never[];
        break;
      case IncentivePartnerMonitoringFilterOptions.Country:
        batchParams.country = filtersByKey[filterKey];
        break;
      default:
        throw new Error(`Unknown filter key ${filterKey}`);
    }
    return batchParams;
  }, {});
}

export function addQueryPrefix(params: Record<string, unknown>): Record<string, unknown> {
  function titleCase(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return Object.entries(params).reduce((newParams, [key, value]) => {
    const newKey = `query${titleCase(key)}`;
    newParams[newKey] = value;
    return newParams;
  }, {});
}
