import { ChangeDetectionStrategy, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatRadioButton } from '@angular/material/radio';
import { DefaultControlValueAccessor } from '@coin/shared/util-helpers';

@Component({
  selector: 'coin-v2-radio-button',
  imports: [MatRadioButton, FormsModule],
  templateUrl: './v2-radio-button.component.html',
  styleUrl: './v2-radio-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => V2RadioButtonComponent),
      multi: true
    }
  ]
})
export class V2RadioButtonComponent extends DefaultControlValueAccessor<boolean> {
  @Input() color: ThemePalette = 'primary';
  @Input() label?: string;
  @Input() name?: string;

  @ViewChild(MatRadioButton) matButton: MatRadioButton;

  protected changed(val: boolean) {
    this.value = val;
  }

  public writeValue(value: boolean): void {
    if (value !== null && this.matButton) {
      this.matButton.checked = value;
    }
  }
}
