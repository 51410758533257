import { NgStyle } from '@angular/common';
import { Component, DestroyRef, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { CmsItem, shrinkExpandSlowAnimation } from '@coin/admin/cms/util';
import { IconButtonComponent, V2ButtonComponent } from '@coin/shared/ui-storybook';
import { TranslateModule } from '@ngx-translate/core';
import { v4 as uuid } from 'uuid';
import { SelectMediaComponent } from '../../../dialogs/shared/select-media/select-media.component';

@Component({
  selector: 'coin-gmm-compilation',
  templateUrl: './compilation.component.html',
  styleUrls: ['./compilation.component.scss'],
  animations: [shrinkExpandSlowAnimation],
  standalone: true,
  imports: [V2ButtonComponent, TranslateModule, NgStyle, IconButtonComponent]
})
export class CompilationComponent {
  @Input() startanimation = false;
  @Input() item: CmsItem<'compilation'>;
  @Input() contentEdit = false;

  constructor(
    private dialog: MatDialog,
    private destroyRef: DestroyRef
  ) {}

  // TO DO: collection is an object and not an array, this code should not work
  public remove(pos: string[], i): void {
    const data = this.item.collection as unknown as unknown[];
    this.iterateObj(pos, data);
    data.splice(i, 1);
  }

  public add(pos: string[]): void {
    const data = this.item.collection as unknown as unknown[];
    this.iterateObj(pos, data);
    data.push({
      img: 'https://media.istockphoto.com/photos/tax-form-with-notepad-pen-pencil-and-calculator-picture-id872852194',
      text: `content.compilation.text-${uuid().split('-')[0]}`
    });
  }

  private iterateObj<T>(pos: string[], array: T[]): T[] {
    let arr = array;
    for (const itm of pos) {
      arr = arr[itm];
    }
    return arr;
  }

  public editPicture(item: CmsItem): void {
    SelectMediaComponent.openDialog(this.dialog, { url: item?.img, mediaType: 'image' })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(newImage => {
        if (newImage) {
          item.img = newImage.mediaUrl;
        }
      });
  }
}
