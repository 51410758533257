import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { IncentivePartnerService } from '@coin/shared/data-access';
import { IncentiveRecordsMonitoringRecordModel, SearchItemService } from '@coin/shared/util-models';
import { IncentiveSupportComponentState } from './incentive-support.component.state';

@Injectable()
export class MonitoringSearchService implements SearchItemService {
  constructor(
    private incentiveSupportComponentState: IncentiveSupportComponentState,
    private incentiveMonitoringService: IncentivePartnerService
  ) {}

  getSearchItemsByText(text: string, page: number): Observable<IncentiveRecordsMonitoringRecordModel[]> {
    const selectedSeason = this.incentiveSupportComponentState.getSelectedSeason();
    if (!selectedSeason) {
      throw new Error('Cannot load records without selected season');
    }
    return this.incentiveMonitoringService
      .queryMonitoring({
        seasonId: selectedSeason.id,
        pagingPage: page + 1,
        pagingSize: 50,
        queryGid: [text]
      })
      .pipe(
        map(result => result.content),
        // Return empty list in case of an error to prevent an error in the view
        catchError(() => of([]))
      );
  }
}
