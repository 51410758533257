import { NgClass } from '@angular/common';
import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CmsItem, DefaultColorPickerValues } from '@coin/admin/cms/util';
import { LegacyComponentsModule } from '@coin/shared/feature-legacy-components';
import { V2ButtonComponent, V2NumberInputComponent } from '@coin/shared/ui-storybook';
import { environment } from '@coin/shared/util-environments';
import { TranslateModule } from '@ngx-translate/core';
import { EmailSliderComponent } from '../../shared/email-slider/email-slider.component';
import { SelectMediaComponent } from '../../../dialogs/shared/select-media/select-media.component';

@Component({
  selector: 'coin-email-single-double-image',
  templateUrl: './email-single-double-image.component.html',
  styleUrls: ['./email-single-double-image.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    LegacyComponentsModule,
    MatFormFieldModule,
    MatLabel,
    MatSelectModule,
    V2NumberInputComponent,
    TranslateModule,
    FormsModule,
    EmailSliderComponent,
    V2ButtonComponent
  ]
})
export class EmailSingleDoubleImageComponent implements OnInit {
  @Input() item: CmsItem<'single-or-multi-image'>;
  @Input() isResponsive: boolean;
  @Input() contentEdit = false;
  @Input() editImageLink: false;
  @Input() showBackgroundPicker = false;

  public amountOptions = [1, 2, 3];
  public amountImages = 1;

  constructor(
    private dialog: MatDialog,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.item ??= {} as never;
    this.amountImages = this.item?.content?.length;
    this.setDefaultBackgroundColor();
  }

  private setDefaultBackgroundColor(): void {
    this.item.backgroundColor = this.item.backgroundColor || DefaultColorPickerValues.backgroundColor;
  }

  public amountChange(value: number): void {
    if (value > this.item.content.length) {
      for (let i = this.item.content.length; i < value; i++) {
        this.item.content.push({ link: `${environment.coinImagePath}coin/images/originals/coin_email_base_image.png` } as never);
      }
    } else if (value < this.item.content.length) {
      this.item.content = this.item.content.slice(0, value);
    }
  }

  public editPicture(item: CmsItem<'single-or-multi-image'>['content'][number]): void {
    SelectMediaComponent.openDialog(this.dialog, { mediaType: 'image' })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(newImage => {
        if (newImage) {
          item.link = newImage.mediaUrl;
        }
      });
  }
}
