import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

/** Sets the aria-label attribute for the containing input, select or button element
 * Directive can be used for V2DropdownComponent, V2TextInputComponent or V2DateInputComponent
 * @example
 * `
 * <p #dateInputLabel>Label for date input</p>
 * <coin-v2-date-input [coinLabelledBy]="dateInputLabel" [(ngModel)]=...></coin-v2-date-input>
 * `
 * */
@Directive({
  selector:
    'coin-v2-date-input[coinLabelledBy], coin-v2-text-input[coinLabelledBy], coin-v2-dropdown[coinLabelledBy], coin-action-button[coinLabelledBy], coin-v2-number-input[coinLabelledBy], coin-v2-checkbox[coinLabelledBy], coin-icon-button[coinLabelledBy]',
  standalone: true
})
export class LabelledByDirective implements AfterViewInit {
  @Input({ required: true }) coinLabelledBy: HTMLElement | string;

  constructor(private elementRef: ElementRef<HTMLElement>) {}

  public ngAfterViewInit(): void {
    const label = this.coinLabelledBy instanceof HTMLElement ? (this.coinLabelledBy as HTMLElement)?.innerText : this.coinLabelledBy;
    if (label) {
      switch (this.elementRef.nativeElement.tagName.toLowerCase()) {
        case 'coin-v2-dropdown':
          this.elementRef.nativeElement.querySelector('mat-select')?.setAttribute('aria-labelledby', undefined);
          this.elementRef.nativeElement.querySelector('mat-select')?.setAttribute('aria-label', label);
          break;
        case 'coin-v2-text-input':
        case 'coin-v2-date-input':
        case 'coin-v2-number-input':
        case 'coin-v2-checkbox':
          this.elementRef.nativeElement.querySelector('input')?.setAttribute('aria-label', label);
          break;
        case 'coin-action-button':
        case 'coin-icon-button':
          this.elementRef.nativeElement.querySelector('button')?.setAttribute('aria-label', label);
          break;
        default:
          throw new Error('LabelledBy directive used on unsupported element');
      }
    }
  }
}
