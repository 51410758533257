@if (item?.headline && !contentEdit) {
  <h1 style="text-transform: uppercase">{{ item?.headline }}</h1>
}
@if (contentEdit) {
  <h1
    style="text-transform: uppercase"
    [class.placeholder]="!item?.headline"
    [contentEditable]="contentEdit"
    [textContent]="item?.headline"
    (input)="item.headline = $event.target.textContent"></h1>
}
@if (item?.text && !contentEdit) {
  <h3 style="margin-bottom: 20px; font-family: Siemens Slab bold; margin-top: 20px" [innerHTML]="item?.text | stripParagraphs"></h3>
}
@if (contentEdit) {
  <coin-html-editor [item]="item" [contentEdit]="true" [isNews]="true"></coin-html-editor>
}
