import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CmsComponentsModule } from '@coin/admin/cms/feature-preview';
import { AppEnvironment, CustomTranslationLoader, TranslationDebugParser } from '@coin/shared/data-access-translation-loader';
import { AllCmsNewsItemsComponent, EquityCmsChapterOverviewComponent } from '@coin/modules/cms/feature';
import { TranslateModule } from '@ngx-translate/core';
import { SimplebarAngularModule } from 'simplebar-angular';
import { EquityModule } from '../equity/equity.module';
import { SharedModule } from '../shared/shared.module';
import { ContentPagesComponent } from './content-pages.component';

@NgModule({
  declarations: [ContentPagesComponent],
  imports: [
    CmsComponentsModule,
    TranslateModule.forChild(CustomTranslationLoader.createConfig(AppEnvironment.Customer, TranslationDebugParser)),
    SharedModule,
    CommonModule,
    FormsModule,
    SimplebarAngularModule,
    EquityModule,
    EquityCmsChapterOverviewComponent,
    AllCmsNewsItemsComponent
  ],
  providers: []
})
export class ContentPagesModule {}
