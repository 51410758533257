import { Component, DestroyRef, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CmsItem } from '@coin/admin/cms/util';
import { LegacyComponentsModule } from '@coin/shared/feature-legacy-components';
import { V2ButtonComponent, V2CheckboxComponent } from '@coin/shared/ui-storybook';
import { TranslateModule } from '@ngx-translate/core';
import { HtmlEditorComponent } from '../../shared';
import { SelectMediaComponent } from '../../../dialogs/shared/select-media/select-media.component';

@Component({
  selector: 'coin-email-main-body-image-text',
  templateUrl: './email-main-body-image-text.component.html',
  styleUrls: ['./email-main-body-image-text.component.scss'],
  standalone: true,
  imports: [V2CheckboxComponent, LegacyComponentsModule, HtmlEditorComponent, V2ButtonComponent, TranslateModule, FormsModule]
})
export class EmailMainBodyImageTextComponent {
  @Input() item: CmsItem<'main-body-image-text'>;
  @Input() showAdditionalEditOptions = false;
  @Input() contentEdit: boolean;

  constructor(
    private dialog: MatDialog,
    private destroyRef: DestroyRef
  ) {}

  public editPicture(): void {
    SelectMediaComponent.openDialog(this.dialog, { mediaType: 'image' })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(newImage => {
        if (newImage) {
          this.item.content.img = newImage.mediaUrl;
        }
      });
  }
}
