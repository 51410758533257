import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonToggleChange, MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { V2DialogModule } from '../dialog-frame/v2-dialog.module';
import { V2ButtonComponent } from '../button/v2-button.component';

interface Option {
  value: string;
  title: string;
}

interface DialogData {
  title?: string;
  subTitle?: string;
  options: Option[];
}

@Component({
  selector: 'coin-select-dialog',
  templateUrl: './select-dialog.component.html',
  styleUrl: './select-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslateModule, V2DialogModule, MatButtonToggle, MatButtonToggleGroup, V2ButtonComponent]
})
export class SelectDialogComponent {
  public selectedOption: string;

  constructor(
    private dialogRef: MatDialogRef<SelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  public confirm(): void {
    this.dialogRef.close(this.selectedOption);
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public onChange(event: MatButtonToggleChange): void {
    this.selectedOption = event.value;
  }

  public static openDialog(dialog: MatDialog, data: SelectDialogComponent['data']): Observable<string> {
    return dialog
      .open<SelectDialogComponent, SelectDialogComponent['data'], string>(SelectDialogComponent, {
        disableClose: true,
        maxHeight: '40vh',
        maxWidth: '50vw',
        minWidth: '370px',
        width: 'auto',
        data
      })
      .afterClosed();
  }
}
