import { Component, Input } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CmsUsages } from '@coin/admin/cms/util';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { KeyValuePipe } from '@angular/common';

@Component({
  selector: 'coin-cms-image-usages',
  templateUrl: './cms-image-usages.component.html',
  styleUrls: ['./cms-image-usages.component.scss'],
  standalone: true,
  imports: [MatIconModule, MatExpansionModule, MatTooltipModule, TranslateModule, MatListModule, KeyValuePipe]
})
export class CmsImageUsagesComponent {
  @Input() usages: CmsUsages;
  @Input() isImageUsed: boolean;
  public panelOpenState = false;

  constructor(private translate: TranslateService) {}

  public get getCountTranslation(): string {
    return this.translate.instant('cms.image-is-used-in');
  }

  public get getNoUsageFoundTranslation(): string {
    return this.translate.instant('cms.no-usage-found');
  }

  public get contentPageCount(): number {
    return Object.values(this.usages.contentPages)
      .map(arr => arr.length)
      .reduce((a, b) => a + b, 0);
  }

  public get isImageInContentPageUsed(): boolean {
    return this.contentPageCount > 0;
  }

  public convertItem(i: string): string;
  public convertItem(i: string[]): string[];
  public convertItem(item: string | string[]): typeof item {
    if (typeof item === 'string') {
      return item.split('/')[2].split('-')[1];
    }
    return item.map(v => v.split('/')[2].split('-')[1]);
  }

  public decodeItem(i: string): string;
  public decodeItem(i: string[]): string[];
  public decodeItem(item: string | string[]): typeof item {
    if (typeof item === 'string') {
      return window.atob(this.convertItem(item));
    }
    return this.convertItem(item).map(v => window.atob(v));
  }

  public getEmailHref(emailKey: string): string {
    return `/cms/emails/${encodeURIComponent(emailKey)}`;
  }

  public getEmailDisplayName(emailKey: string): string {
    return this.decodeItem(emailKey);
  }

  public getNewsHref(newsKey: string): string {
    return `/cms/news/${encodeURIComponent(newsKey)}`;
  }

  public getNewsDisplayName(newsKey: string): string {
    return this.decodeItem(newsKey);
  }

  public getContentPageHref(contentPageKey: string): string {
    return `/cms/content-pages/${contentPageKey}`;
  }

  public getContentPageKey(contentPageKey: string): string {
    return contentPageKey.split('/')[1];
  }

  public getContentPageLink(item: string[]): string[] {
    return this.decodeItem(item);
  }
}
