import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CmsItem } from '@coin/admin/cms/util';
import { V2ButtonComponent, V2CheckboxComponent } from '@coin/shared/ui-storybook';
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'coin-equity-cms-chapter',
  templateUrl: './equity-cms-chapter.component.html',
  styleUrls: ['./equity-cms-chapter.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, FormsModule, TranslateModule, V2CheckboxComponent, MatSlideToggleModule, V2ButtonComponent, MatInput]
})
export class EquityCmsChapterComponent {
  @Input() contentEdit = false;
  @Input() item: CmsItem<'chapter'>;

  @Input() germanyRowSlideToggle = false;
  @Output() slideChange = new EventEmitter<MatSlideToggleChange>();

  public printPage(): void {
    window.print();
  }
}
