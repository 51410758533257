@if (item?.content?.length) {
  <div class="container">
    @for (subitem of item.content; track subitem) {
      <coin-equity-cms-card-item [modifiedWidth]="modifiedWidth" [card]="subitem" [contentEdit]="contentEdit" [small]="$any(item).small"></coin-equity-cms-card-item>
    }
  </div>
}

@if (contentEdit && item) {
  <div>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'cms.amount-cards' | translate }}</mat-label>
      <mat-select (selectionChange)="amountChange($event.value)" [(ngModel)]="amountCards">
        @for (option of amountOptions; track option) {
          <mat-option [value]="option">{{ option }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <coin-v2-checkbox [(ngModel)]="item['small']" [label]="'cms.content-pages.small-image' | translate" />
    <coin-v2-checkbox [(ngModel)]="item.requiresAuthentication" [label]="'cms.content-pages.requires-authentication' | translate"> </coin-v2-checkbox>
  </div>
}
