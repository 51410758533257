import { Component, Input } from '@angular/core';
import { CmsItem } from '@coin/admin/cms/util';
import { StripParagraphsPipe } from '@coin/shared/util-pipes';
import { HtmlEditorComponent } from '../../shared/html-editor/html-editor.component';

@Component({
  selector: 'coin-news-headline',
  templateUrl: './news-headline.component.html',
  styleUrls: ['./news-headline.component.scss'],
  standalone: true,
  imports: [HtmlEditorComponent, StripParagraphsPipe]
})
export class NewsHeadlineComponent {
  @Input() item: CmsItem<'headline-with-subheadline'>;
  @Input() contentEdit = false;
}
