import { Component, DestroyRef, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { CmsItem } from '@coin/admin/cms/util';
import { V2ButtonComponent } from '@coin/shared/ui-storybook';
import { TranslateModule } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { SelectMediaComponent } from '../../../dialogs/shared/select-media/select-media.component';
import { EnlargeImageComponent } from '../../../dialogs/shared/enlarge-image/enlarge-image.component';

@Component({
  selector: 'coin-equity-cms-full-widthpart-with-image',
  templateUrl: './equity-cms-full-widthpart-with-image.component.html',
  styleUrls: ['./equity-cms-full-widthpart-with-image.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatLabel, TranslateModule, FormsModule, V2ButtonComponent, MatInput]
})
export class EquityCmsFullWidthpartWithImageComponent {
  @Input() item: CmsItem<'full-width-part-with-image'>;
  @Input() contentEdit = false;
  @Input() enableEnlarge = true;

  constructor(
    private dialog: MatDialog,
    private destroyRef: DestroyRef
  ) {}

  /**
   * Open popup to select a different image or upload a new one
   * @param item current image item
   */
  public editPicture(item: CmsItem<'full-width-part-with-image'>['content']): void {
    SelectMediaComponent.openDialog(this.dialog, { url: item?.imageUrl, mediaType: 'image' })
      .pipe(
        filter(newImage => !!newImage),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(newImage => {
        item.imageUrl = newImage.mediaUrl;
      });
  }

  /**
   * Open popup with current image but bigger in size
   */
  public enlargeImage(): void {
    this.dialog.open(EnlargeImageComponent, { data: { imageUrl: this.item.content.imageUrl } });
  }
}
