/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  SharedPageModelOfEmployeesEmployeesEmployeeModel,
  SharedPageModelOfSpecialPaymentsRecordsSpecialPaymentRecordModel,
  SharedPageModelOfSystemString,
  SpecialPaymentsApprovalsSpecialPaymentApprovalModel,
  SpecialPaymentsApprovalsUpdateSpecialPaymentApprovalModel,
  SpecialPaymentsDashboardDashboardValuesModel,
  SpecialPaymentsExportCreateSpecialPaymentProposalExportModel,
  SpecialPaymentsRecordsAcknowledgeSpecialPaymentRecordModel,
  SpecialPaymentsRecordsCreateSpecialPaymentRecordModel,
  SpecialPaymentsRecordsResetSpecialPaymentApprovalModel,
  SpecialPaymentsRecordsSpecialPaymentRecordModel,
  SpecialPaymentsRecordsUpdateSpecialPaymentRecordModel,
  TransactionStatusOfExportStatusMetadata
} from '@coin/shared/util-models';

import { CustomHttpParameterCodec } from '../encoder';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

export interface SpecialPaymentsCustomerServiceAcknowledgeRecordsRequestParams {
  /** The unique id of the record to acknowledge. */
  id: string;
  /** The model data to process the acknowledgement with. */
  specialPaymentsRecordsAcknowledgeSpecialPaymentRecordModel?: SpecialPaymentsRecordsAcknowledgeSpecialPaymentRecordModel;
}

export interface SpecialPaymentsCustomerServiceCreateProposalExportExportRequestParams {
  orgCodeStartsWith?: string;
  isDraft?: boolean;
  isCurrentApprover?: boolean;
  isCreatedByMe?: boolean;
  withUserAccessFilter?: boolean;
  withIsDraftFilter?: boolean;
  withChainOnlyFilter?: boolean;
  employeeId?: Array<string>;
  seasonId?: Array<string>;
  proposalState?: Array<'Unknown' | 'Pending' | 'Approved' | 'AutoApproved' | 'Rejected'>;
  currentApprover?: Array<'Unknown' | 'LineManager' | 'HRBusinessPartner' | 'RegionalHrHead' | 'NMinus2' | 'HeadOfHierarchy'>;
  specialPaymentReason?: Array<'Unknown' | 'SpecialProjectsInvolvement' | 'TemporaryEnlargedScope'>;
  orgLevel?: Array<number>;
  orgCode?: Array<string>;
  placeOfAction?: Array<string>;
  managerGid?: Array<string>;
  headOfHierarchyGid?: Array<string>;
  regionalHRHeadGid?: Array<string>;
  nMinus2ManagerGid?: Array<string>;
  lineManagerGid?: Array<string>;
  hRBusinessPartnerGid?: Array<string>;
  allProposals?: boolean;
  /** The model data to create the export with. */
  specialPaymentsExportCreateSpecialPaymentProposalExportModel?: SpecialPaymentsExportCreateSpecialPaymentProposalExportModel;
}

export interface SpecialPaymentsCustomerServiceCreateRecordsRequestParams {
  /** The model data to create the record with. */
  specialPaymentsRecordsCreateSpecialPaymentRecordModel?: SpecialPaymentsRecordsCreateSpecialPaymentRecordModel;
}

export interface SpecialPaymentsCustomerServiceDeleteRecordsRequestParams {
  /** The unique id of the record to delete. */
  id: string;
}

export interface SpecialPaymentsCustomerServiceGetDashboardRequestParams {
  allProposals?: boolean;
}

export interface SpecialPaymentsCustomerServiceGetRecordsRequestParams {
  /** The unique id of the record to get. */
  id: string;
}

export interface SpecialPaymentsCustomerServiceQueryCandidatesRequestParams {
  queryGid?: Array<string>;
  queryFirstName?: Array<string>;
  queryLastName?: Array<string>;
  queryOrgCode?: Array<string>;
  queryHasProposals?: boolean;
  queryForProposalCreation?: boolean;
  pagingPage?: number;
  pagingSize?: number;
  allProposals?: boolean;
}

export interface SpecialPaymentsCustomerServiceQueryFieldValuesRecordsRequestParams {
  targetProperty?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
  userId?: string;
  allProposals?: boolean;
  queryOrgCodeStartsWith?: string;
  queryIsDraft?: boolean;
  queryIsCurrentApprover?: boolean;
  queryIsCreatedByMe?: boolean;
  queryWithUserAccessFilter?: boolean;
  queryWithIsDraftFilter?: boolean;
  queryWithChainOnlyFilter?: boolean;
  queryEmployeeId?: Array<string>;
  querySeasonId?: Array<string>;
  queryProposalState?: Array<'Unknown' | 'Pending' | 'Approved' | 'AutoApproved' | 'Rejected'>;
  queryCurrentApprover?: Array<'Unknown' | 'LineManager' | 'HRBusinessPartner' | 'RegionalHrHead' | 'NMinus2' | 'HeadOfHierarchy'>;
  querySpecialPaymentReason?: Array<'Unknown' | 'SpecialProjectsInvolvement' | 'TemporaryEnlargedScope'>;
  queryOrgLevel?: Array<number>;
  queryOrgCode?: Array<string>;
  queryPlaceOfAction?: Array<string>;
  queryManagerGid?: Array<string>;
  queryHeadOfHierarchyGid?: Array<string>;
  queryRegionalHRHeadGid?: Array<string>;
  queryNMinus2ManagerGid?: Array<string>;
  queryLineManagerGid?: Array<string>;
  queryHRBusinessPartnerGid?: Array<string>;
  pagingPage?: number;
  pagingSize?: number;
}

export interface SpecialPaymentsCustomerServiceQueryRecordsRequestParams {
  allProposals?: boolean;
  queryOrgCodeStartsWith?: string;
  queryIsDraft?: boolean;
  queryIsCurrentApprover?: boolean;
  queryIsCreatedByMe?: boolean;
  queryWithUserAccessFilter?: boolean;
  queryWithIsDraftFilter?: boolean;
  queryWithChainOnlyFilter?: boolean;
  queryEmployeeId?: Array<string>;
  querySeasonId?: Array<string>;
  queryProposalState?: Array<'Unknown' | 'Pending' | 'Approved' | 'AutoApproved' | 'Rejected'>;
  queryCurrentApprover?: Array<'Unknown' | 'LineManager' | 'HRBusinessPartner' | 'RegionalHrHead' | 'NMinus2' | 'HeadOfHierarchy'>;
  querySpecialPaymentReason?: Array<'Unknown' | 'SpecialProjectsInvolvement' | 'TemporaryEnlargedScope'>;
  queryOrgLevel?: Array<number>;
  queryOrgCode?: Array<string>;
  queryPlaceOfAction?: Array<string>;
  queryManagerGid?: Array<string>;
  queryHeadOfHierarchyGid?: Array<string>;
  queryRegionalHRHeadGid?: Array<string>;
  queryNMinus2ManagerGid?: Array<string>;
  queryLineManagerGid?: Array<string>;
  queryHRBusinessPartnerGid?: Array<string>;
  pagingPage?: number;
  pagingSize?: number;
  sortingOrderBy?: 'Asc' | 'Desc';
  sortingProperty?: string;
}

export interface SpecialPaymentsCustomerServiceResetRecordsRequestParams {
  /** The unique id of the record to reset approvals of. */
  recordId: string;
  /** The model data to execute the reset with. */
  specialPaymentsRecordsResetSpecialPaymentApprovalModel?: SpecialPaymentsRecordsResetSpecialPaymentApprovalModel;
}

export interface SpecialPaymentsCustomerServiceUpdateApprovalsRequestParams {
  /** The unique id of the approval to update. */
  id: string;
  /** The model data to update the approval with. */
  specialPaymentsApprovalsUpdateSpecialPaymentApprovalModel?: SpecialPaymentsApprovalsUpdateSpecialPaymentApprovalModel;
}

export interface SpecialPaymentsCustomerServiceUpdateRecordsRequestParams {
  /** The unique id of the existing record to update. */
  id: string;
  /** The model data to update the existing record with. */
  specialPaymentsRecordsUpdateSpecialPaymentRecordModel?: SpecialPaymentsRecordsUpdateSpecialPaymentRecordModel;
}

@Injectable({
  providedIn: 'root'
})
export class SpecialPaymentsCustomerService {
  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(k => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * Acknowledges an existing record by its unique id.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public acknowledgeRecords(
    requestParameters?: SpecialPaymentsCustomerServiceAcknowledgeRecordsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SpecialPaymentsRecordsSpecialPaymentRecordModel>;
  public acknowledgeRecords(
    requestParameters?: SpecialPaymentsCustomerServiceAcknowledgeRecordsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SpecialPaymentsRecordsSpecialPaymentRecordModel>>;
  public acknowledgeRecords(
    requestParameters?: SpecialPaymentsCustomerServiceAcknowledgeRecordsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SpecialPaymentsRecordsSpecialPaymentRecordModel>>;
  public acknowledgeRecords(
    requestParameters?: SpecialPaymentsCustomerServiceAcknowledgeRecordsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling acknowledgeRecords.');
    }
    const specialPaymentsRecordsAcknowledgeSpecialPaymentRecordModel = requestParameters?.specialPaymentsRecordsAcknowledgeSpecialPaymentRecordModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/customer/api/v1/master/records/special-payment/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/acknowledgement`;
    return this.httpClient.request<SpecialPaymentsRecordsSpecialPaymentRecordModel>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: specialPaymentsRecordsAcknowledgeSpecialPaymentRecordModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Creates a new payroll export.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createProposalExportExport(
    requestParameters?: SpecialPaymentsCustomerServiceCreateProposalExportExportRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<TransactionStatusOfExportStatusMetadata>;
  public createProposalExportExport(
    requestParameters?: SpecialPaymentsCustomerServiceCreateProposalExportExportRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<TransactionStatusOfExportStatusMetadata>>;
  public createProposalExportExport(
    requestParameters?: SpecialPaymentsCustomerServiceCreateProposalExportExportRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<TransactionStatusOfExportStatusMetadata>>;
  public createProposalExportExport(
    requestParameters?: SpecialPaymentsCustomerServiceCreateProposalExportExportRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const orgCodeStartsWith = requestParameters?.orgCodeStartsWith;
    const isDraft = requestParameters?.isDraft;
    const isCurrentApprover = requestParameters?.isCurrentApprover;
    const isCreatedByMe = requestParameters?.isCreatedByMe;
    const withUserAccessFilter = requestParameters?.withUserAccessFilter;
    const withIsDraftFilter = requestParameters?.withIsDraftFilter;
    const withChainOnlyFilter = requestParameters?.withChainOnlyFilter;
    const employeeId = requestParameters?.employeeId;
    const seasonId = requestParameters?.seasonId;
    const proposalState = requestParameters?.proposalState;
    const currentApprover = requestParameters?.currentApprover;
    const specialPaymentReason = requestParameters?.specialPaymentReason;
    const orgLevel = requestParameters?.orgLevel;
    const orgCode = requestParameters?.orgCode;
    const placeOfAction = requestParameters?.placeOfAction;
    const managerGid = requestParameters?.managerGid;
    const headOfHierarchyGid = requestParameters?.headOfHierarchyGid;
    const regionalHRHeadGid = requestParameters?.regionalHRHeadGid;
    const nMinus2ManagerGid = requestParameters?.nMinus2ManagerGid;
    const lineManagerGid = requestParameters?.lineManagerGid;
    const hRBusinessPartnerGid = requestParameters?.hRBusinessPartnerGid;
    const allProposals = requestParameters?.allProposals;
    const specialPaymentsExportCreateSpecialPaymentProposalExportModel = requestParameters?.specialPaymentsExportCreateSpecialPaymentProposalExportModel;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (orgCodeStartsWith !== undefined && orgCodeStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>orgCodeStartsWith, 'OrgCodeStartsWith');
    }
    if (isDraft !== undefined && isDraft !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>isDraft, 'IsDraft');
    }
    if (isCurrentApprover !== undefined && isCurrentApprover !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>isCurrentApprover, 'IsCurrentApprover');
    }
    if (isCreatedByMe !== undefined && isCreatedByMe !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>isCreatedByMe, 'IsCreatedByMe');
    }
    if (withUserAccessFilter !== undefined && withUserAccessFilter !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>withUserAccessFilter, 'WithUserAccessFilter');
    }
    if (withIsDraftFilter !== undefined && withIsDraftFilter !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>withIsDraftFilter, 'WithIsDraftFilter');
    }
    if (withChainOnlyFilter !== undefined && withChainOnlyFilter !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>withChainOnlyFilter, 'WithChainOnlyFilter');
    }
    if (employeeId) {
      employeeId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'EmployeeId');
      });
    }
    if (seasonId) {
      seasonId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'SeasonId');
      });
    }
    if (proposalState) {
      proposalState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ProposalState');
      });
    }
    if (currentApprover) {
      currentApprover.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CurrentApprover');
      });
    }
    if (specialPaymentReason) {
      specialPaymentReason.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'SpecialPaymentReason');
      });
    }
    if (orgLevel) {
      orgLevel.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'OrgLevel');
      });
    }
    if (orgCode) {
      orgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'OrgCode');
      });
    }
    if (placeOfAction) {
      placeOfAction.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PlaceOfAction');
      });
    }
    if (managerGid) {
      managerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ManagerGid');
      });
    }
    if (headOfHierarchyGid) {
      headOfHierarchyGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'HeadOfHierarchyGid');
      });
    }
    if (regionalHRHeadGid) {
      regionalHRHeadGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'RegionalHRHeadGid');
      });
    }
    if (nMinus2ManagerGid) {
      nMinus2ManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'NMinus2ManagerGid');
      });
    }
    if (lineManagerGid) {
      lineManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerGid');
      });
    }
    if (hRBusinessPartnerGid) {
      hRBusinessPartnerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'HRBusinessPartnerGid');
      });
    }
    if (allProposals !== undefined && allProposals !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>allProposals, 'allProposals');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/customer/api/v1/master/records/special-payment/export`;
    return this.httpClient.request<TransactionStatusOfExportStatusMetadata>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: specialPaymentsExportCreateSpecialPaymentProposalExportModel,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Creates a new record.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createRecords(
    requestParameters?: SpecialPaymentsCustomerServiceCreateRecordsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SpecialPaymentsRecordsSpecialPaymentRecordModel>;
  public createRecords(
    requestParameters?: SpecialPaymentsCustomerServiceCreateRecordsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SpecialPaymentsRecordsSpecialPaymentRecordModel>>;
  public createRecords(
    requestParameters?: SpecialPaymentsCustomerServiceCreateRecordsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SpecialPaymentsRecordsSpecialPaymentRecordModel>>;
  public createRecords(
    requestParameters?: SpecialPaymentsCustomerServiceCreateRecordsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const specialPaymentsRecordsCreateSpecialPaymentRecordModel = requestParameters?.specialPaymentsRecordsCreateSpecialPaymentRecordModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/customer/api/v1/master/records/special-payment`;
    return this.httpClient.request<SpecialPaymentsRecordsSpecialPaymentRecordModel>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: specialPaymentsRecordsCreateSpecialPaymentRecordModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Deletes an existing record by its unique id.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteRecords(
    requestParameters?: SpecialPaymentsCustomerServiceDeleteRecordsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<any>;
  public deleteRecords(
    requestParameters?: SpecialPaymentsCustomerServiceDeleteRecordsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<any>>;
  public deleteRecords(
    requestParameters?: SpecialPaymentsCustomerServiceDeleteRecordsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<any>>;
  public deleteRecords(
    requestParameters?: SpecialPaymentsCustomerServiceDeleteRecordsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteRecords.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/customer/api/v1/master/records/special-payment/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Gets the special payment dashboard values.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDashboard(
    requestParameters?: SpecialPaymentsCustomerServiceGetDashboardRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SpecialPaymentsDashboardDashboardValuesModel>;
  public getDashboard(
    requestParameters?: SpecialPaymentsCustomerServiceGetDashboardRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SpecialPaymentsDashboardDashboardValuesModel>>;
  public getDashboard(
    requestParameters?: SpecialPaymentsCustomerServiceGetDashboardRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SpecialPaymentsDashboardDashboardValuesModel>>;
  public getDashboard(
    requestParameters?: SpecialPaymentsCustomerServiceGetDashboardRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const allProposals = requestParameters?.allProposals;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (allProposals !== undefined && allProposals !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>allProposals, 'allProposals');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/customer/api/v1/master/special-payment/dashboard`;
    return this.httpClient.request<SpecialPaymentsDashboardDashboardValuesModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Gets an existing record by its unique id.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getRecords(
    requestParameters?: SpecialPaymentsCustomerServiceGetRecordsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SpecialPaymentsRecordsSpecialPaymentRecordModel>;
  public getRecords(
    requestParameters?: SpecialPaymentsCustomerServiceGetRecordsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SpecialPaymentsRecordsSpecialPaymentRecordModel>>;
  public getRecords(
    requestParameters?: SpecialPaymentsCustomerServiceGetRecordsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SpecialPaymentsRecordsSpecialPaymentRecordModel>>;
  public getRecords(
    requestParameters?: SpecialPaymentsCustomerServiceGetRecordsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getRecords.');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/customer/api/v1/master/records/special-payment/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<SpecialPaymentsRecordsSpecialPaymentRecordModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Queries existing candidates by specified parameters.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryCandidates(
    requestParameters?: SpecialPaymentsCustomerServiceQueryCandidatesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfEmployeesEmployeesEmployeeModel>;
  public queryCandidates(
    requestParameters?: SpecialPaymentsCustomerServiceQueryCandidatesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfEmployeesEmployeesEmployeeModel>>;
  public queryCandidates(
    requestParameters?: SpecialPaymentsCustomerServiceQueryCandidatesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfEmployeesEmployeesEmployeeModel>>;
  public queryCandidates(
    requestParameters?: SpecialPaymentsCustomerServiceQueryCandidatesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const queryGid = requestParameters?.queryGid;
    const queryFirstName = requestParameters?.queryFirstName;
    const queryLastName = requestParameters?.queryLastName;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryHasProposals = requestParameters?.queryHasProposals;
    const queryForProposalCreation = requestParameters?.queryForProposalCreation;
    const pagingPage = requestParameters?.pagingPage;
    const pagingSize = requestParameters?.pagingSize;
    const allProposals = requestParameters?.allProposals;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (queryGid) {
      queryGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.Gid');
      });
    }
    if (queryFirstName) {
      queryFirstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.FirstName');
      });
    }
    if (queryLastName) {
      queryLastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.LastName');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryHasProposals !== undefined && queryHasProposals !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryHasProposals, 'Query.HasProposals');
    }
    if (queryForProposalCreation !== undefined && queryForProposalCreation !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryForProposalCreation, 'Query.ForProposalCreation');
    }
    if (pagingPage !== undefined && pagingPage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingPage, 'Paging.Page');
    }
    if (pagingSize !== undefined && pagingSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingSize, 'Paging.Size');
    }
    if (allProposals !== undefined && allProposals !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>allProposals, 'AllProposals');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/customer/api/v1/master/special-payment/candidates`;
    return this.httpClient.request<SharedPageModelOfEmployeesEmployeesEmployeeModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Queries existing record field values by specified parameters.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryFieldValuesRecords(
    requestParameters?: SpecialPaymentsCustomerServiceQueryFieldValuesRecordsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfSystemString>;
  public queryFieldValuesRecords(
    requestParameters?: SpecialPaymentsCustomerServiceQueryFieldValuesRecordsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfSystemString>>;
  public queryFieldValuesRecords(
    requestParameters?: SpecialPaymentsCustomerServiceQueryFieldValuesRecordsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfSystemString>>;
  public queryFieldValuesRecords(
    requestParameters?: SpecialPaymentsCustomerServiceQueryFieldValuesRecordsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const targetProperty = requestParameters?.targetProperty;
    const userId = requestParameters?.userId;
    const allProposals = requestParameters?.allProposals;
    const queryOrgCodeStartsWith = requestParameters?.queryOrgCodeStartsWith;
    const queryIsDraft = requestParameters?.queryIsDraft;
    const queryIsCurrentApprover = requestParameters?.queryIsCurrentApprover;
    const queryIsCreatedByMe = requestParameters?.queryIsCreatedByMe;
    const queryWithUserAccessFilter = requestParameters?.queryWithUserAccessFilter;
    const queryWithIsDraftFilter = requestParameters?.queryWithIsDraftFilter;
    const queryWithChainOnlyFilter = requestParameters?.queryWithChainOnlyFilter;
    const queryEmployeeId = requestParameters?.queryEmployeeId;
    const querySeasonId = requestParameters?.querySeasonId;
    const queryProposalState = requestParameters?.queryProposalState;
    const queryCurrentApprover = requestParameters?.queryCurrentApprover;
    const querySpecialPaymentReason = requestParameters?.querySpecialPaymentReason;
    const queryOrgLevel = requestParameters?.queryOrgLevel;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryPlaceOfAction = requestParameters?.queryPlaceOfAction;
    const queryManagerGid = requestParameters?.queryManagerGid;
    const queryHeadOfHierarchyGid = requestParameters?.queryHeadOfHierarchyGid;
    const queryRegionalHRHeadGid = requestParameters?.queryRegionalHRHeadGid;
    const queryNMinus2ManagerGid = requestParameters?.queryNMinus2ManagerGid;
    const queryLineManagerGid = requestParameters?.queryLineManagerGid;
    const queryHRBusinessPartnerGid = requestParameters?.queryHRBusinessPartnerGid;
    const pagingPage = requestParameters?.pagingPage;
    const pagingSize = requestParameters?.pagingSize;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (targetProperty !== undefined && targetProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>targetProperty, 'TargetProperty');
    }
    if (userId !== undefined && userId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>userId, 'UserId');
    }
    if (allProposals !== undefined && allProposals !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>allProposals, 'AllProposals');
    }
    if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryOrgCodeStartsWith, 'Query.OrgCodeStartsWith');
    }
    if (queryIsDraft !== undefined && queryIsDraft !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryIsDraft, 'Query.IsDraft');
    }
    if (queryIsCurrentApprover !== undefined && queryIsCurrentApprover !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryIsCurrentApprover, 'Query.IsCurrentApprover');
    }
    if (queryIsCreatedByMe !== undefined && queryIsCreatedByMe !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryIsCreatedByMe, 'Query.IsCreatedByMe');
    }
    if (queryWithUserAccessFilter !== undefined && queryWithUserAccessFilter !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryWithUserAccessFilter, 'Query.WithUserAccessFilter');
    }
    if (queryWithIsDraftFilter !== undefined && queryWithIsDraftFilter !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryWithIsDraftFilter, 'Query.WithIsDraftFilter');
    }
    if (queryWithChainOnlyFilter !== undefined && queryWithChainOnlyFilter !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryWithChainOnlyFilter, 'Query.WithChainOnlyFilter');
    }
    if (queryEmployeeId) {
      queryEmployeeId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.EmployeeId');
      });
    }
    if (querySeasonId) {
      querySeasonId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonId');
      });
    }
    if (queryProposalState) {
      queryProposalState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ProposalState');
      });
    }
    if (queryCurrentApprover) {
      queryCurrentApprover.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.CurrentApprover');
      });
    }
    if (querySpecialPaymentReason) {
      querySpecialPaymentReason.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SpecialPaymentReason');
      });
    }
    if (queryOrgLevel) {
      queryOrgLevel.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgLevel');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryPlaceOfAction) {
      queryPlaceOfAction.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlaceOfAction');
      });
    }
    if (queryManagerGid) {
      queryManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ManagerGid');
      });
    }
    if (queryHeadOfHierarchyGid) {
      queryHeadOfHierarchyGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.HeadOfHierarchyGid');
      });
    }
    if (queryRegionalHRHeadGid) {
      queryRegionalHRHeadGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.RegionalHRHeadGid');
      });
    }
    if (queryNMinus2ManagerGid) {
      queryNMinus2ManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.NMinus2ManagerGid');
      });
    }
    if (queryLineManagerGid) {
      queryLineManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.LineManagerGid');
      });
    }
    if (queryHRBusinessPartnerGid) {
      queryHRBusinessPartnerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.HRBusinessPartnerGid');
      });
    }
    if (pagingPage !== undefined && pagingPage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingPage, 'Paging.Page');
    }
    if (pagingSize !== undefined && pagingSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingSize, 'Paging.Size');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/customer/api/v1/master/records/special-payment/field-values`;
    return this.httpClient.request<SharedPageModelOfSystemString>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Queries existing records by specified parameters.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryRecords(
    requestParameters?: SpecialPaymentsCustomerServiceQueryRecordsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfSpecialPaymentsRecordsSpecialPaymentRecordModel>;
  public queryRecords(
    requestParameters?: SpecialPaymentsCustomerServiceQueryRecordsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfSpecialPaymentsRecordsSpecialPaymentRecordModel>>;
  public queryRecords(
    requestParameters?: SpecialPaymentsCustomerServiceQueryRecordsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfSpecialPaymentsRecordsSpecialPaymentRecordModel>>;
  public queryRecords(
    requestParameters?: SpecialPaymentsCustomerServiceQueryRecordsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const allProposals = requestParameters?.allProposals;
    const queryOrgCodeStartsWith = requestParameters?.queryOrgCodeStartsWith;
    const queryIsDraft = requestParameters?.queryIsDraft;
    const queryIsCurrentApprover = requestParameters?.queryIsCurrentApprover;
    const queryIsCreatedByMe = requestParameters?.queryIsCreatedByMe;
    const queryWithUserAccessFilter = requestParameters?.queryWithUserAccessFilter;
    const queryWithIsDraftFilter = requestParameters?.queryWithIsDraftFilter;
    const queryWithChainOnlyFilter = requestParameters?.queryWithChainOnlyFilter;
    const queryEmployeeId = requestParameters?.queryEmployeeId;
    const querySeasonId = requestParameters?.querySeasonId;
    const queryProposalState = requestParameters?.queryProposalState;
    const queryCurrentApprover = requestParameters?.queryCurrentApprover;
    const querySpecialPaymentReason = requestParameters?.querySpecialPaymentReason;
    const queryOrgLevel = requestParameters?.queryOrgLevel;
    const queryOrgCode = requestParameters?.queryOrgCode;
    const queryPlaceOfAction = requestParameters?.queryPlaceOfAction;
    const queryManagerGid = requestParameters?.queryManagerGid;
    const queryHeadOfHierarchyGid = requestParameters?.queryHeadOfHierarchyGid;
    const queryRegionalHRHeadGid = requestParameters?.queryRegionalHRHeadGid;
    const queryNMinus2ManagerGid = requestParameters?.queryNMinus2ManagerGid;
    const queryLineManagerGid = requestParameters?.queryLineManagerGid;
    const queryHRBusinessPartnerGid = requestParameters?.queryHRBusinessPartnerGid;
    const pagingPage = requestParameters?.pagingPage;
    const pagingSize = requestParameters?.pagingSize;
    const sortingOrderBy = requestParameters?.sortingOrderBy;
    const sortingProperty = requestParameters?.sortingProperty;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (allProposals !== undefined && allProposals !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>allProposals, 'AllProposals');
    }
    if (queryOrgCodeStartsWith !== undefined && queryOrgCodeStartsWith !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryOrgCodeStartsWith, 'Query.OrgCodeStartsWith');
    }
    if (queryIsDraft !== undefined && queryIsDraft !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryIsDraft, 'Query.IsDraft');
    }
    if (queryIsCurrentApprover !== undefined && queryIsCurrentApprover !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryIsCurrentApprover, 'Query.IsCurrentApprover');
    }
    if (queryIsCreatedByMe !== undefined && queryIsCreatedByMe !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryIsCreatedByMe, 'Query.IsCreatedByMe');
    }
    if (queryWithUserAccessFilter !== undefined && queryWithUserAccessFilter !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryWithUserAccessFilter, 'Query.WithUserAccessFilter');
    }
    if (queryWithIsDraftFilter !== undefined && queryWithIsDraftFilter !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryWithIsDraftFilter, 'Query.WithIsDraftFilter');
    }
    if (queryWithChainOnlyFilter !== undefined && queryWithChainOnlyFilter !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryWithChainOnlyFilter, 'Query.WithChainOnlyFilter');
    }
    if (queryEmployeeId) {
      queryEmployeeId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.EmployeeId');
      });
    }
    if (querySeasonId) {
      querySeasonId.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SeasonId');
      });
    }
    if (queryProposalState) {
      queryProposalState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ProposalState');
      });
    }
    if (queryCurrentApprover) {
      queryCurrentApprover.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.CurrentApprover');
      });
    }
    if (querySpecialPaymentReason) {
      querySpecialPaymentReason.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.SpecialPaymentReason');
      });
    }
    if (queryOrgLevel) {
      queryOrgLevel.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgLevel');
      });
    }
    if (queryOrgCode) {
      queryOrgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.OrgCode');
      });
    }
    if (queryPlaceOfAction) {
      queryPlaceOfAction.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.PlaceOfAction');
      });
    }
    if (queryManagerGid) {
      queryManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.ManagerGid');
      });
    }
    if (queryHeadOfHierarchyGid) {
      queryHeadOfHierarchyGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.HeadOfHierarchyGid');
      });
    }
    if (queryRegionalHRHeadGid) {
      queryRegionalHRHeadGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.RegionalHRHeadGid');
      });
    }
    if (queryNMinus2ManagerGid) {
      queryNMinus2ManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.NMinus2ManagerGid');
      });
    }
    if (queryLineManagerGid) {
      queryLineManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.LineManagerGid');
      });
    }
    if (queryHRBusinessPartnerGid) {
      queryHRBusinessPartnerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.HRBusinessPartnerGid');
      });
    }
    if (pagingPage !== undefined && pagingPage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingPage, 'Paging.Page');
    }
    if (pagingSize !== undefined && pagingSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingSize, 'Paging.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'Sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'Sorting.Property');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/customer/api/v1/master/records/special-payment`;
    return this.httpClient.request<SharedPageModelOfSpecialPaymentsRecordsSpecialPaymentRecordModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Resets the approval of an existing record by its unique id.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public resetRecords(
    requestParameters?: SpecialPaymentsCustomerServiceResetRecordsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SpecialPaymentsRecordsSpecialPaymentRecordModel>;
  public resetRecords(
    requestParameters?: SpecialPaymentsCustomerServiceResetRecordsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SpecialPaymentsRecordsSpecialPaymentRecordModel>>;
  public resetRecords(
    requestParameters?: SpecialPaymentsCustomerServiceResetRecordsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SpecialPaymentsRecordsSpecialPaymentRecordModel>>;
  public resetRecords(
    requestParameters?: SpecialPaymentsCustomerServiceResetRecordsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const recordId = requestParameters?.recordId;
    if (recordId === null || recordId === undefined) {
      throw new Error('Required parameter recordId was null or undefined when calling resetRecords.');
    }
    const specialPaymentsRecordsResetSpecialPaymentApprovalModel = requestParameters?.specialPaymentsRecordsResetSpecialPaymentApprovalModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/customer/api/v1/master/records/special-payment/${this.configuration.encodeParam({ name: 'recordId', value: recordId, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}/reset`;
    return this.httpClient.request<SpecialPaymentsRecordsSpecialPaymentRecordModel>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: specialPaymentsRecordsResetSpecialPaymentApprovalModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Updates an existing approval by its unique id.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateApprovals(
    requestParameters?: SpecialPaymentsCustomerServiceUpdateApprovalsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SpecialPaymentsApprovalsSpecialPaymentApprovalModel>;
  public updateApprovals(
    requestParameters?: SpecialPaymentsCustomerServiceUpdateApprovalsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SpecialPaymentsApprovalsSpecialPaymentApprovalModel>>;
  public updateApprovals(
    requestParameters?: SpecialPaymentsCustomerServiceUpdateApprovalsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SpecialPaymentsApprovalsSpecialPaymentApprovalModel>>;
  public updateApprovals(
    requestParameters?: SpecialPaymentsCustomerServiceUpdateApprovalsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateApprovals.');
    }
    const specialPaymentsApprovalsUpdateSpecialPaymentApprovalModel = requestParameters?.specialPaymentsApprovalsUpdateSpecialPaymentApprovalModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/customer/api/v1/master/records/special-payment/approvals/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<SpecialPaymentsApprovalsSpecialPaymentApprovalModel>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: specialPaymentsApprovalsUpdateSpecialPaymentApprovalModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * Updates an existing record by its unique id.
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateRecords(
    requestParameters?: SpecialPaymentsCustomerServiceUpdateRecordsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SpecialPaymentsRecordsSpecialPaymentRecordModel>;
  public updateRecords(
    requestParameters?: SpecialPaymentsCustomerServiceUpdateRecordsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SpecialPaymentsRecordsSpecialPaymentRecordModel>>;
  public updateRecords(
    requestParameters?: SpecialPaymentsCustomerServiceUpdateRecordsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SpecialPaymentsRecordsSpecialPaymentRecordModel>>;
  public updateRecords(
    requestParameters?: SpecialPaymentsCustomerServiceUpdateRecordsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling updateRecords.');
    }
    const specialPaymentsRecordsUpdateSpecialPaymentRecordModel = requestParameters?.specialPaymentsRecordsUpdateSpecialPaymentRecordModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/customer/api/v1/master/records/special-payment/${this.configuration.encodeParam({ name: 'id', value: id, in: 'path', style: 'simple', explode: false, dataType: 'string', dataFormat: 'uuid' })}`;
    return this.httpClient.request<SpecialPaymentsRecordsSpecialPaymentRecordModel>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: specialPaymentsRecordsUpdateSpecialPaymentRecordModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
}
