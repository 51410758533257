@if (item) {
  <div class="full-image-main">
    <div class="full-image-container">
      <div class="full-image-box">
        @if (!contentEdit) {
          <h4>{{ item.content.headline }}</h4>
        }
        @if (contentEdit) {
          <mat-form-field class="full-image-headline">
            <mat-label>{{ 'cms.content-pages.headline-text' | translate }}</mat-label>
            <input matInput aria-label="image headline" [(ngModel)]="item.content.headline" [placeholder]="'cms.content-pages.headline-text' | translate" type="text" />
          </mat-form-field>
        }
        <div class="full-image-enlarge">
          @if (enableEnlarge) {
            <div (click)="enlargeImage()" class="enlarge-icon">
              <span class="material-icons">fit_screen</span>
            </div>
          }
          <div><img aria-label="image" [src]="item.content.imageUrl" /></div>
        </div>
        @if (contentEdit) {
          <coin-v2-button class="full-image-change-picture" (click)="editPicture(item.content)" type="secondary" svgIcon="edit" text="Change Picture"></coin-v2-button>
        }
        @if (!contentEdit) {
          <p class="light">{{ item.content.text }}</p>
        }
        @if (contentEdit) {
          <mat-form-field class="full-image-headline">
            <mat-label>{{ 'cms.content-pages.headline-text' | translate }}</mat-label>
            <input matInput aria-label="image headline" [(ngModel)]="item.content.text" [placeholder]="'cms.content-pages.text' | translate" type="text" />
          </mat-form-field>
        }
      </div>
    </div>
  </div>
}
