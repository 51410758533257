import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { FirstVisitService } from '@coin/modules/auth/data-management';
import { StorageService } from '@coin/shared/data-access';
import { StorageKey } from '@coin/shared/util-enums';
import { GlobalEventsService } from '@coin/shared/util-helpers';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('animateEnterLeave', [
      transition(':enter', [style({ opacity: '0' }), animate('200ms ease-in', style({ opacity: '1' }))]),
      transition(':leave', [style({ opacity: '1' }), animate('200ms ease-out', style({ opacity: '0' }))])
    ]),
    trigger('showMenu', [
      state('inactive', style({ visibility: 'hidden' })),
      state('active', style({ visibility: 'visible' })),
      transition('active => inactive', [animate('400ms linear')])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class SidebarComponent implements OnInit {
  @Input() showSidebarOnStart = false;
  @Input() titles: string[];
  @Input() default = false;
  @Output() sidebarToggle = new EventEmitter<boolean>();
  @Output() selectItem = new EventEmitter<number>();

  @HostBinding('class.sidebar-visible')
  private get isVisibleClass() {
    return this.isSidebarVisible();
  }

  isSidebarVisible = signal(false);

  clickout = (event: MouseEvent): void => {
    if (!this.eRef.nativeElement.contains(event.target)) {
      if (this.isSidebarVisible()) {
        if (!this.firstVisit.status()) {
          this.onSidebarToggle();
        }
      }
    }
  };

  constructor(
    private eRef: ElementRef,
    private router: Router,
    private firstVisit: FirstVisitService,
    private storageService: StorageService,
    private events: GlobalEventsService,
    private destroyRef: DestroyRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.events
      .listen('click')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(event => this.clickout(event));

    let lsItem: 'true' | 'false';
    if (this.default) {
      lsItem = await this.storageService.getAsync(StorageKey.SIDEBAR_VISIBLE);
    }
    if (lsItem) {
      if (lsItem === 'false') {
        return;
      }
      if (lsItem === 'true') {
        setTimeout(() => {
          this.onSidebarToggle();
        });
      }
    } else if (this.showSidebarOnStart === true) {
      setTimeout(() => {
        this.onSidebarToggle();
      });
    }
  }

  async onSidebarToggle(index?: number): Promise<void> {
    if (index !== undefined) {
      this.selectItem.emit(index);
    }
    if (this.titles[index] === 'document-center') {
      this.router.navigate(['/document-center']);
    } else {
      this.isSidebarVisible.update(isVisible => !isVisible);
      if (this.default) {
        await this.storageService.setAsync(StorageKey.SIDEBAR_VISIBLE, this.isSidebarVisible().toString() as 'true' | 'false');
      }
      this.sidebarToggle.emit(this.isSidebarVisible());
    }
  }
}
