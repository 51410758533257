<coin-v2-dialog-frame [header]="data.mediaType === 'image' ? 'Select an Image' : 'Select a Video'">
  <div class="body-container">
    <div class="select-container">
      <p class="instruction-text">Either directly insert the {{ mediaName }}'s URL or select one of the shown {{ mediaName }}s.</p>
      <div class="inner-select-container">
        @if (!isLoading && !selectedMediaUrl) {
          <div class="image-details">
            <p class="no-selection-text">... No {{ mediaName }} selected ...</p>
          </div>
        }
        @if (!isLoading && selectedMediaUrl) {
          <div class="preview-image">
            @if (data.mediaType === 'image') {
              <img [src]="selectedMediaUrl" />
            } @else {
              <video [src]="selectedMediaUrl" controls></video>
            }

            <coin-gmm-spacer></coin-gmm-spacer>

            <div class="usage-preview-container">
              @if (cmsUsages) {
                <coin-cms-image-usages [usages]="cmsUsages" [isImageUsed]="isMediaUsed"></coin-cms-image-usages>
              }
              @if (isUsageLoading) {
                <div class="usage-spinner-container">
                  <mat-spinner></mat-spinner>
                </div>
              }
            </div>
          </div>
        }

        @if (isLoading) {
          <div class="loading-container">
            <mat-spinner></mat-spinner>
          </div>
        }

        <div class="select-or-search-thumbnail">
          <mat-form-field>
            <mat-label>{{ (data.mediaType === 'image' ? 'cms.image-url' : 'cms.video-url') | translate }}</mat-label>
            <input matInput aria-label="media url" [(ngModel)]="selectedMediaUrl" [placeholder]="'https://...'" [required]="true" type="test" />
          </mat-form-field>

          <div class="scrollable-thumbnail-container">
            @for (thumbnailUrl of thumbnailUrls; track thumbnailUrl) {
              <div>
                <div class="thumbnail-container">
                  <div (click)="selectMedia(thumbnailUrl)" class="thumbnail-img" [ngStyle]="{ background: 'url(' + thumbnailUrl + ')' }"></div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
  <coin-v2-dialog-footer color="white">
    <div class="image-actions">
      <coin-action-button (click)="fileInput.click()" class="secondary" svgIcon="upload" [noicon]="true">{{ 'general.btnUpload' | translate }}</coin-action-button>
      <coin-action-button
        (click)="calculateUsageClickHandler(selectedMediaUrl)"
        [disabled]="!this.selectedMediaUrl || this.isUsageAlreadyCalculated"
        class="secondary"
        svgIcon="list"
        [noicon]="true"
        >{{ 'cms.emails.calculate-usages' | translate }}</coin-action-button
      >
      <coin-action-button (click)="deleteMedia(selectedMediaUrl)" [disabled]="!this.selectedMediaUrl" class="secondary" svgIcon="delete" [noicon]="true">{{
        'general.btnDelete' | translate
      }}</coin-action-button>
    </div>
    <div class="dialog-actions">
      <input hidden type="file" #fileInput (change)="uploadMedia($event)" id="mediaInput" accept="*" />
      <coin-gmm-spacer></coin-gmm-spacer>
      <coin-action-button (click)="discard()" class="secondary" svgIcon="cancel" [noicon]="true">{{ 'general.btnClose' | translate }}</coin-action-button>
      <coin-action-button (click)="confirm()" [disabled]="!selectedMediaUrl" class="primary" svgIcon="save" [noicon]="true">{{
        'general.btnConfirm' | translate
      }}</coin-action-button>
    </div>
  </coin-v2-dialog-footer>
</coin-v2-dialog-frame>
