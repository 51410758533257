import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import { V2ButtonComponent, V2DialogModule } from '@coin/shared/ui-storybook';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

// TODO:
// To be standalone, requires standalone:
// - SimpleLangSwitchComponent
@Component({
  selector: 'app-equity-legal-disclaimer',
  templateUrl: './equity-legal-disclaimer.component.html',
  styleUrls: ['./equity-legal-disclaimer.component.scss'],
  standalone: false
})
export class EquityLegalDisclaimerComponent {
  getTranslation(translateString): string | SafeHtml {
    return this.translateService.instant(translateString);
  }

  constructor(
    private dialogRef: MatDialogRef<EquityLegalDisclaimerComponent>,
    private translateService: TranslateService
  ) {}

  static openDialog(dialog: MatDialog): MatDialogRef<EquityLegalDisclaimerComponent> {
    return dialog.open<EquityLegalDisclaimerComponent, undefined, boolean>(EquityLegalDisclaimerComponent, {
      maxWidth: '880px',
      width: '100%',
      minWidth: '375px',
      height: '80vh',
      disableClose: true
    });
  }

  confirm(): void {
    this.dialogRef.close(true);
  }

  close(): void {
    this.dialogRef.close();
  }
}
