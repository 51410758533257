/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UsersUsersUserModel } from './users-users-user-model';
import { RestructuringEmployeeSnapshotsRestructuringEmployeeSnapshotLightModel } from './restructuring-employee-snapshots-restructuring-employee-snapshot-light-model';
import { RestructuringEmployeesRestructuringEmployeeModel } from './restructuring-employees-restructuring-employee-model';
import { RestructuringRequestsRestructuringRequestResultModel } from './restructuring-requests-restructuring-request-result-model';
import { RestructuringRequestsRestructuringLetterModel } from './restructuring-requests-restructuring-letter-model';
import { RestructuringConfigurationRestructuringWorkflowSettingsLightModel } from './restructuring-configuration-restructuring-workflow-settings-light-model';

export interface RestructuringRequestsRestructuringRequestModel {
  id?: string;
  employeeSnapshotId?: string;
  employeeId?: string;
  seasonId?: string;
  seasonTitle?: string | null;
  planId?: string;
  planTitle?: string | null;
  employeeSnapshot?: RestructuringEmployeeSnapshotsRestructuringEmployeeSnapshotLightModel;
  createdBy?: RestructuringEmployeesRestructuringEmployeeModel;
  updatedBy?: UsersUsersUserModel;
  createdAt?: string | null;
  updatedAt?: string | null;
  state?: RestructuringRequestsRestructuringRequestModelStateEnum;
  fiscalYear?: string | null;
  isFrozen?: boolean;
  result?: Array<RestructuringRequestsRestructuringRequestResultModel> | null;
  reason?: string | null;
  subState?: string | null;
  possibleStates?: Array<RestructuringConfigurationRestructuringWorkflowSettingsLightModel> | null;
  country?: string | null;
  legalEntity?: string | null;
  isLetterCreated?: boolean | null;
  isCalculationLetterCreated?: boolean | null;
  letter?: RestructuringRequestsRestructuringLetterModel;
  calculationLetter?: RestructuringRequestsRestructuringLetterModel;
}
export enum RestructuringRequestsRestructuringRequestModelStateEnum {
  Draft = 'Draft',
  Monitoring = 'Monitoring',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Published = 'Published'
}
