import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { V2ButtonComponent, V2DialogModule } from '@coin/shared/ui-storybook';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'coin-guest-access-dialog',
  templateUrl: './guest-access-dialog.component.html',
  styleUrl: './guest-access-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIcon, V2DialogModule, TranslateModule, V2ButtonComponent]
})
export class GuestAccessDialogComponent {
  constructor(private dialogRef: MatDialogRef<GuestAccessDialogComponent>) {}

  static openDialog(dialog: MatDialog): MatDialogRef<GuestAccessDialogComponent> {
    return dialog.open(GuestAccessDialogComponent, {
      disableClose: true
    });
  }

  close(): void {
    this.dialogRef.close();
  }
}
