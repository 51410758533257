/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent, HttpParameterCodec, HttpContext } from '@angular/common/http';
import { Observable } from 'rxjs';

import {
  CommunicationsLetterActionsForwardLettersModel,
  CommunicationsLetterFinalizationAutoFinalizeLettersByQueryModel,
  CommunicationsLetterFinalizationAutoFinalizeLettersModel,
  CommunicationsLetterPublicationsPublishLetterBatchModel,
  CommunicationsLetterPublicationsUnpublishLetterBatchModel,
  CommunicationsLetterPublicationsUnpublishLetterListModel,
  SharedPageModelOfCommunicationsWorkflowTemplatesWorkflowTemplateModel,
  SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBatchModel,
  SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBulkModel,
  SiemensCOINClientApiCommunicationsCommandsLettersDownloadLettersModel,
  SiemensCOINClientApiCommunicationsCommandsLettersPublishLetterListModel,
  TransactionStatusOfLetterBatchMetadata
} from '@coin/shared/util-models';

import { CustomHttpParameterCodec } from '../encoder';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';

export interface CommunicationsPartnerServiceAutoFinalizeLettersByQueryLetterFinalizationRequestParams {
  id?: string;
  gid?: Array<string>;
  firstName?: Array<string>;
  lastName?: Array<string>;
  country?: Array<string>;
  are?: Array<string>;
  orgCode?: Array<string>;
  orgClusterNodeName?: Array<string>;
  state?: Array<'Active' | 'Draft' | 'Outdated' | 'Irrelevant'>;
  planName?: Array<string>;
  startDate?: string;
  endDate?: string;
  assignmentId?: string;
  letterType?:
    | 'Other'
    | 'AdherenceLetter'
    | 'TargetAgreementForm'
    | 'TargetAchievementForm'
    | 'MeritAllocation'
    | 'SpecialPaymentLetter'
    | 'RestructuringCalculation'
    | 'RestructuringLetter';
  processStates?: Array<
    'None' | 'Open' | 'TafCreated' | 'TafPublished' | 'ReadyForCalculation' | 'Calculated' | 'Frozen' | 'AchievementLetterCreated' | 'AchievementLetterPublished'
  >;
  recordCommunicationStates?: Array<'None' | 'Created' | 'InManagerReview' | 'InEmployeeReview' | 'Published' | 'ManagerRejected' | 'EmployeeRejected' | 'NoLetterRequired'>;
  workflow?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  template?: Array<string>;
  isDesignReady?: boolean;
  payoutDate?: string;
  payoutState?: Array<'StandardPayout' | 'FullPayout' | 'DefinedPayoutEndOfFiscalYear' | 'NoPayout' | 'Irrelevant'>;
  lineManagerName?: string;
  lineManagerGid?: Array<string>;
  seasonId?: string;
  workflowInstanceState?: Array<'None' | 'InManagerReview' | 'ManagerValidated' | 'ReviewPeriodClosed' | 'Transferred' | 'ManagerRejected' | 'InAllocation' | 'AllocationFinished'>;
  multiplier?: number;
  overallTargetAchievement?: number;
  payoutAmount?: number;
  payoutCurrency?: Array<string>;
  ippAchievementValue?: string;
  incentivePaymentType?: Array<string>;
  measurementDate?: string;
  meritTitle?: Array<string>;
  manuallyChanged?: Array<'None' | 'Activated' | 'Deactivated'>;
  newHire?: Array<'None' | 'Approved' | 'Reviewed' | 'ForReview' | 'Rejected'>;
  promotion?: Array<'None' | 'Requested' | 'InProgress' | 'Rejected' | 'Finalized'>;
  termination?: Array<'None' | 'ForReview' | 'Rejected' | 'Finalized'>;
  populationChange?: Array<'None' | 'MeritPopulation' | 'EquityPopulation'>;
  lineManagerFirstName?: Array<string>;
  lineManagerLastName?: Array<string>;
  companyManagerFirstName?: Array<string>;
  companyManagerGid?: Array<string>;
  companyManagerLastName?: Array<string>;
  templateName?: Array<string>;
  workflowType?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  autoApprove?: boolean;
  approvalRequired?: boolean;
  letterState?: Array<
    | 'None'
    | 'Created'
    | 'InManagerReview'
    | 'InEmployeeReview'
    | 'Published'
    | 'ManagerRejected'
    | 'EmployeeRejected'
    | 'NoTafRequired'
    | 'InBusinessPartnerReview'
    | 'BusinessPartnerRejected'
  >;
  processState?: Array<
    | 'None'
    | 'Created'
    | 'InManagerReview'
    | 'InEmployeeReview'
    | 'Published'
    | 'ManagerRejected'
    | 'EmployeeRejected'
    | 'NoTafRequired'
    | 'InBusinessPartnerReview'
    | 'BusinessPartnerRejected'
  >;
  exportState?: Array<'Open' | 'Done'>;
  payrollState?: Array<'Open' | 'Done'>;
  meritRelevancy?: Array<'Relevant' | 'Excluded' | 'NoIncrease'>;
  approvedByCompanyManager?: boolean;
  allocationWorkflowType?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  readyToPayroll?: boolean;
  planTitle?: Array<string>;
  localCurrency?: Array<string>;
  proposalAmount?: number;
  proposalDate?: string;
  specialPaymentReason?: Array<'Unknown' | 'SpecialProjectsInvolvement' | 'TemporaryEnlargedScope'>;
  templateLetterName?: Array<string>;
  communicationsLetterFinalizationAutoFinalizeLettersByQueryModel?: CommunicationsLetterFinalizationAutoFinalizeLettersByQueryModel;
}

export interface CommunicationsPartnerServiceAutoFinalizeLettersLetterFinalizationRequestParams {
  communicationsLetterFinalizationAutoFinalizeLettersModel?: CommunicationsLetterFinalizationAutoFinalizeLettersModel;
}

export interface CommunicationsPartnerServiceBatchDownloadByQueryLettersRequestParams {
  id?: string;
  gid?: Array<string>;
  firstName?: Array<string>;
  lastName?: Array<string>;
  country?: Array<string>;
  are?: Array<string>;
  orgCode?: Array<string>;
  orgClusterNodeName?: Array<string>;
  state?: Array<'Active' | 'Draft' | 'Outdated' | 'Irrelevant'>;
  planName?: Array<string>;
  startDate?: string;
  endDate?: string;
  assignmentId?: string;
  letterType?:
    | 'Other'
    | 'AdherenceLetter'
    | 'TargetAgreementForm'
    | 'TargetAchievementForm'
    | 'MeritAllocation'
    | 'SpecialPaymentLetter'
    | 'RestructuringCalculation'
    | 'RestructuringLetter';
  processStates?: Array<
    'None' | 'Open' | 'TafCreated' | 'TafPublished' | 'ReadyForCalculation' | 'Calculated' | 'Frozen' | 'AchievementLetterCreated' | 'AchievementLetterPublished'
  >;
  recordCommunicationStates?: Array<'None' | 'Created' | 'InManagerReview' | 'InEmployeeReview' | 'Published' | 'ManagerRejected' | 'EmployeeRejected' | 'NoLetterRequired'>;
  workflow?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  template?: Array<string>;
  isDesignReady?: boolean;
  payoutDate?: string;
  payoutState?: Array<'StandardPayout' | 'FullPayout' | 'DefinedPayoutEndOfFiscalYear' | 'NoPayout' | 'Irrelevant'>;
  lineManagerName?: string;
  lineManagerGid?: Array<string>;
  seasonId?: string;
  workflowInstanceState?: Array<'None' | 'InManagerReview' | 'ManagerValidated' | 'ReviewPeriodClosed' | 'Transferred' | 'ManagerRejected' | 'InAllocation' | 'AllocationFinished'>;
  multiplier?: number;
  overallTargetAchievement?: number;
  payoutAmount?: number;
  payoutCurrency?: Array<string>;
  ippAchievementValue?: string;
  incentivePaymentType?: Array<string>;
  measurementDate?: string;
  meritTitle?: Array<string>;
  manuallyChanged?: Array<'None' | 'Activated' | 'Deactivated'>;
  newHire?: Array<'None' | 'Approved' | 'Reviewed' | 'ForReview' | 'Rejected'>;
  promotion?: Array<'None' | 'Requested' | 'InProgress' | 'Rejected' | 'Finalized'>;
  termination?: Array<'None' | 'ForReview' | 'Rejected' | 'Finalized'>;
  populationChange?: Array<'None' | 'MeritPopulation' | 'EquityPopulation'>;
  lineManagerFirstName?: Array<string>;
  lineManagerLastName?: Array<string>;
  companyManagerFirstName?: Array<string>;
  companyManagerGid?: Array<string>;
  companyManagerLastName?: Array<string>;
  templateName?: Array<string>;
  workflowType?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  autoApprove?: boolean;
  approvalRequired?: boolean;
  letterState?: Array<
    | 'None'
    | 'Created'
    | 'InManagerReview'
    | 'InEmployeeReview'
    | 'Published'
    | 'ManagerRejected'
    | 'EmployeeRejected'
    | 'NoTafRequired'
    | 'InBusinessPartnerReview'
    | 'BusinessPartnerRejected'
  >;
  processState?: Array<
    | 'None'
    | 'Created'
    | 'InManagerReview'
    | 'InEmployeeReview'
    | 'Published'
    | 'ManagerRejected'
    | 'EmployeeRejected'
    | 'NoTafRequired'
    | 'InBusinessPartnerReview'
    | 'BusinessPartnerRejected'
  >;
  exportState?: Array<'Open' | 'Done'>;
  payrollState?: Array<'Open' | 'Done'>;
  meritRelevancy?: Array<'Relevant' | 'Excluded' | 'NoIncrease'>;
  approvedByCompanyManager?: boolean;
  allocationWorkflowType?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  readyToPayroll?: boolean;
  planTitle?: Array<string>;
  localCurrency?: Array<string>;
  proposalAmount?: number;
  proposalDate?: string;
  specialPaymentReason?: Array<'Unknown' | 'SpecialProjectsInvolvement' | 'TemporaryEnlargedScope'>;
  templateLetterName?: Array<string>;
  siemensCOINClientApiCommunicationsCommandsLettersDownloadLettersModel?: SiemensCOINClientApiCommunicationsCommandsLettersDownloadLettersModel;
}

export interface CommunicationsPartnerServiceBatchUnpublishLetterLetterPublicationsRequestParams {
  id?: string;
  gid?: Array<string>;
  firstName?: Array<string>;
  lastName?: Array<string>;
  country?: Array<string>;
  are?: Array<string>;
  orgCode?: Array<string>;
  orgClusterNodeName?: Array<string>;
  state?: Array<'Active' | 'Draft' | 'Outdated' | 'Irrelevant'>;
  planName?: Array<string>;
  startDate?: string;
  endDate?: string;
  assignmentId?: string;
  letterType?:
    | 'Other'
    | 'AdherenceLetter'
    | 'TargetAgreementForm'
    | 'TargetAchievementForm'
    | 'MeritAllocation'
    | 'SpecialPaymentLetter'
    | 'RestructuringCalculation'
    | 'RestructuringLetter';
  processStates?: Array<
    'None' | 'Open' | 'TafCreated' | 'TafPublished' | 'ReadyForCalculation' | 'Calculated' | 'Frozen' | 'AchievementLetterCreated' | 'AchievementLetterPublished'
  >;
  recordCommunicationStates?: Array<'None' | 'Created' | 'InManagerReview' | 'InEmployeeReview' | 'Published' | 'ManagerRejected' | 'EmployeeRejected' | 'NoLetterRequired'>;
  workflow?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  template?: Array<string>;
  isDesignReady?: boolean;
  payoutDate?: string;
  payoutState?: Array<'StandardPayout' | 'FullPayout' | 'DefinedPayoutEndOfFiscalYear' | 'NoPayout' | 'Irrelevant'>;
  lineManagerName?: string;
  lineManagerGid?: Array<string>;
  seasonId?: string;
  workflowInstanceState?: Array<'None' | 'InManagerReview' | 'ManagerValidated' | 'ReviewPeriodClosed' | 'Transferred' | 'ManagerRejected' | 'InAllocation' | 'AllocationFinished'>;
  multiplier?: number;
  overallTargetAchievement?: number;
  payoutAmount?: number;
  payoutCurrency?: Array<string>;
  ippAchievementValue?: string;
  incentivePaymentType?: Array<string>;
  measurementDate?: string;
  meritTitle?: Array<string>;
  manuallyChanged?: Array<'None' | 'Activated' | 'Deactivated'>;
  newHire?: Array<'None' | 'Approved' | 'Reviewed' | 'ForReview' | 'Rejected'>;
  promotion?: Array<'None' | 'Requested' | 'InProgress' | 'Rejected' | 'Finalized'>;
  termination?: Array<'None' | 'ForReview' | 'Rejected' | 'Finalized'>;
  populationChange?: Array<'None' | 'MeritPopulation' | 'EquityPopulation'>;
  lineManagerFirstName?: Array<string>;
  lineManagerLastName?: Array<string>;
  companyManagerFirstName?: Array<string>;
  companyManagerGid?: Array<string>;
  companyManagerLastName?: Array<string>;
  templateName?: Array<string>;
  workflowType?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  autoApprove?: boolean;
  approvalRequired?: boolean;
  letterState?: Array<
    | 'None'
    | 'Created'
    | 'InManagerReview'
    | 'InEmployeeReview'
    | 'Published'
    | 'ManagerRejected'
    | 'EmployeeRejected'
    | 'NoTafRequired'
    | 'InBusinessPartnerReview'
    | 'BusinessPartnerRejected'
  >;
  processState?: Array<
    | 'None'
    | 'Created'
    | 'InManagerReview'
    | 'InEmployeeReview'
    | 'Published'
    | 'ManagerRejected'
    | 'EmployeeRejected'
    | 'NoTafRequired'
    | 'InBusinessPartnerReview'
    | 'BusinessPartnerRejected'
  >;
  exportState?: Array<'Open' | 'Done'>;
  payrollState?: Array<'Open' | 'Done'>;
  meritRelevancy?: Array<'Relevant' | 'Excluded' | 'NoIncrease'>;
  approvedByCompanyManager?: boolean;
  allocationWorkflowType?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  readyToPayroll?: boolean;
  planTitle?: Array<string>;
  localCurrency?: Array<string>;
  proposalAmount?: number;
  proposalDate?: string;
  specialPaymentReason?: Array<'Unknown' | 'SpecialProjectsInvolvement' | 'TemporaryEnlargedScope'>;
  templateLetterName?: Array<string>;
  communicationsLetterPublicationsUnpublishLetterBatchModel?: CommunicationsLetterPublicationsUnpublishLetterBatchModel;
}

export interface CommunicationsPartnerServiceCreateLetterBatchLettersRequestParams {
  id?: string;
  gid?: Array<string>;
  firstName?: Array<string>;
  lastName?: Array<string>;
  country?: Array<string>;
  are?: Array<string>;
  orgCode?: Array<string>;
  orgClusterNodeName?: Array<string>;
  state?: Array<'Active' | 'Draft' | 'Outdated' | 'Irrelevant'>;
  planName?: Array<string>;
  startDate?: string;
  endDate?: string;
  assignmentId?: string;
  letterType?:
    | 'Other'
    | 'AdherenceLetter'
    | 'TargetAgreementForm'
    | 'TargetAchievementForm'
    | 'MeritAllocation'
    | 'SpecialPaymentLetter'
    | 'RestructuringCalculation'
    | 'RestructuringLetter';
  processStates?: Array<
    'None' | 'Open' | 'TafCreated' | 'TafPublished' | 'ReadyForCalculation' | 'Calculated' | 'Frozen' | 'AchievementLetterCreated' | 'AchievementLetterPublished'
  >;
  recordCommunicationStates?: Array<'None' | 'Created' | 'InManagerReview' | 'InEmployeeReview' | 'Published' | 'ManagerRejected' | 'EmployeeRejected' | 'NoLetterRequired'>;
  workflow?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  template?: Array<string>;
  isDesignReady?: boolean;
  payoutDate?: string;
  payoutState?: Array<'StandardPayout' | 'FullPayout' | 'DefinedPayoutEndOfFiscalYear' | 'NoPayout' | 'Irrelevant'>;
  lineManagerName?: string;
  lineManagerGid?: Array<string>;
  seasonId?: string;
  workflowInstanceState?: Array<'None' | 'InManagerReview' | 'ManagerValidated' | 'ReviewPeriodClosed' | 'Transferred' | 'ManagerRejected' | 'InAllocation' | 'AllocationFinished'>;
  multiplier?: number;
  overallTargetAchievement?: number;
  payoutAmount?: number;
  payoutCurrency?: Array<string>;
  ippAchievementValue?: string;
  incentivePaymentType?: Array<string>;
  measurementDate?: string;
  meritTitle?: Array<string>;
  manuallyChanged?: Array<'None' | 'Activated' | 'Deactivated'>;
  newHire?: Array<'None' | 'Approved' | 'Reviewed' | 'ForReview' | 'Rejected'>;
  promotion?: Array<'None' | 'Requested' | 'InProgress' | 'Rejected' | 'Finalized'>;
  termination?: Array<'None' | 'ForReview' | 'Rejected' | 'Finalized'>;
  populationChange?: Array<'None' | 'MeritPopulation' | 'EquityPopulation'>;
  lineManagerFirstName?: Array<string>;
  lineManagerLastName?: Array<string>;
  companyManagerFirstName?: Array<string>;
  companyManagerGid?: Array<string>;
  companyManagerLastName?: Array<string>;
  templateName?: Array<string>;
  workflowType?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  autoApprove?: boolean;
  approvalRequired?: boolean;
  letterState?: Array<
    | 'None'
    | 'Created'
    | 'InManagerReview'
    | 'InEmployeeReview'
    | 'Published'
    | 'ManagerRejected'
    | 'EmployeeRejected'
    | 'NoTafRequired'
    | 'InBusinessPartnerReview'
    | 'BusinessPartnerRejected'
  >;
  processState?: Array<
    | 'None'
    | 'Created'
    | 'InManagerReview'
    | 'InEmployeeReview'
    | 'Published'
    | 'ManagerRejected'
    | 'EmployeeRejected'
    | 'NoTafRequired'
    | 'InBusinessPartnerReview'
    | 'BusinessPartnerRejected'
  >;
  exportState?: Array<'Open' | 'Done'>;
  payrollState?: Array<'Open' | 'Done'>;
  meritRelevancy?: Array<'Relevant' | 'Excluded' | 'NoIncrease'>;
  approvedByCompanyManager?: boolean;
  allocationWorkflowType?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  readyToPayroll?: boolean;
  planTitle?: Array<string>;
  localCurrency?: Array<string>;
  proposalAmount?: number;
  proposalDate?: string;
  specialPaymentReason?: Array<'Unknown' | 'SpecialProjectsInvolvement' | 'TemporaryEnlargedScope'>;
  templateLetterName?: Array<string>;
  siemensCOINClientApiCommunicationsCommandsLettersCreateLetterBatchModel?: SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBatchModel;
}

export interface CommunicationsPartnerServiceCreateManyLettersRequestParams {
  siemensCOINClientApiCommunicationsCommandsLettersCreateLetterBulkModel?: SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBulkModel;
}

export interface CommunicationsPartnerServiceForwardLettersByQueryLetterActionsRequestParams {
  id?: string;
  gid?: Array<string>;
  firstName?: Array<string>;
  lastName?: Array<string>;
  country?: Array<string>;
  are?: Array<string>;
  orgCode?: Array<string>;
  orgClusterNodeName?: Array<string>;
  state?: Array<'Active' | 'Draft' | 'Outdated' | 'Irrelevant'>;
  planName?: Array<string>;
  startDate?: string;
  endDate?: string;
  assignmentId?: string;
  letterType?:
    | 'Other'
    | 'AdherenceLetter'
    | 'TargetAgreementForm'
    | 'TargetAchievementForm'
    | 'MeritAllocation'
    | 'SpecialPaymentLetter'
    | 'RestructuringCalculation'
    | 'RestructuringLetter';
  processStates?: Array<
    'None' | 'Open' | 'TafCreated' | 'TafPublished' | 'ReadyForCalculation' | 'Calculated' | 'Frozen' | 'AchievementLetterCreated' | 'AchievementLetterPublished'
  >;
  recordCommunicationStates?: Array<'None' | 'Created' | 'InManagerReview' | 'InEmployeeReview' | 'Published' | 'ManagerRejected' | 'EmployeeRejected' | 'NoLetterRequired'>;
  workflow?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  template?: Array<string>;
  isDesignReady?: boolean;
  payoutDate?: string;
  payoutState?: Array<'StandardPayout' | 'FullPayout' | 'DefinedPayoutEndOfFiscalYear' | 'NoPayout' | 'Irrelevant'>;
  lineManagerName?: string;
  lineManagerGid?: Array<string>;
  seasonId?: string;
  workflowInstanceState?: Array<'None' | 'InManagerReview' | 'ManagerValidated' | 'ReviewPeriodClosed' | 'Transferred' | 'ManagerRejected' | 'InAllocation' | 'AllocationFinished'>;
  multiplier?: number;
  overallTargetAchievement?: number;
  payoutAmount?: number;
  payoutCurrency?: Array<string>;
  ippAchievementValue?: string;
  incentivePaymentType?: Array<string>;
  measurementDate?: string;
  meritTitle?: Array<string>;
  manuallyChanged?: Array<'None' | 'Activated' | 'Deactivated'>;
  newHire?: Array<'None' | 'Approved' | 'Reviewed' | 'ForReview' | 'Rejected'>;
  promotion?: Array<'None' | 'Requested' | 'InProgress' | 'Rejected' | 'Finalized'>;
  termination?: Array<'None' | 'ForReview' | 'Rejected' | 'Finalized'>;
  populationChange?: Array<'None' | 'MeritPopulation' | 'EquityPopulation'>;
  lineManagerFirstName?: Array<string>;
  lineManagerLastName?: Array<string>;
  companyManagerFirstName?: Array<string>;
  companyManagerGid?: Array<string>;
  companyManagerLastName?: Array<string>;
  templateName?: Array<string>;
  workflowType?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  autoApprove?: boolean;
  approvalRequired?: boolean;
  letterState?: Array<
    | 'None'
    | 'Created'
    | 'InManagerReview'
    | 'InEmployeeReview'
    | 'Published'
    | 'ManagerRejected'
    | 'EmployeeRejected'
    | 'NoTafRequired'
    | 'InBusinessPartnerReview'
    | 'BusinessPartnerRejected'
  >;
  processState?: Array<
    | 'None'
    | 'Created'
    | 'InManagerReview'
    | 'InEmployeeReview'
    | 'Published'
    | 'ManagerRejected'
    | 'EmployeeRejected'
    | 'NoTafRequired'
    | 'InBusinessPartnerReview'
    | 'BusinessPartnerRejected'
  >;
  exportState?: Array<'Open' | 'Done'>;
  payrollState?: Array<'Open' | 'Done'>;
  meritRelevancy?: Array<'Relevant' | 'Excluded' | 'NoIncrease'>;
  approvedByCompanyManager?: boolean;
  allocationWorkflowType?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  readyToPayroll?: boolean;
  planTitle?: Array<string>;
  localCurrency?: Array<string>;
  proposalAmount?: number;
  proposalDate?: string;
  specialPaymentReason?: Array<'Unknown' | 'SpecialProjectsInvolvement' | 'TemporaryEnlargedScope'>;
  templateLetterName?: Array<string>;
  communicationsLetterActionsForwardLettersModel?: CommunicationsLetterActionsForwardLettersModel;
}

export interface CommunicationsPartnerServiceForwardLettersLetterActionsRequestParams {
  communicationsLetterActionsForwardLettersModel?: CommunicationsLetterActionsForwardLettersModel;
}

export interface CommunicationsPartnerServiceListUnpublishLetterLetterPublicationsRequestParams {
  communicationsLetterPublicationsUnpublishLetterListModel?: CommunicationsLetterPublicationsUnpublishLetterListModel;
}

export interface CommunicationsPartnerServicePublishLetterBatchLetterPublicationsRequestParams {
  id?: string;
  gid?: Array<string>;
  firstName?: Array<string>;
  lastName?: Array<string>;
  country?: Array<string>;
  are?: Array<string>;
  orgCode?: Array<string>;
  orgClusterNodeName?: Array<string>;
  state?: Array<'Active' | 'Draft' | 'Outdated' | 'Irrelevant'>;
  planName?: Array<string>;
  startDate?: string;
  endDate?: string;
  assignmentId?: string;
  letterType?:
    | 'Other'
    | 'AdherenceLetter'
    | 'TargetAgreementForm'
    | 'TargetAchievementForm'
    | 'MeritAllocation'
    | 'SpecialPaymentLetter'
    | 'RestructuringCalculation'
    | 'RestructuringLetter';
  processStates?: Array<
    'None' | 'Open' | 'TafCreated' | 'TafPublished' | 'ReadyForCalculation' | 'Calculated' | 'Frozen' | 'AchievementLetterCreated' | 'AchievementLetterPublished'
  >;
  recordCommunicationStates?: Array<'None' | 'Created' | 'InManagerReview' | 'InEmployeeReview' | 'Published' | 'ManagerRejected' | 'EmployeeRejected' | 'NoLetterRequired'>;
  workflow?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  template?: Array<string>;
  isDesignReady?: boolean;
  payoutDate?: string;
  payoutState?: Array<'StandardPayout' | 'FullPayout' | 'DefinedPayoutEndOfFiscalYear' | 'NoPayout' | 'Irrelevant'>;
  lineManagerName?: string;
  lineManagerGid?: Array<string>;
  seasonId?: string;
  workflowInstanceState?: Array<'None' | 'InManagerReview' | 'ManagerValidated' | 'ReviewPeriodClosed' | 'Transferred' | 'ManagerRejected' | 'InAllocation' | 'AllocationFinished'>;
  multiplier?: number;
  overallTargetAchievement?: number;
  payoutAmount?: number;
  payoutCurrency?: Array<string>;
  ippAchievementValue?: string;
  incentivePaymentType?: Array<string>;
  measurementDate?: string;
  meritTitle?: Array<string>;
  manuallyChanged?: Array<'None' | 'Activated' | 'Deactivated'>;
  newHire?: Array<'None' | 'Approved' | 'Reviewed' | 'ForReview' | 'Rejected'>;
  promotion?: Array<'None' | 'Requested' | 'InProgress' | 'Rejected' | 'Finalized'>;
  termination?: Array<'None' | 'ForReview' | 'Rejected' | 'Finalized'>;
  populationChange?: Array<'None' | 'MeritPopulation' | 'EquityPopulation'>;
  lineManagerFirstName?: Array<string>;
  lineManagerLastName?: Array<string>;
  companyManagerFirstName?: Array<string>;
  companyManagerGid?: Array<string>;
  companyManagerLastName?: Array<string>;
  templateName?: Array<string>;
  workflowType?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  autoApprove?: boolean;
  approvalRequired?: boolean;
  letterState?: Array<
    | 'None'
    | 'Created'
    | 'InManagerReview'
    | 'InEmployeeReview'
    | 'Published'
    | 'ManagerRejected'
    | 'EmployeeRejected'
    | 'NoTafRequired'
    | 'InBusinessPartnerReview'
    | 'BusinessPartnerRejected'
  >;
  processState?: Array<
    | 'None'
    | 'Created'
    | 'InManagerReview'
    | 'InEmployeeReview'
    | 'Published'
    | 'ManagerRejected'
    | 'EmployeeRejected'
    | 'NoTafRequired'
    | 'InBusinessPartnerReview'
    | 'BusinessPartnerRejected'
  >;
  exportState?: Array<'Open' | 'Done'>;
  payrollState?: Array<'Open' | 'Done'>;
  meritRelevancy?: Array<'Relevant' | 'Excluded' | 'NoIncrease'>;
  approvedByCompanyManager?: boolean;
  allocationWorkflowType?: Array<
    | 'EmployeeConfirmation'
    | 'EmployeeSignature'
    | 'ManagerConfirmationEmployeeConfirmation'
    | 'ManagerConfirmationEmployeeSignature'
    | 'ManagerSignatureEmployeeConfirmation'
    | 'ManagerSignatureEmployeeSignature'
    | 'ManagerConfirmationEmployeeNoActionNeeded'
    | 'NoActionNeeded'
    | 'AllocationByInCompanyManagerInvisibleForLineManager'
    | 'AllocationByInCompanyManagerVisibleForLineManager'
    | 'AllocationByLineManagerApprovalByInCompanyManager'
    | 'AllocationByLineManagerVisibleForInCompanyManager'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeSignature'
    | 'BusinessPartnerConfirmationManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerConfirmationManagerSignatureEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeSignature'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeConfirmation'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeSignature'
    | 'BusinessPartnerSignatureManagerConfirmationEmployeeNoActionNeeded'
    | 'BusinessPartnerSignatureManagerSignatureEmployeeNoActionNeeded'
  >;
  readyToPayroll?: boolean;
  planTitle?: Array<string>;
  localCurrency?: Array<string>;
  proposalAmount?: number;
  proposalDate?: string;
  specialPaymentReason?: Array<'Unknown' | 'SpecialProjectsInvolvement' | 'TemporaryEnlargedScope'>;
  templateLetterName?: Array<string>;
  communicationsLetterPublicationsPublishLetterBatchModel?: CommunicationsLetterPublicationsPublishLetterBatchModel;
}

export interface CommunicationsPartnerServicePublishLetterListLetterPublicationsRequestParams {
  siemensCOINClientApiCommunicationsCommandsLettersPublishLetterListModel?: SiemensCOINClientApiCommunicationsCommandsLettersPublishLetterListModel;
}

export interface CommunicationsPartnerServiceQueryLetterTemplatesRequestParams {
  queryTitle?: string;
  queryDescription?: string;
  queryLetterType?: Array<
    'Other' | 'AdherenceLetter' | 'TargetAgreementForm' | 'TargetAchievementForm' | 'MeritAllocation' | 'SpecialPaymentLetter' | 'RestructuringCalculation' | 'RestructuringLetter'
  >;
  queryCreatedBy?: string;
  queryUpdatedBy?: string;
  queryCreatedAt?: string;
  queryUpdatedAt?: string;
  queryCreatedAtFrom?: string;
  queryCreatedAtTo?: string;
  queryUpdatedAtFrom?: string;
  queryUpdatedAtTo?: string;
  pagingPage?: number;
  pagingSize?: number;
  sortingOrderBy?: 'Asc' | 'Desc';
  sortingProperty?: string;
}

@Injectable({
  providedIn: 'root'
})
export class CommunicationsPartnerService {
  protected basePath = 'http://localhost';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string | string[],
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
    }
    if (typeof this.configuration.basePath !== 'string') {
      const firstBasePath = Array.isArray(basePath) ? basePath[0] : undefined;
      if (firstBasePath != undefined) {
        basePath = firstBasePath;
      }

      if (typeof basePath !== 'string') {
        basePath = this.basePath;
      }
      this.configuration.basePath = basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  // @ts-ignore
  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === 'object' && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => (httpParams = this.addToHttpParamsRecursive(httpParams, elem, key)));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error('key may not be null if value is Date');
        }
      } else {
        Object.keys(value).forEach(k => (httpParams = this.addToHttpParamsRecursive(httpParams, value[k], key != null ? `${key}.${k}` : k)));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error('key may not be null if value is not object or array');
    }
    return httpParams;
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public autoFinalizeLettersByQueryLetterFinalization(
    requestParameters?: CommunicationsPartnerServiceAutoFinalizeLettersByQueryLetterFinalizationRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<TransactionStatusOfLetterBatchMetadata>;
  public autoFinalizeLettersByQueryLetterFinalization(
    requestParameters?: CommunicationsPartnerServiceAutoFinalizeLettersByQueryLetterFinalizationRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<TransactionStatusOfLetterBatchMetadata>>;
  public autoFinalizeLettersByQueryLetterFinalization(
    requestParameters?: CommunicationsPartnerServiceAutoFinalizeLettersByQueryLetterFinalizationRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<TransactionStatusOfLetterBatchMetadata>>;
  public autoFinalizeLettersByQueryLetterFinalization(
    requestParameters?: CommunicationsPartnerServiceAutoFinalizeLettersByQueryLetterFinalizationRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    const gid = requestParameters?.gid;
    const firstName = requestParameters?.firstName;
    const lastName = requestParameters?.lastName;
    const country = requestParameters?.country;
    const are = requestParameters?.are;
    const orgCode = requestParameters?.orgCode;
    const orgClusterNodeName = requestParameters?.orgClusterNodeName;
    const state = requestParameters?.state;
    const planName = requestParameters?.planName;
    const startDate = requestParameters?.startDate;
    const endDate = requestParameters?.endDate;
    const assignmentId = requestParameters?.assignmentId;
    const letterType = requestParameters?.letterType;
    const processStates = requestParameters?.processStates;
    const recordCommunicationStates = requestParameters?.recordCommunicationStates;
    const workflow = requestParameters?.workflow;
    const template = requestParameters?.template;
    const isDesignReady = requestParameters?.isDesignReady;
    const payoutDate = requestParameters?.payoutDate;
    const payoutState = requestParameters?.payoutState;
    const lineManagerName = requestParameters?.lineManagerName;
    const lineManagerGid = requestParameters?.lineManagerGid;
    const seasonId = requestParameters?.seasonId;
    const workflowInstanceState = requestParameters?.workflowInstanceState;
    const multiplier = requestParameters?.multiplier;
    const overallTargetAchievement = requestParameters?.overallTargetAchievement;
    const payoutAmount = requestParameters?.payoutAmount;
    const payoutCurrency = requestParameters?.payoutCurrency;
    const ippAchievementValue = requestParameters?.ippAchievementValue;
    const incentivePaymentType = requestParameters?.incentivePaymentType;
    const measurementDate = requestParameters?.measurementDate;
    const meritTitle = requestParameters?.meritTitle;
    const manuallyChanged = requestParameters?.manuallyChanged;
    const newHire = requestParameters?.newHire;
    const promotion = requestParameters?.promotion;
    const termination = requestParameters?.termination;
    const populationChange = requestParameters?.populationChange;
    const lineManagerFirstName = requestParameters?.lineManagerFirstName;
    const lineManagerLastName = requestParameters?.lineManagerLastName;
    const companyManagerFirstName = requestParameters?.companyManagerFirstName;
    const companyManagerGid = requestParameters?.companyManagerGid;
    const companyManagerLastName = requestParameters?.companyManagerLastName;
    const templateName = requestParameters?.templateName;
    const workflowType = requestParameters?.workflowType;
    const autoApprove = requestParameters?.autoApprove;
    const approvalRequired = requestParameters?.approvalRequired;
    const letterState = requestParameters?.letterState;
    const processState = requestParameters?.processState;
    const exportState = requestParameters?.exportState;
    const payrollState = requestParameters?.payrollState;
    const meritRelevancy = requestParameters?.meritRelevancy;
    const approvedByCompanyManager = requestParameters?.approvedByCompanyManager;
    const allocationWorkflowType = requestParameters?.allocationWorkflowType;
    const readyToPayroll = requestParameters?.readyToPayroll;
    const planTitle = requestParameters?.planTitle;
    const localCurrency = requestParameters?.localCurrency;
    const proposalAmount = requestParameters?.proposalAmount;
    const proposalDate = requestParameters?.proposalDate;
    const specialPaymentReason = requestParameters?.specialPaymentReason;
    const templateLetterName = requestParameters?.templateLetterName;
    const communicationsLetterFinalizationAutoFinalizeLettersByQueryModel = requestParameters?.communicationsLetterFinalizationAutoFinalizeLettersByQueryModel;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (id !== undefined && id !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>id, 'Id');
    }
    if (gid) {
      gid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Gid');
      });
    }
    if (firstName) {
      firstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'FirstName');
      });
    }
    if (lastName) {
      lastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LastName');
      });
    }
    if (country) {
      country.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Country');
      });
    }
    if (are) {
      are.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Are');
      });
    }
    if (orgCode) {
      orgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'OrgCode');
      });
    }
    if (orgClusterNodeName) {
      orgClusterNodeName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'OrgClusterNodeName');
      });
    }
    if (state) {
      state.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'State');
      });
    }
    if (planName) {
      planName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PlanName');
      });
    }
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }
    if (assignmentId !== undefined && assignmentId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>assignmentId, 'AssignmentId');
    }
    if (letterType !== undefined && letterType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>letterType, 'LetterType');
    }
    if (processStates) {
      processStates.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ProcessStates');
      });
    }
    if (recordCommunicationStates) {
      recordCommunicationStates.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'RecordCommunicationStates');
      });
    }
    if (workflow) {
      workflow.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Workflow');
      });
    }
    if (template) {
      template.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Template');
      });
    }
    if (isDesignReady !== undefined && isDesignReady !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>isDesignReady, 'IsDesignReady');
    }
    if (payoutDate !== undefined && payoutDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>payoutDate, 'PayoutDate');
    }
    if (payoutState) {
      payoutState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayoutState');
      });
    }
    if (lineManagerName !== undefined && lineManagerName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>lineManagerName, 'LineManagerName');
    }
    if (lineManagerGid) {
      lineManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerGid');
      });
    }
    if (seasonId !== undefined && seasonId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>seasonId, 'SeasonId');
    }
    if (workflowInstanceState) {
      workflowInstanceState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'WorkflowInstanceState');
      });
    }
    if (multiplier !== undefined && multiplier !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>multiplier, 'Multiplier');
    }
    if (overallTargetAchievement !== undefined && overallTargetAchievement !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>overallTargetAchievement, 'OverallTargetAchievement');
    }
    if (payoutAmount !== undefined && payoutAmount !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>payoutAmount, 'PayoutAmount');
    }
    if (payoutCurrency) {
      payoutCurrency.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayoutCurrency');
      });
    }
    if (ippAchievementValue !== undefined && ippAchievementValue !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>ippAchievementValue, 'IppAchievementValue');
    }
    if (incentivePaymentType) {
      incentivePaymentType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'IncentivePaymentType');
      });
    }
    if (measurementDate !== undefined && measurementDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>measurementDate, 'MeasurementDate');
    }
    if (meritTitle) {
      meritTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'MeritTitle');
      });
    }
    if (manuallyChanged) {
      manuallyChanged.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ManuallyChanged');
      });
    }
    if (newHire) {
      newHire.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'NewHire');
      });
    }
    if (promotion) {
      promotion.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Promotion');
      });
    }
    if (termination) {
      termination.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Termination');
      });
    }
    if (populationChange) {
      populationChange.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PopulationChange');
      });
    }
    if (lineManagerFirstName) {
      lineManagerFirstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerFirstName');
      });
    }
    if (lineManagerLastName) {
      lineManagerLastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerLastName');
      });
    }
    if (companyManagerFirstName) {
      companyManagerFirstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerFirstName');
      });
    }
    if (companyManagerGid) {
      companyManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerGid');
      });
    }
    if (companyManagerLastName) {
      companyManagerLastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerLastName');
      });
    }
    if (templateName) {
      templateName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'TemplateName');
      });
    }
    if (workflowType) {
      workflowType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'WorkflowType');
      });
    }
    if (autoApprove !== undefined && autoApprove !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>autoApprove, 'AutoApprove');
    }
    if (approvalRequired !== undefined && approvalRequired !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>approvalRequired, 'ApprovalRequired');
    }
    if (letterState) {
      letterState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LetterState');
      });
    }
    if (processState) {
      processState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ProcessState');
      });
    }
    if (exportState) {
      exportState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ExportState');
      });
    }
    if (payrollState) {
      payrollState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayrollState');
      });
    }
    if (meritRelevancy) {
      meritRelevancy.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'MeritRelevancy');
      });
    }
    if (approvedByCompanyManager !== undefined && approvedByCompanyManager !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>approvedByCompanyManager, 'ApprovedByCompanyManager');
    }
    if (allocationWorkflowType) {
      allocationWorkflowType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'AllocationWorkflowType');
      });
    }
    if (readyToPayroll !== undefined && readyToPayroll !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>readyToPayroll, 'ReadyToPayroll');
    }
    if (planTitle) {
      planTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PlanTitle');
      });
    }
    if (localCurrency) {
      localCurrency.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LocalCurrency');
      });
    }
    if (proposalAmount !== undefined && proposalAmount !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>proposalAmount, 'ProposalAmount');
    }
    if (proposalDate !== undefined && proposalDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>proposalDate, 'ProposalDate');
    }
    if (specialPaymentReason) {
      specialPaymentReason.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'SpecialPaymentReason');
      });
    }
    if (templateLetterName) {
      templateLetterName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'TemplateLetterName');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/partner/api/v1/master/letters/batch/finalization`;
    return this.httpClient.request<TransactionStatusOfLetterBatchMetadata>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: communicationsLetterFinalizationAutoFinalizeLettersByQueryModel,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public autoFinalizeLettersLetterFinalization(
    requestParameters?: CommunicationsPartnerServiceAutoFinalizeLettersLetterFinalizationRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<TransactionStatusOfLetterBatchMetadata>;
  public autoFinalizeLettersLetterFinalization(
    requestParameters?: CommunicationsPartnerServiceAutoFinalizeLettersLetterFinalizationRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<TransactionStatusOfLetterBatchMetadata>>;
  public autoFinalizeLettersLetterFinalization(
    requestParameters?: CommunicationsPartnerServiceAutoFinalizeLettersLetterFinalizationRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<TransactionStatusOfLetterBatchMetadata>>;
  public autoFinalizeLettersLetterFinalization(
    requestParameters?: CommunicationsPartnerServiceAutoFinalizeLettersLetterFinalizationRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const communicationsLetterFinalizationAutoFinalizeLettersModel = requestParameters?.communicationsLetterFinalizationAutoFinalizeLettersModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/partner/api/v1/master/letters/list/finalization`;
    return this.httpClient.request<TransactionStatusOfLetterBatchMetadata>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: communicationsLetterFinalizationAutoFinalizeLettersModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public batchDownloadByQueryLetters(
    requestParameters?: CommunicationsPartnerServiceBatchDownloadByQueryLettersRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<TransactionStatusOfLetterBatchMetadata>;
  public batchDownloadByQueryLetters(
    requestParameters?: CommunicationsPartnerServiceBatchDownloadByQueryLettersRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<TransactionStatusOfLetterBatchMetadata>>;
  public batchDownloadByQueryLetters(
    requestParameters?: CommunicationsPartnerServiceBatchDownloadByQueryLettersRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<TransactionStatusOfLetterBatchMetadata>>;
  public batchDownloadByQueryLetters(
    requestParameters?: CommunicationsPartnerServiceBatchDownloadByQueryLettersRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    const gid = requestParameters?.gid;
    const firstName = requestParameters?.firstName;
    const lastName = requestParameters?.lastName;
    const country = requestParameters?.country;
    const are = requestParameters?.are;
    const orgCode = requestParameters?.orgCode;
    const orgClusterNodeName = requestParameters?.orgClusterNodeName;
    const state = requestParameters?.state;
    const planName = requestParameters?.planName;
    const startDate = requestParameters?.startDate;
    const endDate = requestParameters?.endDate;
    const assignmentId = requestParameters?.assignmentId;
    const letterType = requestParameters?.letterType;
    const processStates = requestParameters?.processStates;
    const recordCommunicationStates = requestParameters?.recordCommunicationStates;
    const workflow = requestParameters?.workflow;
    const template = requestParameters?.template;
    const isDesignReady = requestParameters?.isDesignReady;
    const payoutDate = requestParameters?.payoutDate;
    const payoutState = requestParameters?.payoutState;
    const lineManagerName = requestParameters?.lineManagerName;
    const lineManagerGid = requestParameters?.lineManagerGid;
    const seasonId = requestParameters?.seasonId;
    const workflowInstanceState = requestParameters?.workflowInstanceState;
    const multiplier = requestParameters?.multiplier;
    const overallTargetAchievement = requestParameters?.overallTargetAchievement;
    const payoutAmount = requestParameters?.payoutAmount;
    const payoutCurrency = requestParameters?.payoutCurrency;
    const ippAchievementValue = requestParameters?.ippAchievementValue;
    const incentivePaymentType = requestParameters?.incentivePaymentType;
    const measurementDate = requestParameters?.measurementDate;
    const meritTitle = requestParameters?.meritTitle;
    const manuallyChanged = requestParameters?.manuallyChanged;
    const newHire = requestParameters?.newHire;
    const promotion = requestParameters?.promotion;
    const termination = requestParameters?.termination;
    const populationChange = requestParameters?.populationChange;
    const lineManagerFirstName = requestParameters?.lineManagerFirstName;
    const lineManagerLastName = requestParameters?.lineManagerLastName;
    const companyManagerFirstName = requestParameters?.companyManagerFirstName;
    const companyManagerGid = requestParameters?.companyManagerGid;
    const companyManagerLastName = requestParameters?.companyManagerLastName;
    const templateName = requestParameters?.templateName;
    const workflowType = requestParameters?.workflowType;
    const autoApprove = requestParameters?.autoApprove;
    const approvalRequired = requestParameters?.approvalRequired;
    const letterState = requestParameters?.letterState;
    const processState = requestParameters?.processState;
    const exportState = requestParameters?.exportState;
    const payrollState = requestParameters?.payrollState;
    const meritRelevancy = requestParameters?.meritRelevancy;
    const approvedByCompanyManager = requestParameters?.approvedByCompanyManager;
    const allocationWorkflowType = requestParameters?.allocationWorkflowType;
    const readyToPayroll = requestParameters?.readyToPayroll;
    const planTitle = requestParameters?.planTitle;
    const localCurrency = requestParameters?.localCurrency;
    const proposalAmount = requestParameters?.proposalAmount;
    const proposalDate = requestParameters?.proposalDate;
    const specialPaymentReason = requestParameters?.specialPaymentReason;
    const templateLetterName = requestParameters?.templateLetterName;
    const siemensCOINClientApiCommunicationsCommandsLettersDownloadLettersModel = requestParameters?.siemensCOINClientApiCommunicationsCommandsLettersDownloadLettersModel;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (id !== undefined && id !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>id, 'Id');
    }
    if (gid) {
      gid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Gid');
      });
    }
    if (firstName) {
      firstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'FirstName');
      });
    }
    if (lastName) {
      lastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LastName');
      });
    }
    if (country) {
      country.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Country');
      });
    }
    if (are) {
      are.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Are');
      });
    }
    if (orgCode) {
      orgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'OrgCode');
      });
    }
    if (orgClusterNodeName) {
      orgClusterNodeName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'OrgClusterNodeName');
      });
    }
    if (state) {
      state.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'State');
      });
    }
    if (planName) {
      planName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PlanName');
      });
    }
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }
    if (assignmentId !== undefined && assignmentId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>assignmentId, 'AssignmentId');
    }
    if (letterType !== undefined && letterType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>letterType, 'LetterType');
    }
    if (processStates) {
      processStates.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ProcessStates');
      });
    }
    if (recordCommunicationStates) {
      recordCommunicationStates.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'RecordCommunicationStates');
      });
    }
    if (workflow) {
      workflow.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Workflow');
      });
    }
    if (template) {
      template.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Template');
      });
    }
    if (isDesignReady !== undefined && isDesignReady !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>isDesignReady, 'IsDesignReady');
    }
    if (payoutDate !== undefined && payoutDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>payoutDate, 'PayoutDate');
    }
    if (payoutState) {
      payoutState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayoutState');
      });
    }
    if (lineManagerName !== undefined && lineManagerName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>lineManagerName, 'LineManagerName');
    }
    if (lineManagerGid) {
      lineManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerGid');
      });
    }
    if (seasonId !== undefined && seasonId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>seasonId, 'SeasonId');
    }
    if (workflowInstanceState) {
      workflowInstanceState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'WorkflowInstanceState');
      });
    }
    if (multiplier !== undefined && multiplier !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>multiplier, 'Multiplier');
    }
    if (overallTargetAchievement !== undefined && overallTargetAchievement !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>overallTargetAchievement, 'OverallTargetAchievement');
    }
    if (payoutAmount !== undefined && payoutAmount !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>payoutAmount, 'PayoutAmount');
    }
    if (payoutCurrency) {
      payoutCurrency.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayoutCurrency');
      });
    }
    if (ippAchievementValue !== undefined && ippAchievementValue !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>ippAchievementValue, 'IppAchievementValue');
    }
    if (incentivePaymentType) {
      incentivePaymentType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'IncentivePaymentType');
      });
    }
    if (measurementDate !== undefined && measurementDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>measurementDate, 'MeasurementDate');
    }
    if (meritTitle) {
      meritTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'MeritTitle');
      });
    }
    if (manuallyChanged) {
      manuallyChanged.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ManuallyChanged');
      });
    }
    if (newHire) {
      newHire.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'NewHire');
      });
    }
    if (promotion) {
      promotion.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Promotion');
      });
    }
    if (termination) {
      termination.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Termination');
      });
    }
    if (populationChange) {
      populationChange.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PopulationChange');
      });
    }
    if (lineManagerFirstName) {
      lineManagerFirstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerFirstName');
      });
    }
    if (lineManagerLastName) {
      lineManagerLastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerLastName');
      });
    }
    if (companyManagerFirstName) {
      companyManagerFirstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerFirstName');
      });
    }
    if (companyManagerGid) {
      companyManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerGid');
      });
    }
    if (companyManagerLastName) {
      companyManagerLastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerLastName');
      });
    }
    if (templateName) {
      templateName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'TemplateName');
      });
    }
    if (workflowType) {
      workflowType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'WorkflowType');
      });
    }
    if (autoApprove !== undefined && autoApprove !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>autoApprove, 'AutoApprove');
    }
    if (approvalRequired !== undefined && approvalRequired !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>approvalRequired, 'ApprovalRequired');
    }
    if (letterState) {
      letterState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LetterState');
      });
    }
    if (processState) {
      processState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ProcessState');
      });
    }
    if (exportState) {
      exportState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ExportState');
      });
    }
    if (payrollState) {
      payrollState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayrollState');
      });
    }
    if (meritRelevancy) {
      meritRelevancy.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'MeritRelevancy');
      });
    }
    if (approvedByCompanyManager !== undefined && approvedByCompanyManager !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>approvedByCompanyManager, 'ApprovedByCompanyManager');
    }
    if (allocationWorkflowType) {
      allocationWorkflowType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'AllocationWorkflowType');
      });
    }
    if (readyToPayroll !== undefined && readyToPayroll !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>readyToPayroll, 'ReadyToPayroll');
    }
    if (planTitle) {
      planTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PlanTitle');
      });
    }
    if (localCurrency) {
      localCurrency.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LocalCurrency');
      });
    }
    if (proposalAmount !== undefined && proposalAmount !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>proposalAmount, 'ProposalAmount');
    }
    if (proposalDate !== undefined && proposalDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>proposalDate, 'ProposalDate');
    }
    if (specialPaymentReason) {
      specialPaymentReason.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'SpecialPaymentReason');
      });
    }
    if (templateLetterName) {
      templateLetterName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'TemplateLetterName');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/partner/api/v1/master/letters/batch/download`;
    return this.httpClient.request<TransactionStatusOfLetterBatchMetadata>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: siemensCOINClientApiCommunicationsCommandsLettersDownloadLettersModel,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public batchUnpublishLetterLetterPublications(
    requestParameters?: CommunicationsPartnerServiceBatchUnpublishLetterLetterPublicationsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<TransactionStatusOfLetterBatchMetadata>;
  public batchUnpublishLetterLetterPublications(
    requestParameters?: CommunicationsPartnerServiceBatchUnpublishLetterLetterPublicationsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<TransactionStatusOfLetterBatchMetadata>>;
  public batchUnpublishLetterLetterPublications(
    requestParameters?: CommunicationsPartnerServiceBatchUnpublishLetterLetterPublicationsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<TransactionStatusOfLetterBatchMetadata>>;
  public batchUnpublishLetterLetterPublications(
    requestParameters?: CommunicationsPartnerServiceBatchUnpublishLetterLetterPublicationsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    const gid = requestParameters?.gid;
    const firstName = requestParameters?.firstName;
    const lastName = requestParameters?.lastName;
    const country = requestParameters?.country;
    const are = requestParameters?.are;
    const orgCode = requestParameters?.orgCode;
    const orgClusterNodeName = requestParameters?.orgClusterNodeName;
    const state = requestParameters?.state;
    const planName = requestParameters?.planName;
    const startDate = requestParameters?.startDate;
    const endDate = requestParameters?.endDate;
    const assignmentId = requestParameters?.assignmentId;
    const letterType = requestParameters?.letterType;
    const processStates = requestParameters?.processStates;
    const recordCommunicationStates = requestParameters?.recordCommunicationStates;
    const workflow = requestParameters?.workflow;
    const template = requestParameters?.template;
    const isDesignReady = requestParameters?.isDesignReady;
    const payoutDate = requestParameters?.payoutDate;
    const payoutState = requestParameters?.payoutState;
    const lineManagerName = requestParameters?.lineManagerName;
    const lineManagerGid = requestParameters?.lineManagerGid;
    const seasonId = requestParameters?.seasonId;
    const workflowInstanceState = requestParameters?.workflowInstanceState;
    const multiplier = requestParameters?.multiplier;
    const overallTargetAchievement = requestParameters?.overallTargetAchievement;
    const payoutAmount = requestParameters?.payoutAmount;
    const payoutCurrency = requestParameters?.payoutCurrency;
    const ippAchievementValue = requestParameters?.ippAchievementValue;
    const incentivePaymentType = requestParameters?.incentivePaymentType;
    const measurementDate = requestParameters?.measurementDate;
    const meritTitle = requestParameters?.meritTitle;
    const manuallyChanged = requestParameters?.manuallyChanged;
    const newHire = requestParameters?.newHire;
    const promotion = requestParameters?.promotion;
    const termination = requestParameters?.termination;
    const populationChange = requestParameters?.populationChange;
    const lineManagerFirstName = requestParameters?.lineManagerFirstName;
    const lineManagerLastName = requestParameters?.lineManagerLastName;
    const companyManagerFirstName = requestParameters?.companyManagerFirstName;
    const companyManagerGid = requestParameters?.companyManagerGid;
    const companyManagerLastName = requestParameters?.companyManagerLastName;
    const templateName = requestParameters?.templateName;
    const workflowType = requestParameters?.workflowType;
    const autoApprove = requestParameters?.autoApprove;
    const approvalRequired = requestParameters?.approvalRequired;
    const letterState = requestParameters?.letterState;
    const processState = requestParameters?.processState;
    const exportState = requestParameters?.exportState;
    const payrollState = requestParameters?.payrollState;
    const meritRelevancy = requestParameters?.meritRelevancy;
    const approvedByCompanyManager = requestParameters?.approvedByCompanyManager;
    const allocationWorkflowType = requestParameters?.allocationWorkflowType;
    const readyToPayroll = requestParameters?.readyToPayroll;
    const planTitle = requestParameters?.planTitle;
    const localCurrency = requestParameters?.localCurrency;
    const proposalAmount = requestParameters?.proposalAmount;
    const proposalDate = requestParameters?.proposalDate;
    const specialPaymentReason = requestParameters?.specialPaymentReason;
    const templateLetterName = requestParameters?.templateLetterName;
    const communicationsLetterPublicationsUnpublishLetterBatchModel = requestParameters?.communicationsLetterPublicationsUnpublishLetterBatchModel;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (id !== undefined && id !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>id, 'Id');
    }
    if (gid) {
      gid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Gid');
      });
    }
    if (firstName) {
      firstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'FirstName');
      });
    }
    if (lastName) {
      lastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LastName');
      });
    }
    if (country) {
      country.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Country');
      });
    }
    if (are) {
      are.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Are');
      });
    }
    if (orgCode) {
      orgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'OrgCode');
      });
    }
    if (orgClusterNodeName) {
      orgClusterNodeName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'OrgClusterNodeName');
      });
    }
    if (state) {
      state.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'State');
      });
    }
    if (planName) {
      planName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PlanName');
      });
    }
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }
    if (assignmentId !== undefined && assignmentId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>assignmentId, 'AssignmentId');
    }
    if (letterType !== undefined && letterType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>letterType, 'LetterType');
    }
    if (processStates) {
      processStates.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ProcessStates');
      });
    }
    if (recordCommunicationStates) {
      recordCommunicationStates.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'RecordCommunicationStates');
      });
    }
    if (workflow) {
      workflow.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Workflow');
      });
    }
    if (template) {
      template.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Template');
      });
    }
    if (isDesignReady !== undefined && isDesignReady !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>isDesignReady, 'IsDesignReady');
    }
    if (payoutDate !== undefined && payoutDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>payoutDate, 'PayoutDate');
    }
    if (payoutState) {
      payoutState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayoutState');
      });
    }
    if (lineManagerName !== undefined && lineManagerName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>lineManagerName, 'LineManagerName');
    }
    if (lineManagerGid) {
      lineManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerGid');
      });
    }
    if (seasonId !== undefined && seasonId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>seasonId, 'SeasonId');
    }
    if (workflowInstanceState) {
      workflowInstanceState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'WorkflowInstanceState');
      });
    }
    if (multiplier !== undefined && multiplier !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>multiplier, 'Multiplier');
    }
    if (overallTargetAchievement !== undefined && overallTargetAchievement !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>overallTargetAchievement, 'OverallTargetAchievement');
    }
    if (payoutAmount !== undefined && payoutAmount !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>payoutAmount, 'PayoutAmount');
    }
    if (payoutCurrency) {
      payoutCurrency.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayoutCurrency');
      });
    }
    if (ippAchievementValue !== undefined && ippAchievementValue !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>ippAchievementValue, 'IppAchievementValue');
    }
    if (incentivePaymentType) {
      incentivePaymentType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'IncentivePaymentType');
      });
    }
    if (measurementDate !== undefined && measurementDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>measurementDate, 'MeasurementDate');
    }
    if (meritTitle) {
      meritTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'MeritTitle');
      });
    }
    if (manuallyChanged) {
      manuallyChanged.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ManuallyChanged');
      });
    }
    if (newHire) {
      newHire.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'NewHire');
      });
    }
    if (promotion) {
      promotion.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Promotion');
      });
    }
    if (termination) {
      termination.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Termination');
      });
    }
    if (populationChange) {
      populationChange.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PopulationChange');
      });
    }
    if (lineManagerFirstName) {
      lineManagerFirstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerFirstName');
      });
    }
    if (lineManagerLastName) {
      lineManagerLastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerLastName');
      });
    }
    if (companyManagerFirstName) {
      companyManagerFirstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerFirstName');
      });
    }
    if (companyManagerGid) {
      companyManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerGid');
      });
    }
    if (companyManagerLastName) {
      companyManagerLastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerLastName');
      });
    }
    if (templateName) {
      templateName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'TemplateName');
      });
    }
    if (workflowType) {
      workflowType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'WorkflowType');
      });
    }
    if (autoApprove !== undefined && autoApprove !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>autoApprove, 'AutoApprove');
    }
    if (approvalRequired !== undefined && approvalRequired !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>approvalRequired, 'ApprovalRequired');
    }
    if (letterState) {
      letterState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LetterState');
      });
    }
    if (processState) {
      processState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ProcessState');
      });
    }
    if (exportState) {
      exportState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ExportState');
      });
    }
    if (payrollState) {
      payrollState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayrollState');
      });
    }
    if (meritRelevancy) {
      meritRelevancy.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'MeritRelevancy');
      });
    }
    if (approvedByCompanyManager !== undefined && approvedByCompanyManager !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>approvedByCompanyManager, 'ApprovedByCompanyManager');
    }
    if (allocationWorkflowType) {
      allocationWorkflowType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'AllocationWorkflowType');
      });
    }
    if (readyToPayroll !== undefined && readyToPayroll !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>readyToPayroll, 'ReadyToPayroll');
    }
    if (planTitle) {
      planTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PlanTitle');
      });
    }
    if (localCurrency) {
      localCurrency.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LocalCurrency');
      });
    }
    if (proposalAmount !== undefined && proposalAmount !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>proposalAmount, 'ProposalAmount');
    }
    if (proposalDate !== undefined && proposalDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>proposalDate, 'ProposalDate');
    }
    if (specialPaymentReason) {
      specialPaymentReason.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'SpecialPaymentReason');
      });
    }
    if (templateLetterName) {
      templateLetterName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'TemplateLetterName');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/partner/api/v1/master/letters/batch/unpublish`;
    return this.httpClient.request<TransactionStatusOfLetterBatchMetadata>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: communicationsLetterPublicationsUnpublishLetterBatchModel,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createLetterBatchLetters(
    requestParameters?: CommunicationsPartnerServiceCreateLetterBatchLettersRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<TransactionStatusOfLetterBatchMetadata>;
  public createLetterBatchLetters(
    requestParameters?: CommunicationsPartnerServiceCreateLetterBatchLettersRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<TransactionStatusOfLetterBatchMetadata>>;
  public createLetterBatchLetters(
    requestParameters?: CommunicationsPartnerServiceCreateLetterBatchLettersRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<TransactionStatusOfLetterBatchMetadata>>;
  public createLetterBatchLetters(
    requestParameters?: CommunicationsPartnerServiceCreateLetterBatchLettersRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    const gid = requestParameters?.gid;
    const firstName = requestParameters?.firstName;
    const lastName = requestParameters?.lastName;
    const country = requestParameters?.country;
    const are = requestParameters?.are;
    const orgCode = requestParameters?.orgCode;
    const orgClusterNodeName = requestParameters?.orgClusterNodeName;
    const state = requestParameters?.state;
    const planName = requestParameters?.planName;
    const startDate = requestParameters?.startDate;
    const endDate = requestParameters?.endDate;
    const assignmentId = requestParameters?.assignmentId;
    const letterType = requestParameters?.letterType;
    const processStates = requestParameters?.processStates;
    const recordCommunicationStates = requestParameters?.recordCommunicationStates;
    const workflow = requestParameters?.workflow;
    const template = requestParameters?.template;
    const isDesignReady = requestParameters?.isDesignReady;
    const payoutDate = requestParameters?.payoutDate;
    const payoutState = requestParameters?.payoutState;
    const lineManagerName = requestParameters?.lineManagerName;
    const lineManagerGid = requestParameters?.lineManagerGid;
    const seasonId = requestParameters?.seasonId;
    const workflowInstanceState = requestParameters?.workflowInstanceState;
    const multiplier = requestParameters?.multiplier;
    const overallTargetAchievement = requestParameters?.overallTargetAchievement;
    const payoutAmount = requestParameters?.payoutAmount;
    const payoutCurrency = requestParameters?.payoutCurrency;
    const ippAchievementValue = requestParameters?.ippAchievementValue;
    const incentivePaymentType = requestParameters?.incentivePaymentType;
    const measurementDate = requestParameters?.measurementDate;
    const meritTitle = requestParameters?.meritTitle;
    const manuallyChanged = requestParameters?.manuallyChanged;
    const newHire = requestParameters?.newHire;
    const promotion = requestParameters?.promotion;
    const termination = requestParameters?.termination;
    const populationChange = requestParameters?.populationChange;
    const lineManagerFirstName = requestParameters?.lineManagerFirstName;
    const lineManagerLastName = requestParameters?.lineManagerLastName;
    const companyManagerFirstName = requestParameters?.companyManagerFirstName;
    const companyManagerGid = requestParameters?.companyManagerGid;
    const companyManagerLastName = requestParameters?.companyManagerLastName;
    const templateName = requestParameters?.templateName;
    const workflowType = requestParameters?.workflowType;
    const autoApprove = requestParameters?.autoApprove;
    const approvalRequired = requestParameters?.approvalRequired;
    const letterState = requestParameters?.letterState;
    const processState = requestParameters?.processState;
    const exportState = requestParameters?.exportState;
    const payrollState = requestParameters?.payrollState;
    const meritRelevancy = requestParameters?.meritRelevancy;
    const approvedByCompanyManager = requestParameters?.approvedByCompanyManager;
    const allocationWorkflowType = requestParameters?.allocationWorkflowType;
    const readyToPayroll = requestParameters?.readyToPayroll;
    const planTitle = requestParameters?.planTitle;
    const localCurrency = requestParameters?.localCurrency;
    const proposalAmount = requestParameters?.proposalAmount;
    const proposalDate = requestParameters?.proposalDate;
    const specialPaymentReason = requestParameters?.specialPaymentReason;
    const templateLetterName = requestParameters?.templateLetterName;
    const siemensCOINClientApiCommunicationsCommandsLettersCreateLetterBatchModel = requestParameters?.siemensCOINClientApiCommunicationsCommandsLettersCreateLetterBatchModel;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (id !== undefined && id !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>id, 'Id');
    }
    if (gid) {
      gid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Gid');
      });
    }
    if (firstName) {
      firstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'FirstName');
      });
    }
    if (lastName) {
      lastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LastName');
      });
    }
    if (country) {
      country.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Country');
      });
    }
    if (are) {
      are.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Are');
      });
    }
    if (orgCode) {
      orgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'OrgCode');
      });
    }
    if (orgClusterNodeName) {
      orgClusterNodeName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'OrgClusterNodeName');
      });
    }
    if (state) {
      state.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'State');
      });
    }
    if (planName) {
      planName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PlanName');
      });
    }
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }
    if (assignmentId !== undefined && assignmentId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>assignmentId, 'AssignmentId');
    }
    if (letterType !== undefined && letterType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>letterType, 'LetterType');
    }
    if (processStates) {
      processStates.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ProcessStates');
      });
    }
    if (recordCommunicationStates) {
      recordCommunicationStates.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'RecordCommunicationStates');
      });
    }
    if (workflow) {
      workflow.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Workflow');
      });
    }
    if (template) {
      template.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Template');
      });
    }
    if (isDesignReady !== undefined && isDesignReady !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>isDesignReady, 'IsDesignReady');
    }
    if (payoutDate !== undefined && payoutDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>payoutDate, 'PayoutDate');
    }
    if (payoutState) {
      payoutState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayoutState');
      });
    }
    if (lineManagerName !== undefined && lineManagerName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>lineManagerName, 'LineManagerName');
    }
    if (lineManagerGid) {
      lineManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerGid');
      });
    }
    if (seasonId !== undefined && seasonId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>seasonId, 'SeasonId');
    }
    if (workflowInstanceState) {
      workflowInstanceState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'WorkflowInstanceState');
      });
    }
    if (multiplier !== undefined && multiplier !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>multiplier, 'Multiplier');
    }
    if (overallTargetAchievement !== undefined && overallTargetAchievement !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>overallTargetAchievement, 'OverallTargetAchievement');
    }
    if (payoutAmount !== undefined && payoutAmount !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>payoutAmount, 'PayoutAmount');
    }
    if (payoutCurrency) {
      payoutCurrency.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayoutCurrency');
      });
    }
    if (ippAchievementValue !== undefined && ippAchievementValue !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>ippAchievementValue, 'IppAchievementValue');
    }
    if (incentivePaymentType) {
      incentivePaymentType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'IncentivePaymentType');
      });
    }
    if (measurementDate !== undefined && measurementDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>measurementDate, 'MeasurementDate');
    }
    if (meritTitle) {
      meritTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'MeritTitle');
      });
    }
    if (manuallyChanged) {
      manuallyChanged.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ManuallyChanged');
      });
    }
    if (newHire) {
      newHire.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'NewHire');
      });
    }
    if (promotion) {
      promotion.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Promotion');
      });
    }
    if (termination) {
      termination.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Termination');
      });
    }
    if (populationChange) {
      populationChange.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PopulationChange');
      });
    }
    if (lineManagerFirstName) {
      lineManagerFirstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerFirstName');
      });
    }
    if (lineManagerLastName) {
      lineManagerLastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerLastName');
      });
    }
    if (companyManagerFirstName) {
      companyManagerFirstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerFirstName');
      });
    }
    if (companyManagerGid) {
      companyManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerGid');
      });
    }
    if (companyManagerLastName) {
      companyManagerLastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerLastName');
      });
    }
    if (templateName) {
      templateName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'TemplateName');
      });
    }
    if (workflowType) {
      workflowType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'WorkflowType');
      });
    }
    if (autoApprove !== undefined && autoApprove !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>autoApprove, 'AutoApprove');
    }
    if (approvalRequired !== undefined && approvalRequired !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>approvalRequired, 'ApprovalRequired');
    }
    if (letterState) {
      letterState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LetterState');
      });
    }
    if (processState) {
      processState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ProcessState');
      });
    }
    if (exportState) {
      exportState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ExportState');
      });
    }
    if (payrollState) {
      payrollState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayrollState');
      });
    }
    if (meritRelevancy) {
      meritRelevancy.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'MeritRelevancy');
      });
    }
    if (approvedByCompanyManager !== undefined && approvedByCompanyManager !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>approvedByCompanyManager, 'ApprovedByCompanyManager');
    }
    if (allocationWorkflowType) {
      allocationWorkflowType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'AllocationWorkflowType');
      });
    }
    if (readyToPayroll !== undefined && readyToPayroll !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>readyToPayroll, 'ReadyToPayroll');
    }
    if (planTitle) {
      planTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PlanTitle');
      });
    }
    if (localCurrency) {
      localCurrency.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LocalCurrency');
      });
    }
    if (proposalAmount !== undefined && proposalAmount !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>proposalAmount, 'ProposalAmount');
    }
    if (proposalDate !== undefined && proposalDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>proposalDate, 'ProposalDate');
    }
    if (specialPaymentReason) {
      specialPaymentReason.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'SpecialPaymentReason');
      });
    }
    if (templateLetterName) {
      templateLetterName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'TemplateLetterName');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/partner/api/v1/master/letters/batch`;
    return this.httpClient.request<TransactionStatusOfLetterBatchMetadata>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: siemensCOINClientApiCommunicationsCommandsLettersCreateLetterBatchModel,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createManyLetters(
    requestParameters?: CommunicationsPartnerServiceCreateManyLettersRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<TransactionStatusOfLetterBatchMetadata>;
  public createManyLetters(
    requestParameters?: CommunicationsPartnerServiceCreateManyLettersRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<TransactionStatusOfLetterBatchMetadata>>;
  public createManyLetters(
    requestParameters?: CommunicationsPartnerServiceCreateManyLettersRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<TransactionStatusOfLetterBatchMetadata>>;
  public createManyLetters(
    requestParameters?: CommunicationsPartnerServiceCreateManyLettersRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const siemensCOINClientApiCommunicationsCommandsLettersCreateLetterBulkModel = requestParameters?.siemensCOINClientApiCommunicationsCommandsLettersCreateLetterBulkModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/partner/api/v1/master/letters/list`;
    return this.httpClient.request<TransactionStatusOfLetterBatchMetadata>('post', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: siemensCOINClientApiCommunicationsCommandsLettersCreateLetterBulkModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public forwardLettersByQueryLetterActions(
    requestParameters?: CommunicationsPartnerServiceForwardLettersByQueryLetterActionsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<any>;
  public forwardLettersByQueryLetterActions(
    requestParameters?: CommunicationsPartnerServiceForwardLettersByQueryLetterActionsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<any>>;
  public forwardLettersByQueryLetterActions(
    requestParameters?: CommunicationsPartnerServiceForwardLettersByQueryLetterActionsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<any>>;
  public forwardLettersByQueryLetterActions(
    requestParameters?: CommunicationsPartnerServiceForwardLettersByQueryLetterActionsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: undefined; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    const gid = requestParameters?.gid;
    const firstName = requestParameters?.firstName;
    const lastName = requestParameters?.lastName;
    const country = requestParameters?.country;
    const are = requestParameters?.are;
    const orgCode = requestParameters?.orgCode;
    const orgClusterNodeName = requestParameters?.orgClusterNodeName;
    const state = requestParameters?.state;
    const planName = requestParameters?.planName;
    const startDate = requestParameters?.startDate;
    const endDate = requestParameters?.endDate;
    const assignmentId = requestParameters?.assignmentId;
    const letterType = requestParameters?.letterType;
    const processStates = requestParameters?.processStates;
    const recordCommunicationStates = requestParameters?.recordCommunicationStates;
    const workflow = requestParameters?.workflow;
    const template = requestParameters?.template;
    const isDesignReady = requestParameters?.isDesignReady;
    const payoutDate = requestParameters?.payoutDate;
    const payoutState = requestParameters?.payoutState;
    const lineManagerName = requestParameters?.lineManagerName;
    const lineManagerGid = requestParameters?.lineManagerGid;
    const seasonId = requestParameters?.seasonId;
    const workflowInstanceState = requestParameters?.workflowInstanceState;
    const multiplier = requestParameters?.multiplier;
    const overallTargetAchievement = requestParameters?.overallTargetAchievement;
    const payoutAmount = requestParameters?.payoutAmount;
    const payoutCurrency = requestParameters?.payoutCurrency;
    const ippAchievementValue = requestParameters?.ippAchievementValue;
    const incentivePaymentType = requestParameters?.incentivePaymentType;
    const measurementDate = requestParameters?.measurementDate;
    const meritTitle = requestParameters?.meritTitle;
    const manuallyChanged = requestParameters?.manuallyChanged;
    const newHire = requestParameters?.newHire;
    const promotion = requestParameters?.promotion;
    const termination = requestParameters?.termination;
    const populationChange = requestParameters?.populationChange;
    const lineManagerFirstName = requestParameters?.lineManagerFirstName;
    const lineManagerLastName = requestParameters?.lineManagerLastName;
    const companyManagerFirstName = requestParameters?.companyManagerFirstName;
    const companyManagerGid = requestParameters?.companyManagerGid;
    const companyManagerLastName = requestParameters?.companyManagerLastName;
    const templateName = requestParameters?.templateName;
    const workflowType = requestParameters?.workflowType;
    const autoApprove = requestParameters?.autoApprove;
    const approvalRequired = requestParameters?.approvalRequired;
    const letterState = requestParameters?.letterState;
    const processState = requestParameters?.processState;
    const exportState = requestParameters?.exportState;
    const payrollState = requestParameters?.payrollState;
    const meritRelevancy = requestParameters?.meritRelevancy;
    const approvedByCompanyManager = requestParameters?.approvedByCompanyManager;
    const allocationWorkflowType = requestParameters?.allocationWorkflowType;
    const readyToPayroll = requestParameters?.readyToPayroll;
    const planTitle = requestParameters?.planTitle;
    const localCurrency = requestParameters?.localCurrency;
    const proposalAmount = requestParameters?.proposalAmount;
    const proposalDate = requestParameters?.proposalDate;
    const specialPaymentReason = requestParameters?.specialPaymentReason;
    const templateLetterName = requestParameters?.templateLetterName;
    const communicationsLetterActionsForwardLettersModel = requestParameters?.communicationsLetterActionsForwardLettersModel;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (id !== undefined && id !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>id, 'Id');
    }
    if (gid) {
      gid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Gid');
      });
    }
    if (firstName) {
      firstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'FirstName');
      });
    }
    if (lastName) {
      lastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LastName');
      });
    }
    if (country) {
      country.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Country');
      });
    }
    if (are) {
      are.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Are');
      });
    }
    if (orgCode) {
      orgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'OrgCode');
      });
    }
    if (orgClusterNodeName) {
      orgClusterNodeName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'OrgClusterNodeName');
      });
    }
    if (state) {
      state.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'State');
      });
    }
    if (planName) {
      planName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PlanName');
      });
    }
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }
    if (assignmentId !== undefined && assignmentId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>assignmentId, 'AssignmentId');
    }
    if (letterType !== undefined && letterType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>letterType, 'LetterType');
    }
    if (processStates) {
      processStates.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ProcessStates');
      });
    }
    if (recordCommunicationStates) {
      recordCommunicationStates.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'RecordCommunicationStates');
      });
    }
    if (workflow) {
      workflow.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Workflow');
      });
    }
    if (template) {
      template.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Template');
      });
    }
    if (isDesignReady !== undefined && isDesignReady !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>isDesignReady, 'IsDesignReady');
    }
    if (payoutDate !== undefined && payoutDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>payoutDate, 'PayoutDate');
    }
    if (payoutState) {
      payoutState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayoutState');
      });
    }
    if (lineManagerName !== undefined && lineManagerName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>lineManagerName, 'LineManagerName');
    }
    if (lineManagerGid) {
      lineManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerGid');
      });
    }
    if (seasonId !== undefined && seasonId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>seasonId, 'SeasonId');
    }
    if (workflowInstanceState) {
      workflowInstanceState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'WorkflowInstanceState');
      });
    }
    if (multiplier !== undefined && multiplier !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>multiplier, 'Multiplier');
    }
    if (overallTargetAchievement !== undefined && overallTargetAchievement !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>overallTargetAchievement, 'OverallTargetAchievement');
    }
    if (payoutAmount !== undefined && payoutAmount !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>payoutAmount, 'PayoutAmount');
    }
    if (payoutCurrency) {
      payoutCurrency.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayoutCurrency');
      });
    }
    if (ippAchievementValue !== undefined && ippAchievementValue !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>ippAchievementValue, 'IppAchievementValue');
    }
    if (incentivePaymentType) {
      incentivePaymentType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'IncentivePaymentType');
      });
    }
    if (measurementDate !== undefined && measurementDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>measurementDate, 'MeasurementDate');
    }
    if (meritTitle) {
      meritTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'MeritTitle');
      });
    }
    if (manuallyChanged) {
      manuallyChanged.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ManuallyChanged');
      });
    }
    if (newHire) {
      newHire.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'NewHire');
      });
    }
    if (promotion) {
      promotion.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Promotion');
      });
    }
    if (termination) {
      termination.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Termination');
      });
    }
    if (populationChange) {
      populationChange.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PopulationChange');
      });
    }
    if (lineManagerFirstName) {
      lineManagerFirstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerFirstName');
      });
    }
    if (lineManagerLastName) {
      lineManagerLastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerLastName');
      });
    }
    if (companyManagerFirstName) {
      companyManagerFirstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerFirstName');
      });
    }
    if (companyManagerGid) {
      companyManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerGid');
      });
    }
    if (companyManagerLastName) {
      companyManagerLastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerLastName');
      });
    }
    if (templateName) {
      templateName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'TemplateName');
      });
    }
    if (workflowType) {
      workflowType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'WorkflowType');
      });
    }
    if (autoApprove !== undefined && autoApprove !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>autoApprove, 'AutoApprove');
    }
    if (approvalRequired !== undefined && approvalRequired !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>approvalRequired, 'ApprovalRequired');
    }
    if (letterState) {
      letterState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LetterState');
      });
    }
    if (processState) {
      processState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ProcessState');
      });
    }
    if (exportState) {
      exportState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ExportState');
      });
    }
    if (payrollState) {
      payrollState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayrollState');
      });
    }
    if (meritRelevancy) {
      meritRelevancy.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'MeritRelevancy');
      });
    }
    if (approvedByCompanyManager !== undefined && approvedByCompanyManager !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>approvedByCompanyManager, 'ApprovedByCompanyManager');
    }
    if (allocationWorkflowType) {
      allocationWorkflowType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'AllocationWorkflowType');
      });
    }
    if (readyToPayroll !== undefined && readyToPayroll !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>readyToPayroll, 'ReadyToPayroll');
    }
    if (planTitle) {
      planTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PlanTitle');
      });
    }
    if (localCurrency) {
      localCurrency.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LocalCurrency');
      });
    }
    if (proposalAmount !== undefined && proposalAmount !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>proposalAmount, 'ProposalAmount');
    }
    if (proposalDate !== undefined && proposalDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>proposalDate, 'ProposalDate');
    }
    if (specialPaymentReason) {
      specialPaymentReason.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'SpecialPaymentReason');
      });
    }
    if (templateLetterName) {
      templateLetterName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'TemplateLetterName');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = [];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/partner/api/v1/master/letters/batch/forward`;
    return this.httpClient.request<any>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: communicationsLetterActionsForwardLettersModel,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public forwardLettersLetterActions(
    requestParameters?: CommunicationsPartnerServiceForwardLettersLetterActionsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<TransactionStatusOfLetterBatchMetadata>;
  public forwardLettersLetterActions(
    requestParameters?: CommunicationsPartnerServiceForwardLettersLetterActionsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<TransactionStatusOfLetterBatchMetadata>>;
  public forwardLettersLetterActions(
    requestParameters?: CommunicationsPartnerServiceForwardLettersLetterActionsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<TransactionStatusOfLetterBatchMetadata>>;
  public forwardLettersLetterActions(
    requestParameters?: CommunicationsPartnerServiceForwardLettersLetterActionsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const communicationsLetterActionsForwardLettersModel = requestParameters?.communicationsLetterActionsForwardLettersModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/partner/api/v1/master/letters/list/forward`;
    return this.httpClient.request<TransactionStatusOfLetterBatchMetadata>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: communicationsLetterActionsForwardLettersModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public listUnpublishLetterLetterPublications(
    requestParameters?: CommunicationsPartnerServiceListUnpublishLetterLetterPublicationsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<TransactionStatusOfLetterBatchMetadata>;
  public listUnpublishLetterLetterPublications(
    requestParameters?: CommunicationsPartnerServiceListUnpublishLetterLetterPublicationsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<TransactionStatusOfLetterBatchMetadata>>;
  public listUnpublishLetterLetterPublications(
    requestParameters?: CommunicationsPartnerServiceListUnpublishLetterLetterPublicationsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<TransactionStatusOfLetterBatchMetadata>>;
  public listUnpublishLetterLetterPublications(
    requestParameters?: CommunicationsPartnerServiceListUnpublishLetterLetterPublicationsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const communicationsLetterPublicationsUnpublishLetterListModel = requestParameters?.communicationsLetterPublicationsUnpublishLetterListModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/partner/api/v1/master/letters/list/unpublish`;
    return this.httpClient.request<TransactionStatusOfLetterBatchMetadata>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: communicationsLetterPublicationsUnpublishLetterListModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public publishLetterBatchLetterPublications(
    requestParameters?: CommunicationsPartnerServicePublishLetterBatchLetterPublicationsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<TransactionStatusOfLetterBatchMetadata>;
  public publishLetterBatchLetterPublications(
    requestParameters?: CommunicationsPartnerServicePublishLetterBatchLetterPublicationsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<TransactionStatusOfLetterBatchMetadata>>;
  public publishLetterBatchLetterPublications(
    requestParameters?: CommunicationsPartnerServicePublishLetterBatchLetterPublicationsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<TransactionStatusOfLetterBatchMetadata>>;
  public publishLetterBatchLetterPublications(
    requestParameters?: CommunicationsPartnerServicePublishLetterBatchLetterPublicationsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const id = requestParameters?.id;
    const gid = requestParameters?.gid;
    const firstName = requestParameters?.firstName;
    const lastName = requestParameters?.lastName;
    const country = requestParameters?.country;
    const are = requestParameters?.are;
    const orgCode = requestParameters?.orgCode;
    const orgClusterNodeName = requestParameters?.orgClusterNodeName;
    const state = requestParameters?.state;
    const planName = requestParameters?.planName;
    const startDate = requestParameters?.startDate;
    const endDate = requestParameters?.endDate;
    const assignmentId = requestParameters?.assignmentId;
    const letterType = requestParameters?.letterType;
    const processStates = requestParameters?.processStates;
    const recordCommunicationStates = requestParameters?.recordCommunicationStates;
    const workflow = requestParameters?.workflow;
    const template = requestParameters?.template;
    const isDesignReady = requestParameters?.isDesignReady;
    const payoutDate = requestParameters?.payoutDate;
    const payoutState = requestParameters?.payoutState;
    const lineManagerName = requestParameters?.lineManagerName;
    const lineManagerGid = requestParameters?.lineManagerGid;
    const seasonId = requestParameters?.seasonId;
    const workflowInstanceState = requestParameters?.workflowInstanceState;
    const multiplier = requestParameters?.multiplier;
    const overallTargetAchievement = requestParameters?.overallTargetAchievement;
    const payoutAmount = requestParameters?.payoutAmount;
    const payoutCurrency = requestParameters?.payoutCurrency;
    const ippAchievementValue = requestParameters?.ippAchievementValue;
    const incentivePaymentType = requestParameters?.incentivePaymentType;
    const measurementDate = requestParameters?.measurementDate;
    const meritTitle = requestParameters?.meritTitle;
    const manuallyChanged = requestParameters?.manuallyChanged;
    const newHire = requestParameters?.newHire;
    const promotion = requestParameters?.promotion;
    const termination = requestParameters?.termination;
    const populationChange = requestParameters?.populationChange;
    const lineManagerFirstName = requestParameters?.lineManagerFirstName;
    const lineManagerLastName = requestParameters?.lineManagerLastName;
    const companyManagerFirstName = requestParameters?.companyManagerFirstName;
    const companyManagerGid = requestParameters?.companyManagerGid;
    const companyManagerLastName = requestParameters?.companyManagerLastName;
    const templateName = requestParameters?.templateName;
    const workflowType = requestParameters?.workflowType;
    const autoApprove = requestParameters?.autoApprove;
    const approvalRequired = requestParameters?.approvalRequired;
    const letterState = requestParameters?.letterState;
    const processState = requestParameters?.processState;
    const exportState = requestParameters?.exportState;
    const payrollState = requestParameters?.payrollState;
    const meritRelevancy = requestParameters?.meritRelevancy;
    const approvedByCompanyManager = requestParameters?.approvedByCompanyManager;
    const allocationWorkflowType = requestParameters?.allocationWorkflowType;
    const readyToPayroll = requestParameters?.readyToPayroll;
    const planTitle = requestParameters?.planTitle;
    const localCurrency = requestParameters?.localCurrency;
    const proposalAmount = requestParameters?.proposalAmount;
    const proposalDate = requestParameters?.proposalDate;
    const specialPaymentReason = requestParameters?.specialPaymentReason;
    const templateLetterName = requestParameters?.templateLetterName;
    const communicationsLetterPublicationsPublishLetterBatchModel = requestParameters?.communicationsLetterPublicationsPublishLetterBatchModel;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (id !== undefined && id !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>id, 'Id');
    }
    if (gid) {
      gid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Gid');
      });
    }
    if (firstName) {
      firstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'FirstName');
      });
    }
    if (lastName) {
      lastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LastName');
      });
    }
    if (country) {
      country.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Country');
      });
    }
    if (are) {
      are.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Are');
      });
    }
    if (orgCode) {
      orgCode.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'OrgCode');
      });
    }
    if (orgClusterNodeName) {
      orgClusterNodeName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'OrgClusterNodeName');
      });
    }
    if (state) {
      state.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'State');
      });
    }
    if (planName) {
      planName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PlanName');
      });
    }
    if (startDate !== undefined && startDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>startDate, 'StartDate');
    }
    if (endDate !== undefined && endDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>endDate, 'EndDate');
    }
    if (assignmentId !== undefined && assignmentId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>assignmentId, 'AssignmentId');
    }
    if (letterType !== undefined && letterType !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>letterType, 'LetterType');
    }
    if (processStates) {
      processStates.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ProcessStates');
      });
    }
    if (recordCommunicationStates) {
      recordCommunicationStates.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'RecordCommunicationStates');
      });
    }
    if (workflow) {
      workflow.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Workflow');
      });
    }
    if (template) {
      template.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Template');
      });
    }
    if (isDesignReady !== undefined && isDesignReady !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>isDesignReady, 'IsDesignReady');
    }
    if (payoutDate !== undefined && payoutDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>payoutDate, 'PayoutDate');
    }
    if (payoutState) {
      payoutState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayoutState');
      });
    }
    if (lineManagerName !== undefined && lineManagerName !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>lineManagerName, 'LineManagerName');
    }
    if (lineManagerGid) {
      lineManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerGid');
      });
    }
    if (seasonId !== undefined && seasonId !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>seasonId, 'SeasonId');
    }
    if (workflowInstanceState) {
      workflowInstanceState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'WorkflowInstanceState');
      });
    }
    if (multiplier !== undefined && multiplier !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>multiplier, 'Multiplier');
    }
    if (overallTargetAchievement !== undefined && overallTargetAchievement !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>overallTargetAchievement, 'OverallTargetAchievement');
    }
    if (payoutAmount !== undefined && payoutAmount !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>payoutAmount, 'PayoutAmount');
    }
    if (payoutCurrency) {
      payoutCurrency.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayoutCurrency');
      });
    }
    if (ippAchievementValue !== undefined && ippAchievementValue !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>ippAchievementValue, 'IppAchievementValue');
    }
    if (incentivePaymentType) {
      incentivePaymentType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'IncentivePaymentType');
      });
    }
    if (measurementDate !== undefined && measurementDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>measurementDate, 'MeasurementDate');
    }
    if (meritTitle) {
      meritTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'MeritTitle');
      });
    }
    if (manuallyChanged) {
      manuallyChanged.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ManuallyChanged');
      });
    }
    if (newHire) {
      newHire.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'NewHire');
      });
    }
    if (promotion) {
      promotion.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Promotion');
      });
    }
    if (termination) {
      termination.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Termination');
      });
    }
    if (populationChange) {
      populationChange.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PopulationChange');
      });
    }
    if (lineManagerFirstName) {
      lineManagerFirstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerFirstName');
      });
    }
    if (lineManagerLastName) {
      lineManagerLastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LineManagerLastName');
      });
    }
    if (companyManagerFirstName) {
      companyManagerFirstName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerFirstName');
      });
    }
    if (companyManagerGid) {
      companyManagerGid.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerGid');
      });
    }
    if (companyManagerLastName) {
      companyManagerLastName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'CompanyManagerLastName');
      });
    }
    if (templateName) {
      templateName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'TemplateName');
      });
    }
    if (workflowType) {
      workflowType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'WorkflowType');
      });
    }
    if (autoApprove !== undefined && autoApprove !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>autoApprove, 'AutoApprove');
    }
    if (approvalRequired !== undefined && approvalRequired !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>approvalRequired, 'ApprovalRequired');
    }
    if (letterState) {
      letterState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LetterState');
      });
    }
    if (processState) {
      processState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ProcessState');
      });
    }
    if (exportState) {
      exportState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'ExportState');
      });
    }
    if (payrollState) {
      payrollState.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PayrollState');
      });
    }
    if (meritRelevancy) {
      meritRelevancy.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'MeritRelevancy');
      });
    }
    if (approvedByCompanyManager !== undefined && approvedByCompanyManager !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>approvedByCompanyManager, 'ApprovedByCompanyManager');
    }
    if (allocationWorkflowType) {
      allocationWorkflowType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'AllocationWorkflowType');
      });
    }
    if (readyToPayroll !== undefined && readyToPayroll !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>readyToPayroll, 'ReadyToPayroll');
    }
    if (planTitle) {
      planTitle.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'PlanTitle');
      });
    }
    if (localCurrency) {
      localCurrency.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'LocalCurrency');
      });
    }
    if (proposalAmount !== undefined && proposalAmount !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>proposalAmount, 'ProposalAmount');
    }
    if (proposalDate !== undefined && proposalDate !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>proposalDate, 'ProposalDate');
    }
    if (specialPaymentReason) {
      specialPaymentReason.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'SpecialPaymentReason');
      });
    }
    if (templateLetterName) {
      templateLetterName.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'TemplateLetterName');
      });
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/partner/api/v1/master/letters/batch/publish`;
    return this.httpClient.request<TransactionStatusOfLetterBatchMetadata>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: communicationsLetterPublicationsPublishLetterBatchModel,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public publishLetterListLetterPublications(
    requestParameters?: CommunicationsPartnerServicePublishLetterListLetterPublicationsRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<TransactionStatusOfLetterBatchMetadata>;
  public publishLetterListLetterPublications(
    requestParameters?: CommunicationsPartnerServicePublishLetterListLetterPublicationsRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<TransactionStatusOfLetterBatchMetadata>>;
  public publishLetterListLetterPublications(
    requestParameters?: CommunicationsPartnerServicePublishLetterListLetterPublicationsRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<TransactionStatusOfLetterBatchMetadata>>;
  public publishLetterListLetterPublications(
    requestParameters?: CommunicationsPartnerServicePublishLetterListLetterPublicationsRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const siemensCOINClientApiCommunicationsCommandsLettersPublishLetterListModel = requestParameters?.siemensCOINClientApiCommunicationsCommandsLettersPublishLetterListModel;

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json-patch+json', 'application/json', 'text/json', 'application/*+json'];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/partner/api/v1/master/letters/list/publish`;
    return this.httpClient.request<TransactionStatusOfLetterBatchMetadata>('patch', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      body: siemensCOINClientApiCommunicationsCommandsLettersPublishLetterListModel,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }

  /**
   * @param requestParameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public queryLetterTemplates(
    requestParameters?: CommunicationsPartnerServiceQueryLetterTemplatesRequestParams,
    observe?: 'body',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<SharedPageModelOfCommunicationsWorkflowTemplatesWorkflowTemplateModel>;
  public queryLetterTemplates(
    requestParameters?: CommunicationsPartnerServiceQueryLetterTemplatesRequestParams,
    observe?: 'response',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpResponse<SharedPageModelOfCommunicationsWorkflowTemplatesWorkflowTemplateModel>>;
  public queryLetterTemplates(
    requestParameters?: CommunicationsPartnerServiceQueryLetterTemplatesRequestParams,
    observe?: 'events',
    reportProgress?: boolean,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<HttpEvent<SharedPageModelOfCommunicationsWorkflowTemplatesWorkflowTemplateModel>>;
  public queryLetterTemplates(
    requestParameters?: CommunicationsPartnerServiceQueryLetterTemplatesRequestParams,
    observe: any = 'body',
    reportProgress: boolean = false,
    options?: { httpHeaderAccept?: 'text/plain' | 'application/json' | 'text/json'; context?: HttpContext; transferCache?: boolean }
  ): Observable<any> {
    const queryTitle = requestParameters?.queryTitle;
    const queryDescription = requestParameters?.queryDescription;
    const queryLetterType = requestParameters?.queryLetterType;
    const queryCreatedBy = requestParameters?.queryCreatedBy;
    const queryUpdatedBy = requestParameters?.queryUpdatedBy;
    const queryCreatedAt = requestParameters?.queryCreatedAt;
    const queryUpdatedAt = requestParameters?.queryUpdatedAt;
    const queryCreatedAtFrom = requestParameters?.queryCreatedAtFrom;
    const queryCreatedAtTo = requestParameters?.queryCreatedAtTo;
    const queryUpdatedAtFrom = requestParameters?.queryUpdatedAtFrom;
    const queryUpdatedAtTo = requestParameters?.queryUpdatedAtTo;
    const pagingPage = requestParameters?.pagingPage;
    const pagingSize = requestParameters?.pagingSize;
    const sortingOrderBy = requestParameters?.sortingOrderBy;
    const sortingProperty = requestParameters?.sortingProperty;

    let localVarQueryParameters = new HttpParams({ encoder: this.encoder });
    if (queryTitle !== undefined && queryTitle !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryTitle, 'Query.Title');
    }
    if (queryDescription !== undefined && queryDescription !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryDescription, 'Query.Description');
    }
    if (queryLetterType) {
      queryLetterType.forEach(element => {
        localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>element, 'Query.LetterType');
      });
    }
    if (queryCreatedBy !== undefined && queryCreatedBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryCreatedBy, 'Query.CreatedBy');
    }
    if (queryUpdatedBy !== undefined && queryUpdatedBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryUpdatedBy, 'Query.UpdatedBy');
    }
    if (queryCreatedAt !== undefined && queryCreatedAt !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryCreatedAt, 'Query.CreatedAt');
    }
    if (queryUpdatedAt !== undefined && queryUpdatedAt !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryUpdatedAt, 'Query.UpdatedAt');
    }
    if (queryCreatedAtFrom !== undefined && queryCreatedAtFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryCreatedAtFrom, 'Query.CreatedAtFrom');
    }
    if (queryCreatedAtTo !== undefined && queryCreatedAtTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryCreatedAtTo, 'Query.CreatedAtTo');
    }
    if (queryUpdatedAtFrom !== undefined && queryUpdatedAtFrom !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryUpdatedAtFrom, 'Query.UpdatedAtFrom');
    }
    if (queryUpdatedAtTo !== undefined && queryUpdatedAtTo !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>queryUpdatedAtTo, 'Query.UpdatedAtTo');
    }
    if (pagingPage !== undefined && pagingPage !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingPage, 'Paging.Page');
    }
    if (pagingSize !== undefined && pagingSize !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>pagingSize, 'Paging.Size');
    }
    if (sortingOrderBy !== undefined && sortingOrderBy !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingOrderBy, 'Sorting.OrderBy');
    }
    if (sortingProperty !== undefined && sortingProperty !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters, <any>sortingProperty, 'Sorting.Property');
    }

    let localVarHeaders = this.defaultHeaders;

    let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
    if (localVarHttpHeaderAcceptSelected === undefined) {
      // to determine the Accept header
      const httpHeaderAccepts: string[] = ['text/plain', 'application/json', 'text/json'];
      localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    }
    if (localVarHttpHeaderAcceptSelected !== undefined) {
      localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
    }

    let localVarHttpContext: HttpContext | undefined = options && options.context;
    if (localVarHttpContext === undefined) {
      localVarHttpContext = new HttpContext();
    }

    let localVarTransferCache: boolean | undefined = options && options.transferCache;
    if (localVarTransferCache === undefined) {
      localVarTransferCache = true;
    }

    let responseType_: 'text' | 'json' | 'blob' = 'json';
    if (localVarHttpHeaderAcceptSelected) {
      if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
        responseType_ = 'text';
      } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
        responseType_ = 'json';
      } else {
        responseType_ = 'blob';
      }
    }

    const localVarPath = `/partner/api/v1/master/letters/templates`;
    return this.httpClient.request<SharedPageModelOfCommunicationsWorkflowTemplatesWorkflowTemplateModel>('get', `${this.configuration.basePath}${localVarPath}`, {
      context: localVarHttpContext,
      params: localVarQueryParameters,
      responseType: <any>responseType_,
      withCredentials: this.configuration.withCredentials,
      headers: localVarHeaders,
      observe: observe,
      transferCache: localVarTransferCache,
      reportProgress: reportProgress
    });
  }
}
