import { DestroyRef, Injectable } from '@angular/core';
import {
  CommunicationsPartnerService,
  HttpHelpersService,
  IncentivePartnerService,
  IncentivePartnerServiceQueryMonitoringRequestParams,
  LetterCreationService,
  LoadingService,
  TransactionCalculationOperations,
  TransactionService
} from '@coin/shared/data-access';
import {
  CommunicationsLetterPublicationsAddPublicationDateModel,
  CommunicationsLetterPublicationsPublishLetterBatchModelLetterTypeEnum,
  CommunicationsLetterPublicationsPublishLetterBatchModelManagerTypeEnum,
  CommunicationsLetterPublicationsPublishLetterBatchModelSeasonTypeEnum,
  CommunicationsLetterPublicationsUnpublishLetterBatchModelLetterTypeEnum,
  FilterSetting,
  IncentiveRecordsAdjustRecordLetterStateModelLetterTypeEnum,
  IncentiveRecordsAdjustRecordLetterStateModelStateEnum,
  IncentiveRecordsAssignmentLetterModel,
  IncentiveRecordsAssignmentLetterModelTypeEnum,
  IncentiveRecordsAssignmentRecordPartnerModel,
  IncentiveRecordsAssignmentRecordPartnerModelProcessStateEnum,
  IncentiveRecordsAssignmentRecordPartnerModelStateEnum,
  IncentiveRecordsMonitoringRecordModel,
  IncentiveRecordsMonitoringRecordModelStateEnum,
  IncentiveRecordsRecordLetterStateModelLetterTypeEnum,
  IncentiveRecordsRecordLetterStateModelStateEnum,
  IncentiveRecordsUpdateManyRecordStatesPartnerModelStateEnum,
  ListViewTagFilterParameter,
  PaginationState,
  SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBatchModelLetterTypeEnum,
  SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBatchModelSeasonTypeEnum,
  SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBulkModelLetterTypeEnum,
  SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBulkModelSeasonTypeEnum,
  SiemensCOINClientApiCommunicationsCommandsLettersPublishLetterListModelManagerTypeEnum,
  SiemensCOINClientApiCommunicationsCommandsLettersPublishLetterListModelSeasonTypeEnum,
  TransactionStatus,
  TransactionStatusMetadata
} from '@coin/shared/util-models';
import { Store } from '@ngxs/store';
import { ImmerComponentStore } from 'ngrx-immer/component-store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { defer, Observable, of, Subject, switchMap, takeUntil } from 'rxjs';
import { FilterSortState } from '@coin/customer/shared/filter-sort-bar-data-access';
import { DownloadFileHelper, stateSnapshot } from '@coin/shared/util-helpers';
import { ToastrService } from 'ngx-toastr';
import { IncentiveSupportComponentState } from './incentive-support.component.state';
import { MonitoringQuickFilter } from '../enums/monitoring-quick-filter.enum';
import { MassOperationPayload, MassOperationType } from '../models/mass-operation-type';
import { addQueryPrefix, convertFiltersToBatchParams } from '../functions/convert-filters-to-batch-params';
import { isProcessStateAboveOrEqualToFrozen } from '../functions/is-above-equal-frozen';

interface MonitoringStateModel {
  monitoringList: IncentiveRecordsMonitoringRecordModel[];
  paginationState: PaginationState;
  filterParameter: ListViewTagFilterParameter[];
  isMultiselectActive: boolean;
  selectedItemIds: Set<string> | 'ALL';
  filterOptions: FilterSetting[];
}

const initialFilterOptionsState: FilterSetting[] = [];

const initialState = (): MonitoringStateModel => ({
  monitoringList: [],
  paginationState: {
    pageSize: 50,
    nextPage: 1,
    hasMore: true,
    currentPage: 0
  },
  filterParameter: [
    { category: MonitoringQuickFilter.State, value: IncentiveRecordsMonitoringRecordModelStateEnum.Active },
    { category: MonitoringQuickFilter.State, value: IncentiveRecordsMonitoringRecordModelStateEnum.Outdated }
  ],
  isMultiselectActive: false,
  selectedItemIds: new Set<string>(),
  filterOptions: initialFilterOptionsState
});

@Injectable()
export class MonitoringComponentState extends ImmerComponentStore<MonitoringStateModel> {
  public transactionCalculationOperations = new TransactionCalculationOperations(this.transactionService, this.toastrService);
  private paginationReset$ = new Subject<void>();

  public monitoringList$ = this.select(({ monitoringList }) => monitoringList);
  public monitoringListById$ = this.select(this.monitoringList$, monitoringList =>
    monitoringList.reduce<Record<string, IncentiveRecordsMonitoringRecordModel>>((accumulation, record) => {
      accumulation[record.id] = record;
      return accumulation;
    }, {})
  );
  public paginationState$ = this.select(({ paginationState }) => paginationState);
  public recordsById$ = this.select(this.monitoringList$, monitoringList =>
    monitoringList.reduce<Record<string, IncentiveRecordsMonitoringRecordModel>>((accumulation, record) => {
      accumulation[record.id] = record;
      return accumulation;
    }, {})
  );
  public selectedItemIds$ = this.select(({ selectedItemIds }) => selectedItemIds);
  public totalCount$ = this.select(({ paginationState }) => paginationState.total || 0);
  public selectedItemsCount$ = this.select(this.selectedItemIds$, this.totalCount$, (selectedItemIds, totalCount) =>
    selectedItemIds === 'ALL' ? totalCount : selectedItemIds.size
  );
  public isLoadingInitialPage$ = this.select(this.paginationState$, paginationState => paginationState.currentPage === 0 && paginationState.nextPage === 1);
  public filterParameter$ = this.select(({ filterParameter }) => filterParameter);
  public isMultiselectActive$ = this.select(({ isMultiselectActive }) => isMultiselectActive);
  public isFilterActive$ = this.select(
    ({ filterParameter }) =>
      filterParameter.map(({ value }) => value).toString() !==
      initialState()
        .filterParameter.map(({ value }) => value)
        .toString()
  );
  public hasNoLetterSelected$ = this.select(this.selectedItemIds$, selectedItemIds => {
    return selectedItemIds !== 'ALL' && !this.getLetterIdsOfRecords([...selectedItemIds.values()]).length;
  });

  public isSelected(id: string): boolean {
    const { selectedItemIds } = this.get();
    return selectedItemIds === 'ALL' || selectedItemIds.has(id);
  }

  constructor(
    private incentiveSupportState: IncentiveSupportComponentState,
    private communicationsPartnerService: CommunicationsPartnerService,
    private incentivePartnerService: IncentivePartnerService,
    private store: Store,
    private httpHelpersService: HttpHelpersService,
    private loadingService: LoadingService,
    private monitoringService: IncentivePartnerService,
    private destroyRef: DestroyRef,
    private letterCreationService: LetterCreationService,
    private transactionService: TransactionService,
    private toastrService: ToastrService
  ) {
    super(initialState());

    this.incentiveSupportState.sortingChange$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.loadInitialPage();
    });

    this.incentiveSupportState.setFilter$.pipe(takeUntilDestroyed()).subscribe(() => {
      // Verify if filter options has different assigned values aside from null
      const selectedFilterOptions = this.store.selectSnapshot(FilterSortState.selectedFilterOptions);

      const isEqual =
        JSON.stringify(Object.values(this.state().filterOptions.filter(v => v.values !== null)).sort()) ===
        JSON.stringify(Object.values(selectedFilterOptions.filter(v => v.values !== null)).sort());

      if (!isEqual) {
        this.loadInitialPage();
      }

      // Update state
      this.patchState({
        filterOptions: selectedFilterOptions
      });
    });

    this.incentiveSupportState.setSearchItem$.pipe(takeUntilDestroyed()).subscribe(reassignment => {
      this.unshift(reassignment);
    });
  }

  public unshift(record: IncentiveRecordsMonitoringRecordModel): void {
    const { monitoringList } = this.get();
    const index = monitoringList.findIndex(existingRecord => existingRecord.id === record.id);
    this.setState(state => {
      if (index > -1) {
        state.monitoringList.splice(index, 1);
      }
      state.monitoringList.unshift(record);
    });
  }

  public loadInitialPage(): void {
    this.toggleMultiselect(false);
    this.resetPaginationState();
    this.loadNextPage();
  }

  public loadNextPage(): void {
    const selectedSeason = this.incentiveSupportState.getSelectedSeason();
    const { paginationState } = this.get();
    const { nextPage, pageSize, currentPage } = paginationState;

    const selectedSortOption = this.store.selectSnapshot(FilterSortState.selectedSortOption);
    const selectedFilterOptions = this.store.selectSnapshot(FilterSortState.selectedFilterOptions);

    const requestParams = {
      seasonId: selectedSeason.id,
      pagingPage: nextPage,
      pagingSize: pageSize,
      sortingOrderBy: selectedSortOption?.value as unknown as 'Asc' | 'Desc',
      sortingProperty: selectedSortOption?.key
    };
    const filters = addQueryPrefix(convertFiltersToBatchParams(selectedFilterOptions));
    const quickFilters = this.getSelectedQuickFilters();
    const paramsWithFilters = { ...requestParams, ...filters, ...quickFilters };
    this.monitoringService
      .queryMonitoring(paramsWithFilters)
      .pipe(
        request$ => (!currentPage ? request$ : this.loadingService.withLoadingScreen(request$)), // display global loading spinner when loading any page after the first
        this.httpHelpersService.withStatusMessages({ error: 'incentive-support.http-error-messages.loading-reassignments-failed' }),
        takeUntil(this.paginationReset$),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(response => {
        const { content, ...paginationState } = response;
        this.setState(state => {
          state.paginationState = paginationState;
          state.monitoringList = [...state.monitoringList, ...content];
        });
      });
  }

  private getSelectedQuickFilters(): Pick<IncentivePartnerServiceQueryMonitoringRequestParams, 'queryState'> {
    const { filterParameter } = this.get();
    const stateFilter = filterParameter.filter(parameter => parameter.category === MonitoringQuickFilter.State);

    return {
      queryState: stateFilter.map(state => state.value) as unknown as ('Active' | 'Draft' | 'Outdated' | 'Irrelevant')[]
    };
  }

  public addFilterParameter(parameter: ListViewTagFilterParameter): void {
    this.setState(state => {
      state.filterParameter = [...state.filterParameter, parameter];
    });
  }

  public removeFilterParameter(parameter: ListViewTagFilterParameter): void {
    this.setState(state => {
      state.filterParameter = state.filterParameter.filter(existingFilterParameter => existingFilterParameter.value !== parameter.value);
    });
  }

  public resetFilterParameters(): void {
    this.setState(state => {
      state.filterParameter = initialState().filterParameter;
    });
  }

  public toggleMultiselect(isMultiselectActive: boolean): void {
    this.setState(state => {
      state.isMultiselectActive = isMultiselectActive;
    });
    this.unselectAll();
  }

  public selectAll(): void {
    this.toggleAll(true);
  }

  public unselectAll(): void {
    this.toggleAll(false);
  }

  private toggleAll(isSelected: boolean): void {
    this.setState(state => {
      if (isSelected) {
        state.selectedItemIds = 'ALL';
      } else {
        state.selectedItemIds = new Set();
      }
    });
  }

  public toggleSelection(id: string): void {
    this.setState(state => {
      if (state.selectedItemIds === 'ALL') {
        // select all currently visible items except for the toggled one
        state.selectedItemIds = new Set(state.monitoringList.map(item => item.id));
        state.selectedItemIds.delete(id);
        return;
      }

      if (state.selectedItemIds.has(id)) {
        state.selectedItemIds.delete(id);
      } else {
        state.selectedItemIds.add(id);
      }
    });
  }

  public resetPaginationState(): void {
    const { paginationState } = initialState();
    this.patchState({ paginationState, monitoringList: [] });
    this.paginationReset$.next();
  }

  public downloadLetter(letter: IncentiveRecordsAssignmentLetterModel): void {
    this.letterCreationService
      .getLetterUserDocument(letter.name, letter.id)
      .pipe(this.loadingService.withLoadingScreen, takeUntilDestroyed(this.destroyRef))
      .subscribe(letterBase64 => DownloadFileHelper.download(letterBase64, letter.name));
  }

  public getCurrentLetterByRecord(item: IncentiveRecordsMonitoringRecordModel | IncentiveRecordsAssignmentRecordPartnerModel) {
    if (item) {
      const letters = this.getLetterByType(item.letters || []);
      if (letters.length > 0) return letters[letters.length - 1];
    }

    return null;
  }

  public getRecordLetterState(item: IncentiveRecordsMonitoringRecordModel | IncentiveRecordsAssignmentRecordPartnerModel) {
    if (item) {
      const tafStates = item.recordLetterStates.filter(state => state.letterType === IncentiveRecordsRecordLetterStateModelLetterTypeEnum.TargetAgreementForm);
      if (tafStates.length > 0) return tafStates[tafStates.length - 1]?.state;
    }

    return null;
  }

  private getLetterByType(letters: IncentiveRecordsAssignmentLetterModel[]) {
    return letters.filter(letter => letter.type === IncentiveRecordsAssignmentLetterModelTypeEnum.TargetAgreementForm);
  }

  public startListMassOperation<T extends MassOperationType>(operationType: T, recordIds: string[], payload: MassOperationPayload<T>) {
    const seasonId = this.incentiveSupportState.getSelectedSeason().id;
    const letterIds = this.getLetterIdsOfRecords(recordIds);
    // letters which are published needs to be unpublished before setting no taf required / irrelevant
    const contextualUnpublish$ = defer(() =>
      letterIds.length
        ? this.communicationsPartnerService
            .listUnpublishLetterLetterPublications({
              communicationsLetterPublicationsUnpublishLetterListModel: { letterIds }
            })
            .pipe(this.startMassOperationTransaction)
        : of(null)
    );

    switch (operationType) {
      case 'create': {
        const createPayload = payload as MassOperationPayload<'create'>;
        return this.communicationsPartnerService
          .createManyLetters({
            siemensCOINClientApiCommunicationsCommandsLettersCreateLetterBulkModel: {
              seasonId,
              seasonType: SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBulkModelSeasonTypeEnum.IncentiveV2,
              recordIds,
              letterType: SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBulkModelLetterTypeEnum.TargetAgreementForm,
              templateId: createPayload.templateId,
              numberFormatCountryIso: createPayload.numberFormatCountryIso
            }
          })
          .pipe(this.startMassOperationTransaction);
      }
      case 'publish': {
        const publishPayload = payload as MassOperationPayload<'publish'>;
        return this.communicationsPartnerService
          .publishLetterListLetterPublications({
            siemensCOINClientApiCommunicationsCommandsLettersPublishLetterListModel: {
              letterIds,
              seasonId,
              workflowId: publishPayload.workflowId,
              managerType: publishPayload.managerType as unknown as SiemensCOINClientApiCommunicationsCommandsLettersPublishLetterListModelManagerTypeEnum,
              seasonType: SiemensCOINClientApiCommunicationsCommandsLettersPublishLetterListModelSeasonTypeEnum.IncentiveV2,
              employeeMailTemplateId: publishPayload.selection?.employeeMail?.id,
              employeeTaskTemplateId: publishPayload.selection?.employeeTask?.id,
              managerMailTemplateId: publishPayload.selection?.managerMail?.id,
              managerTaskTemplateId: publishPayload.selection?.managerTask?.id,
              overriddenPublicationDates: publishPayload.overriddenPublicationDates as unknown as CommunicationsLetterPublicationsAddPublicationDateModel[]
            }
          })
          .pipe(this.startMassOperationTransaction);
      }
      case 'unpublish':
        return this.communicationsPartnerService
          .listUnpublishLetterLetterPublications({
            communicationsLetterPublicationsUnpublishLetterListModel: { letterIds }
          })
          .pipe(this.startMassOperationTransaction);
      case 'forward': {
        const forwardPayload = payload as MassOperationPayload<'forward'>; // TODO: api is missing comment
        return this.communicationsPartnerService
          .forwardLettersLetterActions({
            communicationsLetterActionsForwardLettersModel: { letterIds, seasonId }
          })
          .pipe(this.startMassOperationTransaction); // TODO: might need to be mapped to a local "mock" transaction status
      }
      case 'auto-finalize': {
        const autoFinalizePayload = payload as MassOperationPayload<'auto-finalize'>;
        return this.communicationsPartnerService
          .autoFinalizeLettersLetterFinalization({
            communicationsLetterFinalizationAutoFinalizeLettersModel: {
              letterIds,
              seasonId,
              ...autoFinalizePayload
            }
          })
          .pipe(this.startMassOperationTransaction);
      }
      case 'set-no-taf': {
        return contextualUnpublish$.pipe(
          switchMap(() =>
            this.incentivePartnerService.updateRecordStatesRecords({
              seasonId,
              incentiveRecordsUpdateManyRecordStatesPartnerModel: {
                recordLetterState: {
                  state: IncentiveRecordsAdjustRecordLetterStateModelStateEnum.NoLetterRequired,
                  letterType: IncentiveRecordsAdjustRecordLetterStateModelLetterTypeEnum.TargetAgreementForm
                },
                recordIds
              }
            })
          )
        );
      }
      case 'reset-taf-required':
        return this.incentivePartnerService.updateRecordStatesRecords({
          seasonId,
          incentiveRecordsUpdateManyRecordStatesPartnerModel: {
            recordLetterState: {
              state: IncentiveRecordsAdjustRecordLetterStateModelStateEnum.None,
              letterType: IncentiveRecordsAdjustRecordLetterStateModelLetterTypeEnum.TargetAgreementForm
            },
            recordIds
          }
        });
      case 'set-irrelevant': {
        return contextualUnpublish$.pipe(
          switchMap(() =>
            this.incentivePartnerService.updateRecordStatesRecords({
              seasonId,
              incentiveRecordsUpdateManyRecordStatesPartnerModel: {
                state: IncentiveRecordsUpdateManyRecordStatesPartnerModelStateEnum.Irrelevant,
                recordIds
              }
            })
          )
        );
      }
      case 'set-active':
        return this.incentivePartnerService.updateRecordStatesRecords({
          seasonId,
          incentiveRecordsUpdateManyRecordStatesPartnerModel: { state: IncentiveRecordsUpdateManyRecordStatesPartnerModelStateEnum.Active, recordIds }
        });
    }
  }

  public startBatchMassOperation<T extends MassOperationType>(operationType: T, payload: MassOperationPayload<T>) {
    const seasonId = this.incentiveSupportState.getSelectedSeason().id;
    const selectedFilterOptions = this.store.selectSnapshot(FilterSortState.selectedFilterOptions);
    const filtersAsBatchParams = convertFiltersToBatchParams(selectedFilterOptions);
    // letters which are published needs to be unpublished before setting no taf required / irrelevant
    const contextualUnpublish$ = defer(() =>
      this.communicationsPartnerService
        .batchUnpublishLetterLetterPublications({
          communicationsLetterPublicationsUnpublishLetterBatchModel: {
            seasonId,
            letterType: CommunicationsLetterPublicationsUnpublishLetterBatchModelLetterTypeEnum.TargetAgreementForm
          },
          ...filtersAsBatchParams
        })
        .pipe(this.startMassOperationTransaction)
    );

    switch (operationType) {
      case 'create': {
        const createPayload = payload as MassOperationPayload<'create'>;
        return this.communicationsPartnerService
          .createLetterBatchLetters({
            siemensCOINClientApiCommunicationsCommandsLettersCreateLetterBatchModel: {
              seasonId,
              seasonType: SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBatchModelSeasonTypeEnum.IncentiveV2,
              letterType: SiemensCOINClientApiCommunicationsCommandsLettersCreateLetterBatchModelLetterTypeEnum.TargetAgreementForm,
              templateId: createPayload.templateId,
              numberFormatCountryIso: createPayload.numberFormatCountryIso
            },
            ...filtersAsBatchParams
          })
          .pipe(this.startMassOperationTransaction);
      }
      case 'publish': {
        const publishPayload = payload as MassOperationPayload<'publish'>;
        return this.communicationsPartnerService
          .publishLetterBatchLetterPublications({
            communicationsLetterPublicationsPublishLetterBatchModel: {
              seasonId,
              workflowId: publishPayload.workflowId,
              managerType: publishPayload.managerType as unknown as CommunicationsLetterPublicationsPublishLetterBatchModelManagerTypeEnum,
              seasonType: CommunicationsLetterPublicationsPublishLetterBatchModelSeasonTypeEnum.IncentiveV2,
              employeeMailTemplateId: publishPayload.selection?.employeeMail?.id,
              employeeTaskTemplateId: publishPayload.selection?.employeeTask?.id,
              managerMailTemplateId: publishPayload.selection?.managerMail?.id,
              managerTaskTemplateId: publishPayload.selection?.managerTask?.id,
              letterType: CommunicationsLetterPublicationsPublishLetterBatchModelLetterTypeEnum.TargetAgreementForm
            },
            ...filtersAsBatchParams
          })
          .pipe(this.startMassOperationTransaction);
      }
      case 'unpublish':
        return this.communicationsPartnerService
          .batchUnpublishLetterLetterPublications({
            communicationsLetterPublicationsUnpublishLetterBatchModel: {
              seasonId,
              letterType: CommunicationsLetterPublicationsUnpublishLetterBatchModelLetterTypeEnum.TargetAgreementForm
            },
            ...filtersAsBatchParams
          })
          .pipe(this.startMassOperationTransaction);
      case 'forward': {
        const forwardPayload = payload as MassOperationPayload<'forward'>; // TODO: api is missing comment
        // TODO: clarify letter ids (Admin App does not have batch forward)
        return this.communicationsPartnerService
          .forwardLettersByQueryLetterActions({
            communicationsLetterActionsForwardLettersModel: { seasonId, letterIds: [] },
            ...filtersAsBatchParams
          })
          .pipe(this.startMassOperationTransaction);
      }
      case 'auto-finalize': {
        const autoFinalizePayload = payload as MassOperationPayload<'auto-finalize'>;
        return this.communicationsPartnerService
          .autoFinalizeLettersByQueryLetterFinalization({
            communicationsLetterFinalizationAutoFinalizeLettersByQueryModel: { seasonId, ...autoFinalizePayload },
            ...filtersAsBatchParams
          })
          .pipe(this.startMassOperationTransaction);
      }
      case 'set-no-taf':
        return contextualUnpublish$.pipe(
          switchMap(() =>
            this.incentivePartnerService.updateRecordStatesRecords({
              seasonId,
              incentiveRecordsUpdateManyRecordStatesPartnerModel: {
                recordLetterState: {
                  state: IncentiveRecordsAdjustRecordLetterStateModelStateEnum.NoLetterRequired,
                  letterType: IncentiveRecordsAdjustRecordLetterStateModelLetterTypeEnum.TargetAgreementForm
                }
              },
              ...filtersAsBatchParams
            })
          )
        );
      case 'reset-taf-required':
        return this.incentivePartnerService.updateRecordStatesRecords({
          seasonId,
          incentiveRecordsUpdateManyRecordStatesPartnerModel: {
            recordLetterState: {
              state: IncentiveRecordsAdjustRecordLetterStateModelStateEnum.None,
              letterType: IncentiveRecordsAdjustRecordLetterStateModelLetterTypeEnum.TargetAgreementForm
            }
          },
          ...filtersAsBatchParams
        });
      case 'set-irrelevant':
        return contextualUnpublish$.pipe(
          switchMap(() =>
            this.incentivePartnerService.updateRecordStatesRecords({
              seasonId,
              incentiveRecordsUpdateManyRecordStatesPartnerModel: { state: IncentiveRecordsUpdateManyRecordStatesPartnerModelStateEnum.Irrelevant },
              ...filtersAsBatchParams
            })
          )
        );
    }
  }

  public startMassLetterDownloadOperation(): Observable<TransactionStatus<TransactionStatusMetadata>> {
    const { selectedItemIds } = this.get();
    const seasonId = this.incentiveSupportState.getSelectedSeason().id;

    if (selectedItemIds === 'ALL') {
      const selectedFilterOptions = this.store.selectSnapshot(FilterSortState.selectedFilterOptions);
      const filtersAsBatchParams = convertFiltersToBatchParams(selectedFilterOptions);

      return this.communicationsPartnerService.batchDownloadByQueryLetters({
        seasonId,
        siemensCOINClientApiCommunicationsCommandsLettersDownloadLettersModel: { seasonId },
        ...filtersAsBatchParams
      }) as unknown as Observable<TransactionStatus<TransactionStatusMetadata>>;
    }

    const letterIds = this.getLetterIdsOfRecords([...selectedItemIds]);
    return this.communicationsPartnerService.batchDownloadByQueryLetters({
      seasonId,
      siemensCOINClientApiCommunicationsCommandsLettersDownloadLettersModel: { seasonId, letterIds }
    }) as unknown as Observable<TransactionStatus<TransactionStatusMetadata>>;
  }

  private getLetterIdsOfRecords(recordIds: string[]): string[] {
    const recordsById = stateSnapshot(this.recordsById$);

    const letterIds = recordIds.map(recordId => this.getCurrentLetterByRecord(recordsById[recordId])?.id).filter(Boolean);
    return [...new Set(letterIds)];
  }

  private startMassOperationTransaction = (observable$: Observable<{ transactionId: string }>) => {
    return this.transactionCalculationOperations.startProcess(observable$).pipe();
  };

  public updateRecordInList(record: IncentiveRecordsAssignmentRecordPartnerModel): void {
    this.setState(state => {
      const recordIndex = state.monitoringList.findIndex(r => r.id === record.id);
      if (recordIndex !== -1) {
        state.monitoringList[recordIndex] = {
          // downcast detail record to list record
          ...record,
          creationContext: record.creationContext as never,
          state: record.state as never,
          payoutState: record.payoutState as never,
          exportState: record.exportState as never,
          processState: record.processState as never
        };
      }
    });
  }

  public canExecuteOperation(
    operation: MassOperationType,
    letterState: IncentiveRecordsRecordLetterStateModelStateEnum,
    processState: IncentiveRecordsAssignmentRecordPartnerModelProcessStateEnum,
    recordState: IncentiveRecordsAssignmentRecordPartnerModelStateEnum
  ): boolean {
    if (isProcessStateAboveOrEqualToFrozen(processState)) return false;
    const allowedOperationsByLetterState: Record<IncentiveRecordsRecordLetterStateModelStateEnum, MassOperationType[]> = {
      None: ['create'],
      Created: ['publish'],
      Published: ['unpublish'],
      InManagerReview: ['unpublish', 'auto-finalize'],
      InEmployeeReview: ['unpublish', 'auto-finalize'],
      ManagerRejected: ['unpublish', 'forward'],
      EmployeeRejected: ['unpublish', 'forward'],
      NoLetterRequired: ['reset-taf-required']
    };

    switch (operation) {
      case 'set-no-taf':
        return letterState !== 'NoLetterRequired';
      case 'set-irrelevant':
        return recordState !== 'Irrelevant';
      case 'set-active':
        return recordState === 'Irrelevant';
      default:
        return allowedOperationsByLetterState[letterState].includes(operation);
    }
  }
}
