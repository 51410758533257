import { NumberFormatCountryIso } from '@coin/shared/util-enums';
import { inject, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map, startWith } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { COIN_FORMATS, DEFAULT_DATE_FORMAT } from '@coin/shared/util-helpers';

function getLocalDisplayFormat(locale: string) {
  switch (locale) {
    case NumberFormatCountryIso.German:
      return 'DD.MM.YYYY';
    default:
      return DEFAULT_DATE_FORMAT;
  }
}

export function localDateInputFormatFactory() {
  const translateService = inject(TranslateService);
  const localeId = inject(LOCALE_ID);

  const localFormat = structuredClone(COIN_FORMATS);
  translateService.onLangChange
    .pipe(
      startWith(null),
      map(() => localeId.toString()),
      takeUntilDestroyed()
    )
    .subscribe(locale => {
      localFormat.display.dateInput = getLocalDisplayFormat(locale);
    });

  return localFormat;
}
