import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CmsUsages } from '@coin/admin/cms/util';
import { HttpHelpersService, LoadingService } from '@coin/shared/data-access';
import { environment } from '@coin/shared/util-environments';
import { EMPTY, Observable, switchMap } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  constructor(
    private http: HttpClient,
    private loadingService: LoadingService,
    private httpHelpersService: HttpHelpersService,
    private toast: ToastrService
  ) {}

  getImageThumbnails(): Observable<{ key: string }[]> {
    return this.http.get<{ key: string }[]>(`${environment.api.imagesService}images`).pipe(this.httpHelpersService.handleError('Cannot get images'));
  }

  getVideoThumbnails(): Observable<{ key: string }[]> {
    return this.http.get<{ key: string }[]>(`${environment.api.imagesService}videos`).pipe(this.httpHelpersService.handleError('Cannot get videos'));
  }

  uploadImage(imageBase64: string): Observable<{ fileName: string }> {
    return this.http.post<{ fileName: string }>(`${environment.api.imagesService}images`, { imageBase64 });
  }

  uploadVideo(file: File): Observable<{ fileName: string }> {
    if (file.type !== 'video/mp4') {
      this.toast.error('Video must be in mp4 format');
      return EMPTY;
    }

    const videoId = uuid();
    return this.http.get<{ signedUrl: string }>(`${environment.api.imagesService}videos/upload`, { params: { key: `${videoId}.mp4` } }).pipe(
      switchMap(({ signedUrl }) => {
        // upload to S3
        return this.http.put(signedUrl, file);
      }),
      switchMap(() => {
        // process by Lambda
        return this.http.patch<{ fileName: string }>(`${environment.api.imagesService}videos/${videoId}`, undefined);
      }),
      this.httpHelpersService.handleError('Cannot upload video')
    );
  }

  deleteImage(key: string): Observable<unknown> {
    return this.loadingService.withLoadingScreen(this.http.delete(`${environment.api.imagesService}images/${key}`));
  }

  deleteVideo(key: string): Observable<unknown> {
    return this.loadingService.withLoadingScreen(this.http.delete(`${environment.api.imagesService}videos/${key}`));
  }

  public getImageCmsUsage(path: string): Observable<CmsUsages> {
    return this.http
      .get<CmsUsages>(`${environment.api.imagesService}images/cms-usages?path=${encodeURIComponent(path)}`)
      .pipe(this.httpHelpersService.handleError('Cannot get image usages'));
  }

  public getVideoCmsUsage(path: string): Observable<CmsUsages> {
    return this.http
      .get<CmsUsages>(`${environment.api.imagesService}videos/cms-usages?path=${encodeURIComponent(path)}`)
      .pipe(this.httpHelpersService.handleError('Cannot get video usages'));
  }
}
