import { ChangeDetectorRef, Component, DestroyRef, Input, OnInit, viewChild, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CmsItem, DefaultColorPickerValues } from '@coin/admin/cms/util';
import { LegacyComponentsModule } from '@coin/shared/feature-legacy-components';
import { V2CheckboxComponent } from '@coin/shared/ui-storybook';
import { AngularEditorComponent, AngularEditorModule } from '@kolkov/angular-editor';
import { QuillEditorComponent } from 'ngx-quill';
import { ToastrService } from 'ngx-toastr';
import { map, tap } from 'rxjs';
import { ContextmenuComponent } from '../contextmenu/contextmenu.component';
import { EmailSliderComponent } from '../email-slider/email-slider.component';
import { CMSContextMenuReplacement } from '../models/cms-context-menu-replacement';
import { AngularEditorHTMLConfig } from './angular-editor.config';
import { QUILL_ADVANCED_MODULES, QUILL_DEFAULT_MODULES } from './quill-modules.config';
import { registerPlainPaste } from './register-plain-paste';

@Component({
  selector: 'coin-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, QuillEditorComponent, AngularEditorModule, V2CheckboxComponent, LegacyComponentsModule, ContextmenuComponent, EmailSliderComponent]
})
export class HtmlEditorComponent implements OnInit {
  @Input() item: CmsItem<'editor' | 'bullet-list' | 'email-headline' | 'headline-with-subheadline'>;
  @Input() contentEdit = false;
  @Input() isNews = false;
  @Input() isFaq = false;
  @Input() isContentText = false;
  @Input() isTextHtml = false;
  @Input() isContentHtml = false;
  @Input() isTable = false;
  @Input() showAdditionalEditOptions = false;
  @Input() contextMenuReplacements?: CMSContextMenuReplacement[];

  @ViewChild('contextMenu') contextMenu: ContextmenuComponent;

  quillEditor = viewChild(QuillEditorComponent);
  angularEditor = viewChild(AngularEditorComponent);

  public quillFormControl = new FormControl('');

  public quillAdvancedModules = QUILL_ADVANCED_MODULES;
  public quillSimpleTextModules = QUILL_DEFAULT_MODULES;
  public angularEditorHTMLConfig = AngularEditorHTMLConfig;

  contextMenuVisible = false;
  contextmenuX = 0;
  contextmenuY = 0;
  elementToBeChanged = { text: '', position: 0, element: null };

  get showTextEditor(): boolean {
    return this.item && !this.isTable && (this.isFaq || this.isNews);
  }

  get showAngularHtmlEditor(): boolean {
    return this.item && this.contentEdit && this.isContentHtml;
  }

  get showFaqTableTextEditor(): boolean {
    return this.item && this.isFaq && this.isTable;
  }

  get showContentTextEditor(): boolean {
    return this.item && this.contentEdit && this.isContentText;
  }

  get showContentTextHtml(): boolean {
    return this.item && this.contentEdit && this.isTextHtml;
  }

  get showContentEditor(): boolean {
    return this.item && this.contentEdit && !this.isFaq && !this.isNews && !this.isContentText && !this.isContentHtml && !this.isTextHtml;
  }

  get showAngularTextarea(): boolean {
    return this.item && !this.contentEdit && !this.isFaq && !this.isNews;
  }

  constructor(
    private toast: ToastrService,
    private cd: ChangeDetectorRef,
    private destroyRef: DestroyRef
  ) {
    registerPlainPaste({ quill: this.quillEditor, kolkov: this.angularEditor });
  }

  ngOnInit(): void {
    this.item ??= {} as never;

    if (this.showContentTextEditor) {
      this.quillFormControl.setValue(this.item.content['text']);
    } else if (this.showContentEditor) {
      this.quillFormControl.setValue(this.item.content);
    } else if (this.showTextEditor) {
      this.quillFormControl.setValue(this.item.text);
    } else if (this.showContentTextHtml) {
      this.quillFormControl.setValue(this.item['textHtml']);
    }

    this.quillFormControl.valueChanges
      .pipe(
        map(content => content || ''),
        map(content => content?.replaceAll('<p></p>', '<p>&nbsp;</p>')),
        map(content => content?.replaceAll('<h1></h1>', '<h1>&nbsp;</h1>')),
        map(content => content?.replaceAll('<h2></h2>', '<h2>&nbsp;</h2>')),
        map(content => content?.replaceAll('<s></s>', '<s>&nbsp;</s>')),
        map(content => content?.replaceAll('<i></i>', '<i>&nbsp;</i>')),
        map(content => content?.replaceAll('<u></u>', '<u>&nbsp;</u>')),
        tap(content => {
          if (this.showContentTextEditor) {
            this.item.content['text'] = content;
          } else if (this.showContentEditor) {
            this.item.content = content;
          } else if (this.showTextEditor) {
            this.item.text = content;
          } else if (this.showContentTextHtml) {
            this.item['textHtml'] = content;
          }
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();

    this.setDefaultColors();
  }

  onRightClick(eventItem: MouseEvent): boolean {
    if (this.contextMenuReplacements) {
      const windowSelection = window.getSelection();
      this.elementToBeChanged.text = windowSelection['focusNode']['data'] ?? '';
      this.elementToBeChanged.position = windowSelection['baseOffset'];
      this.elementToBeChanged.element = windowSelection['focusNode']['parentElement'];
      this.contextmenuX = eventItem.clientX;
      this.contextmenuY = eventItem.clientY;
      this.contextMenuVisible = true;
    }

    return !this.contextMenuReplacements;
  }

  public onContextMenu(): boolean {
    return !this.contextMenuReplacements;
  }

  addItemToCursor(text): void {
    const newText =
      this.elementToBeChanged.text.substring(0, this.elementToBeChanged.position) +
      text +
      this.elementToBeChanged.text.substring(this.elementToBeChanged.position, this.elementToBeChanged.text.length).split('&nbsp;').join('&#160;');
    const indexOfText = this.elementToBeChanged.element.innerHTML.indexOf(this.elementToBeChanged.text);
    if (indexOfText > -1) {
      this.elementToBeChanged.element.innerHTML = this.elementToBeChanged.element.innerHTML.replace(this.elementToBeChanged.text, newText);
      this.setText();
    } else {
      const indexOfText2 = this.elementToBeChanged.element.innerHTML.split('&nbsp;').join(' ').indexOf(this.elementToBeChanged.text.split('&nbsp;').join(' '));
      if (indexOfText2 > -1) {
        this.elementToBeChanged.element.innerHTML = this.elementToBeChanged.element.innerHTML
          .split('&nbsp;')
          .join(' ')
          .replace(this.elementToBeChanged.text.split('&nbsp;').join(' '), newText);
        this.setText();
      } else {
        const indexOfText3 = escape(this.elementToBeChanged.element.innerHTML.split('&nbsp;').join(' ')).indexOf(
          escape(this.elementToBeChanged.text.split('&nbsp;').join(' ')).split('%A0').join('%20')
        );
        if (indexOfText3 > -1) {
          this.elementToBeChanged.element.innerHTML = unescape(
            escape(this.elementToBeChanged.element.innerHTML.split('&nbsp;').join(' ')).replace(
              escape(this.elementToBeChanged.text.split('&nbsp;').join(' ')).split('%A0').join('%20'),
              escape(newText)
            )
          );
          this.setText();
        } else {
          this.toast.info('Unable to find entry point to insert text');
        }
      }
    }
  }

  private setText(): void {
    this.cd.detectChanges();

    if (this.angularEditor()?.textArea?.nativeElement != null) {
      this.item.content = this.angularEditor().textArea.nativeElement.innerHTML;
    }
  }

  private setDefaultColors(): void {
    this.item.backgroundColor = this.item.backgroundColor || DefaultColorPickerValues.backgroundColor;
    this.item.textColor = this.item.textColor || DefaultColorPickerValues.textColor;
  }
}
