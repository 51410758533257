import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CmsItem, DefaultColorPickerValues } from '@coin/admin/cms/util';
import { LegacyComponentsModule } from '@coin/shared/feature-legacy-components';
import { V2CheckboxComponent } from '@coin/shared/ui-storybook';
import { RenderedGmmLineComponent } from '@coin-siemens-energy/letter-print-page';

@Component({
  selector: 'coin-gmm-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.scss'],
  standalone: true,
  imports: [V2CheckboxComponent, LegacyComponentsModule, FormsModule, RenderedGmmLineComponent]
})
export class LineComponent implements OnInit {
  @Input() item: CmsItem<'line'>;
  @Input() contentEdit = false;
  @Input() showBackgroundPicker = false;

  ngOnInit(): void {
    this.item ??= {} as CmsItem<'line'>;
    this.setDefaultColors();
  }

  private setDefaultColors(): void {
    this.item.backgroundColor = this.item.backgroundColor || DefaultColorPickerValues.backgroundColor;
  }
}
