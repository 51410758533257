import { Component, Input } from '@angular/core';

@Component({
  selector: 'coin-c-g2m-intro',
  templateUrl: './g2m-intro.component.html',
  styleUrls: ['./g2m-intro.component.scss'],
  standalone: false
})
export class G2mIntroComponent {
  @Input() maxHeight: string;
}
