<mat-expansion-panel [expanded]="isExpanded" [hideToggle]="isLocked" (opened)="togglePanelExpansion()">
  <mat-expansion-panel-header [class.locked]="isLocked">
    <mat-panel-title>
      <div class="left">
        <h4 class="position">{{ index + 1 }}</h4>
        <h4>{{ question?.title | translate }}</h4>
        @if (selectedAnswer && !isExpanded) {
          <div class="selected-answer">
            <mat-icon>check</mat-icon>
            <p>{{ selectedAnswer.title | translate }}</p>
          </div>
        }
      </div>
      @if (!isLocked && selectedAnswer && isExpanded) {
        <coin-v2-button
          type="primary"
          icon="chevron_right"
          iconPos="right"
          text="{{ (isLastElement ? 'survey.general.complete-survey' : 'survey.general.next-question') | translate }}"
          (click)="openNextQuestion()"></coin-v2-button>
      }
    </mat-panel-title>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <div class="answers">
      @for (answer of question?.answers; track trackByItemFn($index, answer)) {
        <coin-customer-survey-answer
          [answer]="answer"
          [isEditable]="isEditable && !answer.isSelected"
          (toggleAnswer)="onAnswerToggle($event, answer)"></coin-customer-survey-answer>
      }
    </div>
  </ng-template>
</mat-expansion-panel>
