import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortAlphabetically',
  standalone: true
})
export class SortAlphabeticallyPipe implements PipeTransform {
  transform(value: string[]): string[] {
    if (!value) {
      return [];
    }
    return [...value].sort((a, b) => {
      const aValue = a ?? '';
      const bValue = b ?? '';
      return aValue.localeCompare(bValue);
    });
  }
}
