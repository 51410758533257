import { Component, computed, EventEmitter, input, Input, Output, TemplateRef } from '@angular/core';
import { ResponsivenessService } from '@coin/shared/data-management-state';
import { TinyHelpers } from '@coin/shared/util-helpers';
import { ListColumn } from '@coin/shared/util-models';

@Component({
  selector: 'coin-c-list-header',
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.scss'],
  standalone: false
})
export class ListHeaderComponent {
  @Input() leftTemplate: TemplateRef<unknown>;
  @Input() rightTemplate: TemplateRef<unknown>;
  columns = input<ListColumn[]>();
  columnScaleFactor = input(1);
  @Input() translationPrefix: string;
  @Input() background: string;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onAction: EventEmitter<string> = new EventEmitter<string>();
  scrollbarWidth = input(15);

  constructor(public responsive: ResponsivenessService) {}

  scaledColumnWidths = computed(() => {
    const scaleFactor = this.columnScaleFactor();

    return this.columns()
      ?.map(column => this.scaleWidth(column.width, scaleFactor))
      .join(' ');
  });

  private scaleWidth(width: string, factor: number): string {
    const floatingPointNumber = /[+-]?([0-9]*[.])?[0-9]+/g;
    return width.replaceAll(floatingPointNumber, matchedNumber => {
      return (parseFloat(matchedNumber) * factor).toString();
    });
  }

  public get isTranslationPrefixGiven(): boolean {
    return this.translationPrefix?.trim().length > 0;
  }

  firstNamedIndex = computed(() => {
    return this.columns().findIndex(column => !!column.name);
  });

  lastNamedIndex = computed(() => {
    return TinyHelpers.findLastIndex(this.columns(), column => !!column.name);
  });

  public pascalcaseToKebabCase = TinyHelpers.pascalcaseToKebabCase;

  public onActionClick(action: string): void {
    this.onAction.emit(action);
  }
}
