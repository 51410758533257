@if (item) {
  <coin-v2-button [type]="item.style" [icon]="item.icon" (click)="openPage(item['link'])" [text]="item.text"></coin-v2-button>

  <div style="display: flex; flex-direction: column; width: 100%">
    @if (contentEdit) {
      <mat-form-field style="margin-top: 20px">
        <mat-label>{{ 'cms.button-text' | translate }}</mat-label>
        <input matInput aria-label="button text" [(ngModel)]="item['text']" [placeholder]="'cms.button-text-placeholder' | translate" [disabled]="!contentEdit" type="text" />
      </mat-form-field>
    }
    @if (contentEdit) {
      <mat-form-field style="margin-top: 20px">
        <mat-label>{{ 'cms.button-link' | translate }}</mat-label>
        <input matInput aria-label="button text" [(ngModel)]="item['link']" [placeholder]="'cms.button-text-placeholder' | translate" [disabled]="!contentEdit" type="test" />
      </mat-form-field>
    }

    @if (contentEdit) {
      <mat-form-field appearance="fill">
        <mat-label>{{ 'cms.button-style' | translate }}</mat-label>
        <mat-select [(ngModel)]="item.style">
          @for (option of buttonStyles; track option) {
            <mat-option [value]="option">{{ option }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    }

    @if (contentEdit) {
      <mat-form-field appearance="fill">
        <mat-label>{{ 'cms.button-style' | translate }}</mat-label>
        <mat-select [(ngModel)]="item.icon">
          @for (option of buttonIcons; track option) {
            <mat-option [value]="option">{{ option }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    }
  </div>
}
