import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CmsLetterImageSignaturesComponent,
  CMSLetterSignatureComponent,
  CMSLetterUserBlockComponent,
  CMSMeritLetterTableComponent,
  CMSPerformanceTableComponent,
  RenderedGmmLineComponent,
  RenderedGmmSpacerComponent
} from '@coin-siemens-energy/letter-print-page';
import { NumberFormatterPipe } from '@coin/admin/common/util';
import { AuthGmsAngularClientModule } from '@coin/modules/auth/feature';
import {
  AllCmsNewsItemsComponent,
  CmsButtonComponent,
  CompilationComponent,
  DownloadFileComponent,
  EmailButtonComponent,
  EmailIndexListComponent,
  EmailMainBodyImageTextComponent,
  EmailSingleDoubleImageComponent,
  EmailSliderComponent,
  EquityCmsCardsComponent,
  EquityCmsChapterComponent,
  EquityCmsChapterOverviewComponent,
  EquityCmsFullWidthpartWithImageComponent,
  EquityExpandBoxComponent,
  EquityExpandLinksboxComponent,
  HtmlEditorComponent,
  ImageSliderComponent,
  LineComponent,
  NewsHeadlineComponent,
  NumbersComponent,
  PictureContainerComponent,
  QuoteComponent,
  SpacerComponent,
  TextBlockComponent,
  TextImageLinkComponent
} from '@coin/modules/cms/feature';
import { LegacyComponentsModule } from '@coin/shared/feature-legacy-components';
import { IconButtonComponent, V2ButtonComponent, V2CheckboxComponent, V2DialogModule, V2NumberInputComponent } from '@coin/shared/ui-storybook';
import { PureFunctionPipe, StripParagraphsPipe } from '@coin/shared/util-pipes';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TranslateModule } from '@ngx-translate/core';
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';
import { QuillModule } from 'ngx-quill';
import { SimplebarAngularModule } from 'simplebar-angular';
import { BasicEmailComponent } from './emails/basic-email/basic-email.component';
import { CmsEditEmailComponent } from './emails/cms-edit-email/cms-edit-email.component';
import { EmailFootnotesComponent } from './emails/email-footnotes/email-footnotes.component';
import { EmailHeaderComponent } from './emails/email-header/email-header.component';
import { EmailPreviewComponent } from './emails/email-preview/email-preview.component';
import { EmailQuoteComponent } from './emails/email-quote/email-quote.component';
import { EmailVideoComponent } from './emails/email-video/email-video.component';
import { CmsEditNewsComponent } from './news/cms-edit-news/cms-edit-news.component';
import { PreviewComponent } from './news/preview/preview.component';
import { SelectNewContentComponent } from './news/select-new-content/select-new-content.component';

@NgModule({
  declarations: [
    CmsEditEmailComponent,
    CmsEditNewsComponent,
    BasicEmailComponent,
    PreviewComponent,
    EmailPreviewComponent,
    EmailVideoComponent,
    SelectNewContentComponent,
    EmailHeaderComponent,
    EmailFootnotesComponent,
    EmailQuoteComponent
  ],
  imports: [
    CommonModule,
    LegacyComponentsModule,
    SimplebarAngularModule,
    MatExpansionModule,
    MatRadioModule,
    MatSliderModule,
    MatBadgeModule,
    MatProgressSpinnerModule,
    AngularEditorModule,
    DragDropModule,
    MatInputModule,
    FormsModule,
    TranslateModule.forChild(),
    QuillModule.forRoot(),
    MatListModule,
    StripParagraphsPipe,
    PureFunctionPipe,
    MatTooltipModule,
    MatTabsModule,
    MatSelectModule,
    MatCheckboxModule,
    DragScrollComponent,
    DragScrollItemDirective,
    MatIconModule,
    NumberFormatterPipe,
    MatCardModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    V2NumberInputComponent,
    V2CheckboxComponent,
    IconButtonComponent,
    V2DialogModule,
    V2ButtonComponent,
    CMSLetterSignatureComponent,
    CMSLetterUserBlockComponent,
    CMSPerformanceTableComponent,
    CMSMeritLetterTableComponent,
    CmsLetterImageSignaturesComponent,
    RenderedGmmSpacerComponent,
    RenderedGmmLineComponent,
    V2DialogModule,
    TextBlockComponent,
    ImageSliderComponent,
    QuoteComponent,
    SpacerComponent,
    PictureContainerComponent,
    CompilationComponent,
    NumbersComponent,
    TextImageLinkComponent,
    HtmlEditorComponent,
    EmailSingleDoubleImageComponent,
    NewsHeadlineComponent,
    DownloadFileComponent,
    CmsButtonComponent,
    EmailIndexListComponent,
    LineComponent,
    EmailButtonComponent,
    EmailMainBodyImageTextComponent,
    EmailSliderComponent,
    TextBlockComponent,
    EquityExpandBoxComponent,
    EquityExpandLinksboxComponent,
    EquityCmsChapterComponent,
    EquityCmsChapterOverviewComponent,
    EquityCmsCardsComponent,
    EquityCmsFullWidthpartWithImageComponent,
    AllCmsNewsItemsComponent
  ],
  exports: [
    CmsEditEmailComponent,
    CmsEditNewsComponent,
    BasicEmailComponent,
    PreviewComponent,
    EmailPreviewComponent,
    SelectNewContentComponent,
    CmsButtonComponent,
    EmailHeaderComponent,
    EmailFootnotesComponent,
    EmailQuoteComponent,
    EmailVideoComponent
  ]
})
export class CmsComponentsModule {
  static forTest() {
    return CmsComponentsTestModule;
  }
}

@NgModule({ imports: [CmsComponentsModule, AuthGmsAngularClientModule.forTest()] })
class CmsComponentsTestModule {}
