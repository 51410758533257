import { NgClass, NgStyle } from '@angular/common';
import { Component, DestroyRef, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { CmsItem } from '@coin/admin/cms/util';
import { V2ButtonComponent } from '@coin/shared/ui-storybook';
import { GlobalEventsService } from '@coin/shared/util-helpers';
import { TranslateModule } from '@ngx-translate/core';
import { DragScrollComponent, DragScrollItemDirective } from 'ngx-drag-scroll';
import { filter } from 'rxjs';
import { SelectMediaComponent } from '../../../dialogs/shared/select-media/select-media.component';

@Component({
  selector: 'coin-gmm-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss'],
  standalone: true,
  imports: [NgClass, NgStyle, TranslateModule, DragScrollComponent, V2ButtonComponent, DragScrollItemDirective]
})
export class ImageSliderComponent implements OnInit {
  @Input() item: CmsItem<'image-slider'>;
  @Input() contentEdit = false;
  @Input() autoSlide = true;
  private autoSlider: number;
  public sliderIndex = 0;

  @ViewChild('nav', { read: DragScrollComponent }) ds: DragScrollComponent;

  constructor(
    private dialog: MatDialog,
    private events: GlobalEventsService,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this.autoRotate();
    this.events
      .listen('resize')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.onResize());
  }

  private autoRotate(): void {
    if (this.item?.sliderImages.length > 1 && this.autoSlide) {
      clearTimeout(this.autoSlider);
      this.autoSlider = window.setTimeout(() => {
        if (this.sliderIndex < this.item.sliderImages.length - 1) {
          this.moveTo(this.sliderIndex + 1);
        } else {
          this.moveTo(0);
        }
        this.autoRotate();
      }, 8000);
    }
  }

  public editPicture(): void {
    SelectMediaComponent.openDialog(this.dialog, { url: this.item.sliderImages[this.sliderIndex]?.img, mediaType: 'image' })
      .pipe(
        filter(newImage => !!newImage),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(newImage => {
        this.item.sliderImages[this.sliderIndex].img = newImage.mediaUrl;
      });
  }

  public moveLeft(): void {
    this.ds.moveLeft();
  }

  public moveRight(): void {
    this.ds.moveRight();
  }

  public moveTo(index: number): void {
    this.ds.moveTo(index);
  }

  onResize = (): void => {
    if (this.item.sliderImages.length > 1) {
      const tempHolder = this.ds.currIndex.toString();
      this.ds.moveTo(this.ds.currIndex !== 0 ? 0 : 1);
      this.ds.moveTo(parseInt(tempHolder, 10));
    }
  };

  public indexChanged(): void {
    this.sliderIndex = this.ds.currIndex;
    this.autoRotate();
  }

  public addSliderItem(): void {
    this.item.sliderImages.push({ img: '', text: 'New Image Slider Headline', subtext: 'Lorem ipsum dolor sit amet.' });
    this.moveTo(this.item.sliderImages.length - 1);
    this.indexChanged();
  }

  public deleteSliderItem(): void {
    this.item.sliderImages.splice(this.sliderIndex, 1);
  }

  dataChange(e: unknown, data: unknown[], model: string): void {
    data[model] = e;
  }
}
